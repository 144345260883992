@charset "UTF-8";

/* MPSR-51108 delete */
.store_main_spot {
    margin-top: 30px;

    +.store_main_spot {
        margin-top: 10px;
    }
}

.store_profile_area {
    overflow: hidden;
    margin: -2px;
    zoom: 1;
}

.store_profile_link,
.store_profile_product_link {
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    overflow: hidden;
}

.store_profile_link:hover,
.store_profile_product_link:hover {
    text-decoration: none;
}

.store_profile figure,
.store_profile_product figure {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
}

.store_profile {
    float: left;
    position: relative;
    width: 50%;
    padding-top: 50%;

    figure img {
        display: block;
        width: 100%;
    }

    figcaption {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: url('/img/mini/home/thumb_mask_488.png');

        strong {
            position: absolute;
            left: 30px;
            right: 30px;
            top: 192px;
            height: 96px;
            font-weight: bold;
            font-size: 40px;
            line-height: 48px;
            color: #fff;
            text-align: center;
            word-break: keep-all;
            word-wrap: break-word;
            overflow: hidden;
        }

        p {
            position: absolute;
            left: 30px;
            right: 30px;
            top: 314px;
            font-size: 16px;
            line-height: 24px;
            color: #fff;
            text-align: center;
        }

        .icon_go {
            position: absolute;
            left: 50%;
            right: 0;
            bottom: 48px;
            width: 53px;
            height: 53px;
            margin-left: -26px;
            background: url('/img/mini/home/icon_profile_circle_arrow.png') no-repeat 0 0;
        }
    }
}

.store_profile_link,
.store_profile_product_link {
    overflow: hidden;
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    background-color: $light-color;
}

.store_profile_link:hover,
.store_profile_product_link:hover {
    text-decoration: none;
}

.store_profile figure,
.store_profile_product figure {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
}

.store_profile_product {
    float: left;
    position: relative;
    width: 25%;
    padding-top: 25%;

    img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        margin: auto;
    }

    figcaption {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: transparent;
        transition: 0.3s ease-out;

        strong {
            display: none;
            position: absolute;
            left: 20px;
            right: 20px;
            top: 68px;
            height: 44px;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            color: #fff;
            text-align: center;
            word-break: keep-all;
            word-wrap: break-word;
            overflow: hidden;
        }

        .title {
            top: 98px;
        }
    }

    .consult_info {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 124px;
        text-align: center;

        .consult_txt {
            color: #fff;
            font-size: 15px;
            font-weight: bold;
        }
    }

    .store_profile_product_price {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 124px;
        color: #fff;
        text-align: center;

        em {
            display: inline-block;
            padding-top: 3px;
            font-weight: bold;
            font-size: 15px;
            line-height: 17px;
            font-family: sans-serif;
            vertical-align: top;
        }

        .price_unit {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            vertical-align: top;
        }
    }
}

.store_profile_product figcaption .icon_go {
    position: absolute;
    left: 50%;
    bottom: 37px;
    width: 43px;
    height: 43px;
    margin-left: -22px;
    background: url('/img/mini/home/icon_profile_circle_arrow.png') no-repeat 0 -53px;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.2s ease-out;
}

.store_profile_product_link {

    &:focus figcaption,
    &:active figcaption,
    &:hover figcaption {
        background-color: rgba(0, 0, 0, 0.5);
        background: url('/img/mini/home/opacity50.png')\9;
    }

    &:focus figcaption strong,
    &:active figcaption strong,
    &:hover figcaption strong,
    &:focus figcaption .store_profile_product_price,
    &:active figcaption .store_profile_product_price,
    &:hover figcaption .store_profile_product_price,
    &:focus figcaption .consult_info,
    &:active figcaption .consult_info,
    &:hover figcaption .consult_info {
        display: block;
    }

    &:focus .icon_go,
    &:active .icon_go,
    &:hover .icon_go {
        opacity: 1;
        transform: translateY(0);
    }
}

/* // MPSR-51108 delete */
