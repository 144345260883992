@charset "UTF-8";

@font-face {
    font-family: "Helvetica Neue";
    src: local("Helvetica Neue");
    unicode-range: U+0020;
}

@font-face {
    font-family: "Arial";
    src: local("Arial");
    unicode-range: U+0020;
}

@import "../common/mixins"; //공통 믹스인모음
@import "../common/helpers/variables"; //공통 변수모음
@import "../eui/component/c-flag";

@import "sprites/sp_mini_2x";
@import "partial_mini_v2/variables"; //스토어 변수모음
@import "partial_mini_v2/mixins";
@import "partial_mini_v2/base";
@import "partial_mini_v2/layout";
@import "partial_mini_v2/buttons";
@import "partial_mini_v2/headings"; // MPSR-51108 delete
@import "partial_mini_v2/input";
@import "partial_mini_v2/layer";
@import "partial_mini_v2/graph";
@import "partial_mini_v2/header";
@import "partial_mini_v2/lnb";
@import "partial_mini_v2/profile"; // MPSR-51108 delete
@import "partial_mini_v2/main";
@import "partial_mini_v2/promotion";
@import "partial_mini_v2/category";
@import "partial_mini_v2/filter";
@import "partial_mini_v2/product";
@import "partial_mini_v2/best_review";
@import "partial_mini_v2/instagram";
@import "partial_mini_v2/coupon";
@import "partial_mini_v2/notice";
@import "sprites/sp_ico_2x";
@import "../eui/component/c-starrate";
@import "../common/partial/component/list";
@import "../amazon/partial/header";

#cts2,
#cts > .store_recommand_product_wrap:first-child {
    margin-top: 30px;
}

.store_wrap {
    @extend %style-c-flag-box;

    .c-flag-box {
        max-height: 22px;
    }

    .subject-flag {
        color: $color-11st-blue;
        margin-right: 3px;
    }
}

.store_bundle_discount {
    overflow: visible;
    position: relative;
    width: 1200px;
    margin: 30px auto;
    font-family: $font-family;

    .title {
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: normal;
        line-height: 28px;
        color: #111;
    }

    .my_bundle_discount {
        padding: 24px;
        margin-bottom: 40px;
        background-color: #fff;
        @include clearfix();
        font-family: $font-family;

        .c-card-item {
            float: left;
            width: 590px;
            min-height: 225px;

            .c-card-item__thumb {
                float: left;
                width: 225px;

                a {
                    display: block;
                    text-align: center;
                }

                .c-card-item__thumb-img {
                    display: inline-block;
                    width: 225px;
                    height: 225px;

                    img {
                        vertical-align: top;
                    }
                }
            }

            .c-card-item__info {
                float: left;
                padding-left: 15px;
                width: 320px;

                dl {
                    @include clearfix();
                }

                .c-card-item__name {
                    margin-bottom: 8px;
                    font-size: 15px;
                    line-height: 22px;
                }

                .c-card-item__price-info {
                    margin-bottom: 2px;
                    @include clearfix();

                    .c-card-item__rate {
                        float: left;
                        margin-right: 8px;
                        font-size: 14px;
                        line-height: 18px;
                        color: $color-11st-red;
                        font-family: $font-family-number-lato;

                        .value {
                            font-size: 20px;
                            line-height: 20px;
                            vertical-align: bottom;
                        }
                    }

                    .c-card-item__price {
                        float: left;
                        font-size: 14px;
                        line-height: 18px;

                        .value {
                            display: inline-block;
                            font-family: $font-family-number-lato;
                        }

                        .unit {
                            display: inline-block;
                            margin-left: -2px;
                        }
                    }
                }

                .c-starrate {
                    @include clearfix();

                    .c-starrate__sati {
                        margin: 4px 5px 0 0;
                    }

                    .c-starrate__review {
                        .sr-only {
                            @include skip();
                        }

                        a {
                            color: #999;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .discount_info {
            float: left;
            padding: 0 11px 0 35px;
            min-height: 225px;
            border-left: 1px solid #eee;

            > ul {
                width: 515px;

                > li {
                    position: relative;
                    margin-top: 8px;
                    border: 1px solid #ddd;
                    border-radius: 6px;
                    padding: 23px 20px 19px 54px;

                    &:first-child {
                        margin-top: 0;
                    }

                    &.active {
                        padding: 23px 20px 24px 54px;
                        .radiobox {
                            .info {
                                .detail2 {
                                    display: block;
                                }
                            }
                        }

                        .layer_discount_info {
                            display: block;
                        }

                        .button_expand {
                            &:before {
                                transform: rotate(-135deg);
                            }
                        }
                    }

                    .radiobox {
                        .info {
                            .detail2 {
                                display: none;
                            }
                        }
                    }

                    .layer_discount_info {
                        display: none;
                    }

                    .button_expand {
                        position: absolute;
                        overflow: hidden;
                        top: 22px;
                        right: 22px;
                        width: 24px;
                        height: 24px;
                        text-indent: -9999px;

                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 7px;
                            height: 7px;
                            margin: -6px 0 0 -4px;
                            border: 1px solid #252525;
                            border-top: none;
                            border-left: none;
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            .radiobox {
                display: block;

                > span {
                    display: inline-block;
                    font-size: 17px;
                    line-height: 22px;
                    color: #333;
                    letter-spacing: 0;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 22px;
                        height: 22px;
                        border: 1px solid #ddd;
                        border-radius: 100%;
                        background-color: #fff;
                        vertical-align: middle;
                        box-sizing: border-box;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        top: 6px;
                        left: 6px;
                        width: 10px;
                        height: 10px;
                        border-radius: 100%;
                        background-color: #ddd;
                        vertical-align: middle;
                        box-sizing: border-box;
                    }
                }

                input[type="radio"] {
                    position: absolute;
                    top: 23px;
                    left: 17px;
                    width: 22px;
                    height: 22px;
                    margin: 0;
                    opacity: 0;
                    vertical-align: middle;
                }

                input[type="radio"]:checked + span:before {
                    font-weight: bold;
                    color: #ff3142;
                    border-color: $color-11st-red;
                    background-color: $color-11st-red;
                }

                input[type="radio"]:checked + span:after {
                    background-color: #fff;
                }

                .info {
                    display: block;
                    overflow: hidden;
                    background: #fff;
                    font-size: 14px;
                    color: #333;
                    line-height: 21px;

                    &::before {
                        top: 23px;
                        left: 17px;
                    }

                    &::after {
                        top: 29px;
                        left: 23px;
                    }

                    span {
                        display: block;

                        em {
                            color: $color-11st-blue;
                        }
                    }

                    .detail1 {
                        padding-bottom: 5px;
                        font-size: 15px;
                        font-weight: bold;
                        line-height: 22px;
                        color: #333;
                    }

                    .detail2 {
                        font-size: 14px;
                        line-height: 20px;
                        color: #666;

                        span {
                            margin-top: 2px;

                            &:first-child {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }

            .layer_discount_info {
                position: relative;

                .layer {
                    display: none;
                    position: absolute;
                    top: 35px;
                    left: 0;
                    width: 408px;
                    padding: 20px;
                    box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.36);
                    background: #fff;
                    border-radius: 4px;
                    box-sizing: border-box;
                    z-index: 10;
                }

                &.active {
                    .layer {
                        display: block;
                    }
                }

                .layer_cont {
                    .title {
                        padding-bottom: 0;
                        font-size: 15px;
                        font-weight: bold;
                        line-height: 22px;
                        color: #111;
                    }

                    .cont {
                        margin-top: 10px;

                        .bundle_info {
                            li {
                                font-family: $font-family-number-lato;
                                font-size: 15px;
                                color: #111;
                                line-height: 24px;

                                em {
                                    color: $color-11st-blue;
                                }
                            }
                        }
                    }
                }

                .btn_click {
                    position: relative;
                    margin-top: 7px;
                    padding-right: 12px;
                    font-family: $font-family;
                    font-size: 15px;
                    line-height: 22px;
                    color: #111;

                    &::after {
                        position: absolute;
                        top: 50%;
                        right: 2px;
                        width: 8px;
                        height: 8px;
                        margin-top: -4px;
                        border: solid #111;
                        border-width: 1px 1px 0 0;
                        transform: rotate(45deg);
                        content: "";
                    }
                }

                .btn_close {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    width: 23px;
                    height: 23px;
                    overflow: hidden;
                    line-height: 200px;

                    @include ico-cancel(13px, 1px, #111, 5px);
                }
            }
        }
    }

    .recommand_discount {
        .c_list_store_recommand {
            position: relative;
            width: 1200px;

            .c_slide_view {
                padding: 25px 15px;
                margin: -25px -15px;
            }
        }

        .c_slide_container {
            .c_slide {
                margin-right: 20px;

                ul {
                    li {
                        margin: 0 0 0 20px;
                        border-radius: 4px;
                        background-color: #fff;
                        box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);

                        &:hover {
                            box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.7);

                            .c-card-item__thumb-img {
                                img {
                                    transform: scale(1.08);
                                }
                            }
                        }

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }

            .c-card-item {
                width: 224px;
                height: 345px;
                border-radius: 4px;
            }

            .c-card-item__thumb {
                .c-card-item__thumb-img {
                    width: 224px;
                    height: 224px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;

                    img {
                        vertical-align: top;
                        transition: transform 0.2s ease-in;
                    }
                }
            }

            .c-card-item__info {
                padding: 20px;

                .c-card-item__name {
                    height: 3em;
                    margin: 0 0 15px 0;
                    line-height: 22px;
                }

                .c-card-item__price-info {
                    .c-card-item__rate {
                        display: inline-block;
                        margin: 0 8px 2px 0;
                        font-size: 16px;

                        .value {
                            font-size: 22px;
                            line-height: 22px;
                        }
                    }

                    .c-card-item__price {
                        float: left;
                        font-size: 15px;
                        line-height: 22px;

                        .value {
                            display: inline-block;
                            margin: 0 0 1px 0;
                            font-size: 22px;
                            line-height: 22px;
                        }
                    }
                }
            }

            .c_adtext {
                top: 208px;
            }
        }
    }

    .list_navigator {
        button {
            overflow: hidden;
            position: absolute;
            top: 50%;
            width: 50px;
            height: 50px;
            margin-top: -25px;
            border-radius: 50%;
            box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);
            line-height: 200px;
            background: #fff;
            z-index: 10;

            &.previous {
                &::before {
                    @include sprite-retina($sp_ico_2x_arrow_left);
                }

                &:hover {
                    &::before {
                        @include sprite-retina($sp_ico_2x_arrow_left_on);
                    }
                }
            }

            &.next {
                &::before {
                    @include sprite-retina($sp_ico_2x_arrow_right);
                }

                &:hover {
                    &::before {
                        @include sprite-retina($sp_ico_2x_arrow_right_on);
                    }
                }
            }
        }
    }

    .store_filter_title {
        padding-top: 10px;
    }

    &.store_bundle_discount_amazon {
        .discount_info {
            .radiobox {
                input[type="radio"]:checked + span:before {
                    color: #54cabe;
                    border-color: #54cabe;
                    background-color: #54cabe;
                }
            }
        }
    }
}
