@charset "UTF-8";

/* MPSR-51108 삭제 */
.store_chk_wrap {
    position: relative;

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        border: 0;
        background: none;
        opacity: 0;
        filter: alpha(opacity=0);
    }

    label {
        @extend .fs12;
        display: inline-block;
        padding-left: 24px;
        background: url('/img/mini/home/icon_check.png') no-repeat 0 50%;
        color: #666;
        line-height: 18px;
    }

    &.checked label {
        background-image: url('/img/mini/home/icon_check_active.png');
    }

    &.type2 {
        label {
            @extend .fs14;
            color: #333;
        }

        &.checked label {
            background-image: url('/img/mini/home/icon_check2_active.png');
        }
    }
}
/* // MPSR-51108 삭제 */

// dropdown
.dropdown_wrap {
    display: inline-block;
    position: relative;
    z-index: 9;
    width: 142px;
    height: 32px;
    background-color: #fff;
    text-align: left;
    font-size: 13px;
    color: #333;
    line-height: 31px;
    border: 1px solid #e3e3e8;
    vertical-align: middle;

    .dropdown_selected {
        position: relative;
        line-height: 0;

        .selected {
            position: relative;
            display: block;
            width: 100%;
            padding: 0 25px 0 29px;
            text-align: left;
            color: #333;
            font-weight: bold;
            font-size: 13px;
            line-height: 32px;

            &:hover {
                text-decoration: underline;
            }

            &:after {
                display: block;
                position: absolute;
                top: 50%;
                right: 13px;
                width: 7px;
                height: 7px;
                margin-top: -6px;
                border: 1px solid #aaa;
                border-width: 0 1px 1px 0;
                transform: rotate(45deg);
                content: '';
            }
        }
    }

    &.active {
        border-color: #666;

        .dropdown_selected .item:after {
            border-width: 1px 0 0 1px;
            margin-top: -3px;
        }

        .dropdown_list {
            display: block;
        }
    }

    .dropdown_list {
        display: none;
        position: absolute;
        top: 33px;
        left: -1px;
        right: -1px;
        padding: 7px 0;
        border: 1px solid #666;
        border-top: 0;
        background: #fff;

        li {
            position: relative;
            line-height: 0;
        }

        .item {
            width: 100%;
            padding: 0 0 5px 29px;
            text-align: left;
            color: #333;
            font-size: 13px;
            line-height: 22px;

            &:hover {
                color: $color-11st-red;
                text-decoration: underline;
            }
        }

        .help_box {
            margin-top: -15px;
        }
    }

    .help_box {
        position: absolute;
        top: 50%;
        left: 7px;
        margin-top: -16px;

        .btn_help .sp_search2 {
            margin: 0;
            vertical-align: -2px;
        }
    }
}
