@charset "UTF-8";

// MPSR-51108 delete
.store_wrap {
    .btn_view_prev {
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 50%;
        width: 60px;
        height: 60px;
        margin-top: -30px;
        background: url(/img/mini/home/icon_swipe_arrow.png) no-repeat -1px -1px;
        line-height: 200px;

        &:hover {
            background-position: -1px -63px;
        }
    }

    .btn_view_next {
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 50%;
        width: 60px;
        height: 60px;
        margin-top: -30px;
        background: url('/img/mini/home/icon_swipe_arrow.png') no-repeat -63px -1px;
        line-height: 200px;

        &:hover {
            background-position: -63px -63px;
        }
    }
}

.store_common_prev {
    position: absolute;
    left: 0;
    top: 50%;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    background: url(/img/mini/home/icon_swipe_arrow.png) no-repeat -1px -1px;
    overflow: hidden;
    line-height: 200px;

    &:hover {
        background-position: -1px -63px;
    }
}

.store_common_next {
    position: absolute;
    right: 0;
    top: 50%;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    background: url('/img/mini/home/icon_swipe_arrow.png') no-repeat -63px -1px;
    overflow: hidden;
    line-height: 200px;

    &:hover {
        background-position: -63px -63px;
    }
}
// // MPSR-51108 delete

.view_more_bar {
    @extend .fs14;
    display: block;
    position: relative;
    margin-top: 20px;
    padding: 14px 0;
    border: 1px solid #cdd1d3;
    background-color: #d6dadd;
    text-align: center;

    .icon_more {
        display: inline-block;
        padding-right: 24px;
        background: url('/img/mini/home/icon_view_more_bar.png') no-repeat 100% 0;
    }

    .icon_go {
        display: inline-block;
        padding-right: 24px;
        background: url('/img/mini/home/icon_view_more_bar.png') no-repeat 100% 100%;
    }
}
