@charset "UTF-8";

/* MPSR-55604 삭제 */
.store_promotion_header {
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    background-color: #8bc8e4;
    zoom: 1;

    img {
        float: left;
        width: 320px;
        height: 320px;
    }

    figcaption {
        float: left;
        position: relative;
        width: 660px;
        height: 320px;
        color: #fff;
    }

    .store_promotion_subject {
        display: block;
        margin-top: 53px;
        margin-bottom: -55px;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        text-decoration: underline;
        word-break: keep-all;
        word-wrap: break-word;
    }

    .store_promotion_title {
        overflow: hidden;
        height: 88px;
        margin-top: 77px;
        padding: 0 100px;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        word-break: keep-all;
        word-wrap: break-word;
    }

    .store_promotion_explain {
        overflow: hidden;
        height: 56px;
        margin-top: 20px;
        padding: 0 100px;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        word-break: keep-all;
        word-wrap: break-word;
    }
}

/* //MPSR-55604 삭제 */

.store_promotion_link {
    display: block;
    position: relative;
    margin: 2px;
}

.store_promotion.viewtype {
    width: auto;
    margin: 0;

    ul {
        width: auto;
        padding: 0 12px;
        @include clearfix();
    }

    li {
        width: 233px;
        padding: 0 3px;

        .img_plot {
            margin-bottom: 13px;
        }

        .img_plot img {
            width: 100%;
        }

        .box_pd .pname {
            padding: 0;

            p {
                overflow: hidden;
                height: 22px;
                font-size: 15px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .price_info {
                font-size: 13px;

                &.mini .price_detail .sale_price {
                    font-size: 18px;
                }

                .sale {
                    color: $color-11st-red;
                    font-weight: bold;
                    font-size: 18px;
                }
            }
        }
    }
}

.store_deal {
    position: relative;

    & + & {
        margin-top: 16px;
    }
}

.store_deal_list {
    position: relative;
    overflow: hidden;
    width: 100%;

    .store_deal_view_prd {
        overflow: hidden;
        height: 387px;
    }

    .box {
        float: left;
        width: 1220px;
    }

    li {
        float: left;
        width: 482px;
        padding: 0 16px 20px 0;
    }

    .thumb img {
        display: block;
        max-width: 100%;
        height: 243px;
        margin: auto;
    }

    .product_type1 > a {
        position: relative;
        display: block;
        height: 387px;
        background-color: #fff;

        .thumb .line_frame {
            *width: 283px;
            *height: 140px;
        }

        .ico_emblem {
            position: absolute;
            top: 5px;
            left: 5px;
            width: 80px;
            height: 80px;
            text-align: center;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .border_frame {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            border: 1px solid $color-11st-red;
            box-sizing: border-box;
            transition: opacity 0.2s;
            opacity: 0;
            filter: alpha(opacity=0);
            cursor: pointer;
        }

        &:hover .border_frame {
            opacity: 1;
            filter: alpha(opacity=100);
        }

        .summary_in {
            padding: 10px 20px 10px 20px;
            height: calc(144px - 59px);

            .info {
                .sale {
                    color: $color-11st-red;

                    &.sale_tx {
                        font-size: 16px;
                        letter-spacing: -1px;
                    }

                    .n {
                        font-size: 16px;
                    }
                }

                .price {
                    font-size: 13px;
                    margin-left: 4px;

                    &:first-child {
                        margin-left: 0;
                    }

                    .n {
                        font-size: 22px;
                        line-height: 27px;
                    }

                    .normal_price {
                        margin-left: 4px;
                        color: #bbb;
                        text-decoration: line-through;
                    }

                    .text_price {
                        font-size: 16px;
                        line-height: 27px;
                        letter-spacing: -1px;
                    }
                }
            }
        }

        .subject {
            margin-bottom: 5px;
        }

        .amount {
            float: right;
            margin: 8px 0 0 0;
            font-size: 11px;
            color: #999;

            .n {
                font-family: $font-family-number;
                letter-spacing: 0;
            }
        }

        .prod_benefit {
            padding: 10px 20px;
            border-top: 1px solid #eee;
            color: #666;
            font-size: 13px;
            line-height: 18px;

            .uiq_sale {
                padding-left: 6px;

                &:before {
                    display: inline-block;
                    margin: -3px 5px 0 -6px;
                    width: 2px;
                    height: 2px;
                    background-color: #ddd;
                    border-radius: 50%;
                    vertical-align: middle;
                    content: "";
                }

                &:first-child {
                    padding-left: 0;

                    &:before {
                        display: none;
                    }
                }

                em {
                    color: $color-11st-red;
                }
            }
        }

        .add {
            height: 36px;
            line-height: 0;
            padding: 0;
            border-bottom: 1px solid #c6cacc;
        }

        .prod_coupon {
            position: absolute;
            left: 0;
            bottom: 1px;
            width: 100%;
            height: 36px;

            .tx {
                height: 36px;
                padding: 0 20px;
                line-height: 36px;
                font-size: 11px;
            }

            .lk_go {
                position: relative;
                display: inline-block;
                height: 20px;
                padding: 8px 8px 8px 0;
                line-height: 20px;

                &.full {
                    margin: 0 20px;

                    .coupon_rate {
                        width: 237px;

                        .coupon_name {
                            width: 180px;
                        }
                    }
                }

                &.lt {
                    float: left;
                    margin-left: 20px;
                }

                &.rt {
                    float: right;
                    margin-right: 20px;
                }

                &:after {
                    transform: rotate(45deg);
                    height: 5px;
                    width: 5px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -3px;
                    border: solid #666;
                    border-width: 1px 1px 0 0;
                    content: "";
                }
            }

            .coupon_rate {
                display: inline-block;

                > span {
                    display: inline-block;
                    float: left;
                }

                .coupon_sale {
                    position: relative;
                    margin-right: 6px;
                    padding: 0 8px 0 4px;
                    background-color: #fff;
                    color: #666;
                    text-align: center;
                    letter-spacing: -1px;
                    line-height: 19px;

                    em {
                        position: relative;
                        font-weight: bold;
                        white-space: nowrap;
                        font-size: 10px;
                        color: $color-11st-red;
                        letter-spacing: 0;
                        vertical-align: top;
                        line-height: 20px;
                    }

                    &:before {
                        display: inline-block;
                        background-image: url(/img/main/v2/sp_main.png);
                        background-position: 100% -167px;
                        width: 12px;
                        height: 20px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        content: "";
                    }

                    &:after {
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        content: "";
                    }

                    &.num_type {
                        letter-spacing: 0;
                    }
                }

                .coupon_name {
                    font-size: 11px;
                    line-height: 20px;
                    @include text-ellipsis;
                }
            }

            .store {
                position: relative;
                display: inline-block;
                height: 20px;
                font-size: 11px;
                vertical-align: middle;

                &:before {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin: 0 3px -2px 0;
                    background-image: url(/img/main/v2/sp_main.png);
                    background-position: 100% -139px;
                    content: "";
                }

                .txt {
                    vertical-align: top;
                    width: 90px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 11px;
                    line-height: 16px;
                }
            }
        }

        &.closed {
            .thumb {
                .line_frame {
                    border: 0;
                    background: url("/img/default/bg_000_07.png");
                    opacity: 1;
                    filter: alpha(opacity=100);
                    text-align: center;

                    .txt {
                        display: inline-block;
                        padding-top: 55px;
                        font-size: 18px;
                        font-weight: bold;
                        color: #fefefe;
                    }
                }
            }

            .txt_closed {
                padding-top: 5px;
                font-size: 13px;
                font-weight: bold;
                color: #999;
            }

            a:hover {
                .subject {
                    text-decoration: none;
                }
            }

            .amount,
            .sale,
            .price {
                display: none;
            }
        }

        .consult_txt {
            display: block;
            padding-top: 5px;
            font-size: 13px;
            font-weight: bold;
            color: $color-11st-blue;
        }
    }
}

.store_deal_list.list_type2 {
    .store_deal_view_prd {
        height: auto;
    }

    li {
        float: none;
        width: 980px;
        padding: 0;
    }

    .product_type1 {
        > a {
            height: auto;
            padding: 16px;
            @include clearfix();
        }

        .thumb {
            float: left;
            width: 482px;
            margin-right: 20px;
        }

        .summary_in {
            padding: 28px 0 0;
            min-height: 203px;
            box-sizing: border-box;
        }

        .subject {
            margin: 0;
            padding: 0 0 16px;
            height: 48px;
            white-space: normal;
        }

        .info {
            padding-bottom: 16px;

            .sale {
                display: block;
                margin-bottom: 8px;
            }

            .price {
                margin-left: 0;
            }
        }

        .prod_benefit {
            padding: 10px 0;
        }
    }
}

/* MPSR-55604 삭제 */
.pagination_view {
    height: 10px;
    margin-top: 10px;
    text-align: center;

    .btn_icon {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 2px;
        background: #ccc;
        border-radius: 5px;
        text-indent: -9999px;
        vertical-align: top;
        line-height: 10px;

        &.active {
            background: #000;
        }
    }
}
/* // MPSR-55604 삭제 */

/* CSS copy */
.viewtype {
    // VIEWTYPE1-basic /css/common/listing_v3.scss
    overflow: hidden;
    position: relative;
    width: 1202px;
    margin: 0px -1px;

    ol,
    ul {
        width: 100%;
        zoom: 1;
        padding: 0 1px;
    }

    li {
        float: left;
        padding: 3px 10px 3px;

        .box_pd {
            position: relative;
            padding: 1px;

            &:hover {
                padding: 0;
                border: 1px solid $color-11st-red;
            }

            img {
                display: inline-block;
                vertical-align: top;
            }

            &.ad_sec,
            &.sell_sec {
                background: #f7f7f7;
            }

            .pname {
                padding: 7px 9px 9px;

                p {
                    overflow: hidden;
                    display: block;
                    height: 37px;
                    margin-right: -1px;
                    padding-right: 1px;
                    font-size: 14px;
                    line-height: 18px;
                    color: #333;
                    word-break: break-all;
                }

                .price_info.mini {
                    padding: 4px 0 0;

                    .price_detail .sale_price {
                        margin-top: 0;
                        font-size: 14px;
                    }
                }
            }

            &.sell {
                padding: 0;
                border: 1px solid $color-11st-red;

                .sell_prd {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 5;
                    width: 100%;
                    padding: 6px 0 9px;
                    background: $color-11st-red;
                    font-size: 12px;
                    color: #fff;
                    text-align: center;
                    vertical-align: top;
                }
            }

            span.best {
                position: absolute;
                left: 0;
                top: 0;
                width: 31px;
                height: 23px;
                padding: 5px 9px 0 0;
                background: url("/img/category/v2/sp_category.png") -186px -51px no-repeat;
                font: normal 14px $font-family-number;
                color: #fff;
                text-align: center;
            }

            a {
                display: block;
                position: relative;
                text-decoration: none;

                &:hover {
                    text-decoration: none;

                    span.best {
                        background-position: -142px -51px;
                    }

                    .pname > p {
                        text-decoration: underline;
                    }
                }
            }

            .img_plot {
                position: relative;

                &:before {
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    content: "";
                }
            }

            .prd_flag {
                color: #666;
                font-size: 12px;
                padding: 10px 0 8px;
                text-decoration: none;

                em.r_ico {
                    display: inline-block;
                    height: 18px;
                    padding: 0 7px;
                    font-size: 12px;
                    line-height: 16px;
                    border: 1px solid #666;
                    border-radius: 10px;
                    margin-right: 4px;
                    vertical-align: middle;
                }
            }

            .s_flag {
                display: block;
                margin-top: 9px;

                em {
                    display: inline-block;
                    padding: 0;
                    font-size: 12px;
                    color: #666;
                    border: none;
                }

                > em {
                    margin-right: 5px;
                    padding: 0 0 0 0;
                    line-height: 1.4;
                    vertical-align: middle;

                    &.one {
                        padding: 0 5px 0 0;
                        line-height: 110%;
                        margin-right: 5px;
                    }

                    &.now {
                        background: none;

                        img {
                            vertical-align: bottom;
                        }
                    }
                }

                .bar_10_da {
                    margin-right: 5px;
                    vertical-align: middle;
                }

                img {
                    width: auto;
                    height: auto;
                }

                .txt {
                    display: block;
                    margin-top: 2px;
                    color: #999;
                }
            }

            .list_btm {
                .info_txt_11day {
                    font-size: 13px;
                    height: auto;

                    .point {
                        color: $color-11st-blue;
                    }
                }
            }
        }

        .option_ico {
            display: inline-block;
            font-size: 12px;
            color: #999;
            font-weight: normal;
            margin-top: 5px;
            vertical-align: top;
            line-height: 130%;
        }
    }

    .selr_star_s {
        width: 74px;
    }

    .price_info {
        .sale {
            font-size: 18px;
        }

        .price_detail {
            .normal_price {
                bottom: 21px;
                font-size: 14px;
            }

            .sale_price {
                margin-top: 21px;
                font-size: 18px;
            }
        }
    }
}

.product_type1 {
    // product_type1 /css/main/main_v3.scss
    background: #fff;

    .info_etc,
    .add {
        .tx {
            display: inline-block;
            font-size: 12px;
            color: #666;

            .point {
                color: $color-11st-red;
            }

            .point2 {
                color: $color-11st-blue;
            }

            .lsn {
                letter-spacing: 0;
            }
        }
    }

    a {
        display: block;
        text-decoration: none;
    }

    .thumb {
        position: relative;
        display: block;
        text-align: center;

        .line_frame {
            position: absolute;
            left: 0px;
            top: 0px;
            display: block;
            width: 100%;
            height: 100%;
            border: 1px solid #000;
            opacity: 0.06;
            filter: alpha(opacity=6);
            box-sizing: border-box;
        }
    }

    .category {
        margin: 12px 0 0 0;
        color: #5074dd;
        font-size: 12px;
    }

    .summary_in {
        position: relative;
        display: block;
        padding: 2px 10px 3px 10px;
    }

    .subject {
        overflow: hidden;
        display: block;
        height: 21px;
        margin-top: 4px;
        font-size: 16px;
        line-height: 1.31;
        color: #333;
    }

    .subject_row2 {
        height: 42px;
    }

    a:hover .subject {
        text-decoration: underline;
    }

    .info {
        display: block;

        &.have_price_normal {
            margin-top: 29px;
        }
    }

    .sale {
        font-size: 14px;
        color: $color-11st-red;
    }

    .sale_tx {
        font-size: 18px;
        font-weight: bold;
    }

    .sale .n {
        font-size: 18px;
        font-family: Tahoma, sans-serif;
        font-weight: bold;
        letter-spacing: 0;
    }

    .price {
        position: relative;
        margin: 0 0 0 2px;
        font-size: 15px;
        color: #333;

        .n {
            font-size: 18px;
            font-family: Tahoma, sans-serif;
            font-weight: bold;
            letter-spacing: 0;
        }

        .text_price {
            font-size: 16px;
        }
    }

    .price_normal {
        position: absolute;
        left: 0px;
        bottom: 20px;
        color: #999;
        font-size: 13px;
        text-decoration: line-through;

        .n {
            font-size: 13px;
            font-family: Tahoma, sans-serif;
            font-weight: normal;
        }
    }

    .info_etc {
        margin: 2px 0 6px 0;

        .tx {
            font-size: 11px;

            .n {
                font-family: Tahoma, sans-serif;
                letter-spacing: 0;
            }
        }

        .group {
            display: block;
            line-height: 1.1em;
        }
    }

    .add {
        overflow: hidden;
        height: 37px;
        line-height: 37px;
        padding: 0 10px 0 10px;
        background: #fcfcfc;
        border-top: 1px solid #f4f4f4;

        .opt {
            margin: 0 4px 0 0;
            font-size: 12px;
            color: #999;
        }

        .tx {
            font-size: 12px;
        }

        .tx_bar {
            display: inline-block;
            width: 1px;
            height: 11px;
            margin: 0 4px 0 5px;
            background: #dbdbdb;
            vertical-align: middle;
        }
    }

    .consult_txt {
        font-size: 14px;
        color: $color-11st-blue;
        font-weight: bold;
    }
}

/* // CSS copy */
/* MPSR-51108 delete */
.store_promotion {
    float: left;
    position: relative;

    figure {
        overflow: hidden;
        zoom: 1;

        img {
            float: left;
            width: 242px;
            height: 242px;
        }
    }

    figure img + figcaption {
        width: 246px;
    }

    figcaption {
        float: left;
        position: relative;
        width: 242px;
        height: 242px;
        background-color: #8bc8e4;
        color: #fff;

        .store_promotion_subject {
            @extend .fs14;
            position: absolute;
            left: 29px;
            right: 29px;
            top: 25px;
            text-decoration: underline;
            word-break: keep-all;
            word-wrap: break-word;
            overflow: hidden;
        }

        .store_promotion_title {
            position: absolute;
            left: 29px;
            right: 29px;
            top: 55px;
            max-height: 52px;
            font-weight: normal;
            font-size: 20px;
            line-height: 26px;
            word-break: keep-all;
            word-wrap: break-word;
            overflow: hidden;
        }

        .store_promotion_explain {
            position: absolute;
            left: 29px;
            right: 29px;
            top: 115px;
            max-height: 36px;
            word-break: keep-all;
            word-wrap: break-word;
            overflow: hidden;
        }

        .go_store_promotion {
            position: absolute;
            left: 29px;
            bottom: 29px;
            padding: 6px 15px 6px;
            border: 1px solid;
            font-size: 12px;
            line-height: 18px;
        }
    }

    &.viewtype {
        float: none;
    }
}

.promotion_header {
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    height: 184px;
    border-bottom: 1px solid #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    background-color: #fff;
    zoom: 1;

    .store_promotion_title {
        overflow: hidden;
        height: 44px;
        margin-top: 55px;
        padding: 0 100px;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        word-break: keep-all;
        word-wrap: break-word;
    }

    .store_promotion_explain {
        overflow: hidden;
        height: 28px;
        margin: 7px 0 50px 0;
        padding: 0 100px;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        word-break: keep-all;
        word-wrap: break-word;
    }
}

.store_promotion_wrap {
    position: relative;
    margin-top: 40px;

    h3 {
        font-weight: bold;
        padding-bottom: 12px;
        font-size: 20px;
        line-height: 28px;
    }

    .store_promotion_list {
        overflow: hidden;
        margin-top: 6px;
        padding: 8px;
        border-bottom: 1px solid #ccc;
        border-color: rgba(0, 0, 0, 0.2);
        background-color: #fff;
    }

    .store_sub_promotion {
        float: left;
        position: relative;
        margin: 8px;
        border: 1px solid #eee;

        figure {
            overflow: hidden;
            zoom: 1;

            .thumb {
                position: relative;
                float: left;
                width: 160px;
                height: 160px;
            }

            img {
                width: 160px;
                height: 160px;
            }

            .bg_frame {
                position: absolute;
                left: 0px;
                top: 0px;
                display: block;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: 0.03;
                filter: alpha(opacity=3);
                box-sizing: border-box;
            }
        }

        .store_promotion_link {
            margin: 0;
        }

        figcaption {
            float: left;
            position: relative;
            width: 264px;
            height: 160px;
            padding: 0 20px;
            background-color: #fff;
            color: #333;

            .store_promotion_title {
                display: block;
                padding-top: 54px;
                height: 26px;
                font-weight: bold;
                font-size: 20px;
                line-height: 26px;
                word-break: keep-all;
                word-wrap: break-word;
                overflow: hidden;
            }

            .store_promotion_explain {
                margin-top: 9px;
                font-size: 14px;
                line-height: 18px;
                word-break: keep-all;
                word-wrap: break-word;
                overflow: hidden;
            }
        }
    }
}
