@charset "UTF-8";
@import "../eui/component/c-button";
@import "sprites/sp_mini_2x";

/* MPSR-51108 delete */
.store_product_heading {
    padding: 9px 0 11px;
    border-bottom: 2px solid #111;
    font-weight: normal;
    font-size: 15px;
}

/* // MPSR-51108 delete */

.store_search_title {
    margin-top: 30px;
    margin-bottom: -30px;
    padding: 9px 0;
    font-weight: normal;
    font-size: 22px;
    line-height: 32px;

    strong {
        font-weight: normal;
        color: $color-11st-red;
    }
}

.store_product_top {
    padding: 9px 0 14px;
    border-bottom: 2px solid #111;
    zoom: 1;

    &:after {
        display: block;
        clear: both;
        content: "";
    }

    .store_product_heading {
        float: left;
        padding: 0;
        border: 0;
    }

    .store_chk_wrap {
        float: left;
        margin-left: 10px;
    }
}

.store_product_sorting {
    position: absolute;
    right: 0;
    top: 11px;
    color: #999;
}

// 검색 목록 필터
.title_lg {
    display: block;
    padding: 10px 0 5px;
    font-weight: normal;
    font-size: 22px;
    line-height: 1.3;

    .text_sub {
        display: inline-block;
        margin-left: 3px;
        vertical-align: 2px;
        color: #666;
        font-size: 14px;
    }
}

.product_list_header {
    position: relative;
    display: table;
    width: 100%;
    min-height: 38px;
    border-bottom: 2px solid #111;

    .title {
        display: table-cell;
        padding: 9px 0 11px;
        vertical-align: top;
        font-weight: normal;
        font-size: 15px;
    }

    .side {
        text-align: right;
        vertical-align: top;
    }

    .store_product_top {
        display: table-cell;
        vertical-align: top;
        border-bottom: none;
    }

    .dropdown_wrap {
        margin-left: 16px;
        vertical-align: top;
    }

    &.header_style_1 {
        padding: 7px 0 6px;

        .title {
            font-size: 22px;
        }

        .text_sub {
            display: inline-block;
            margin-left: 3px;
            vertical-align: 2px;
            color: #666;
            font-size: 14px;
        }

        .check_style_filter {
            padding-top: 16px;
        }
    }
}

.check_style_filter {
    display: inline-block;
    padding: 9px 0;
    vertical-align: top;
    font-size: 13px;
    cursor: pointer;

    > input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
    }

    > label {
        display: block;
        color: #111;
        cursor: pointer;

        &:before {
            display: inline-block;
            width: 5px;
            height: 9px;
            margin: -5px 6px 0 3px;
            border: 1px solid #ccc;
            border-width: 0px 1px 1px 0;
            transform: rotate(45deg);
            vertical-align: middle;
            content: "";
        }
    }

    input:checked + label:before {
        border-color: $color-11st-red;
    }

    input.focus-visible + label {
        margin: 0 -2px;
        padding: 0 2px;
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
    }

    + .check_style_filter {
        margin-left: 9px;
    }
}

/* 검색 스타일 복사 (ctgr_sch_v2.scss) */
.help_box {
    position: relative;
    display: inline-block;
    height: 100%;

    &.on {
        z-index: 2;

        .layer_def_b3,
        .layer_def_b {
            display: block;
        }
    }

    .layer_def_b3,
    .layer_def_b {
        display: none;
        top: 26px;
        left: 50%;
        width: 250px;
        margin-left: -125px;
        font-family: $font-family-default;
        line-height: 20px;
        letter-spacing: 0;

        .layer_conts {
            padding: 7px 4px 6px 9px;
        }

        .layer_tit {
            display: block;
        }
    }

    .layer_def_b {
        top: 22px;
        left: auto;
        right: 0;
    }

    .btn_help {
        width: 20px;
        height: 100%;
        vertical-align: middle;

        .sp_search2 {
            display: inline-block;
            overflow: hidden;
            width: 14px;
            height: 14px;
            margin: -1px 0 1px;
            vertical-align: middle;
            background: url("/img/search/v2/sp_search2.png") no-repeat -37px -39px;
            font-size: 1px;
            line-height: 200px;
            white-space: nowrap;
        }

        &.btn_beta {
            position: relative;
            width: 49px;
            margin: -2px 0 2px;

            .sp_search2 {
                width: 49px;
                height: 22px;
                background-position: -42px -88px;
            }
        }
    }
}

/* // 검색 스타일 복사 (ctgr_sch_v2.scss) */

.store_product_sorting_item {
    display: inline-block;

    + .store_product_sorting_item {
        padding-left: 7px;
        background: url("/img/mini/home/icon_sorting_dot.png") no-repeat 1px 50%;
    }
}

.store_product_sorting a {
    color: #666;

    &:focus,
    &:active,
    &:hover {
        text-decoration: underline;
    }

    &.on {
        display: block;
        padding-left: 24px;
        background: url("/img/mini/home/icon_sorting_on.png") no-repeat 2px 0;
        color: $color-11st-red;
        text-decoration: none;
    }
}

.store_product_wrap {
    position: relative;
    margin-top: 30px;
}

.store_product {
    overflow: hidden;
    position: relative;
    min-height: 400px;
    margin-top: 16px;

    &.with_button_type {
        .s_store_result {
            margin-top: -25px;
        }

        .view_more_bar {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            margin-top: 0;
        }
    }
}

.store_product_filter {
    display: flex;
    justify-content: space-between;

    .c-button {
        //font-size, padding, min-width, height, width, border-radius
        @include c-button-set(13px, 0 8px, 34px, 31px, auto, 4px);
        &--item {
            margin-right: 6px;
            color: $color-gray-02;
            border-color: $color-gray-10 !important;
            background-color: $color-gray-13;

            .delete {
                display: none;
            }
        }

        &--selected {
            padding-right: 24px;
            font-weight: bold;
            background-color: $color-11st-red;
            border-color: $color-11st-red !important;
            color: $color-gray-14;

            .delete {
                display: block;
                position: absolute;
                top: 6px;
                right: 4px;
                padding: 3px;
                @include ico-cancel($size: 10px, $thick: 1px, $color: #f7ced7, $padding: 0);
                line-height: 200px;
            }
        }
    }

    .filter_box {
        display: flex;
        font-size: 0;
        line-height: 0;
    }

    .delivery_filter {
        position: relative;

        & + .promotion_filter {
            &::before {
                content: "";
                display: inline-block;
                margin: 2px 10px 0 4px;
                width: 1px;
                height: 28px;
                background-color: $color-gray-10;
                vertical-align: top;
            }
        }
    }

    .side {
        height: 16px;
        margin-top: 6px;

        li {
            position: relative;
            float: left;

            &:last-child {
                &::before {
                    content: "";
                    float: left;
                    margin: 6px 7px;
                    width: 2px;
                    height: 2px;
                    border-radius: 4px;
                    background-color: $color-gray-09;
                }
            }
        }

        .help_box {
            position: absolute;
            top: -4px;
            left: 0;

            .help {
                display: inline-block;
                overflow: hidden;
                width: 14px;
                height: 14px;
                margin: -1px 0 1px;
                vertical-align: middle;
                font-size: 1px;
                line-height: 200px;
                @include sprite-retina($sp_mini_2x_question);
            }
        }

        .c-button {
            float: left;

            &--bold {
                margin-left: 17px;
            }

            &--item {
                margin-right: 0;
                @include c-button-set(13px, 0 3px, 34px, 16px, auto, 0);
                background-color: transparent;
                border: 0 none !important;
                color: $color-gray-01;
            }
        }
    }
}

.store_product_list {
    display: flex !important;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin-left: calc(-16px / 2);
}

.store_product_item {
    flex-grow: 0;
    flex-shrink: 1;
    width: 33.333%;
    padding: 8px 0;
}

.store_product_link {
    display: block;
    position: relative;
    margin: 8px;
    height: 100%;
    background-color: #fff;
    zoom: 1;
    @include box-border();

    &:hover .store_product_name,
    &:active .store_product_name,
    &:focus .store_product_name {
        text-decoration: underline;
    }
}

.store_product_thumb_wrap {
    position: relative;
    padding-top: 100%;
}

.store_product_thumb {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 100%;
    height: 100%;
    margin: auto;
}

.store_product_info {
    position: relative;
    padding: 12px 15px 43px;
    zoom: 1;
    * {
        font-family: $font-family-lato-all;
    }

    .consult_info {
        display: block;
        position: static;
        margin-top: 19px;
        text-align: left;

        .consult_txt {
            color: $color-11st-blue;
            font-size: 14px;
            font-weight: normal;
        }
    }
}

.store_product_name {
    @extend .fs14;
    display: block;
    height: 20px;
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.store_product_price_area {
    display: block;
    height: 34px;
    margin-top: 4px;
}

.store_product_old_price {
    display: block;
    height: 15px;
    color: #999;
    line-height: 15px;

    em {
        display: inline-block;
        padding-right: 1px;
        font-size: 14px;
        line-height: 15px;
        text-decoration: line-through;
        vertical-align: baseline;
    }

    .price_unit {
        display: inline-block;
        font-size: 12px;
        text-decoration: line-through;
    }
}

.store_product_new_price {
    display: block;
    height: 19px;
    color: #111;
    line-height: 19px;

    em {
        display: inline-block;
        padding-right: 1px;
        font-weight: bold;
        font-size: 18px;
        line-height: 19px;
        vertical-align: top;
    }

    .price_unit {
        display: inline-block;
        font-size: 14px;
    }
}

.store_product_benefit {
    @include clearfix();
    position: absolute;
    left: 3px;
    bottom: 15px;
    overflow: hidden;
    height: 14px;
    width: inherit;
    color: $color-gray-04;
    font-family: $font-family-lato-all;
    font-size: 12px;
    line-height: 14px;

    // 할인적립 표기 방식
    em.uiq_sale,
    span.uiq_sale {
        position: relative;
        float: left;
        display: block;
        color: $color-gray-04;
        word-spacing: 0;
        padding: 0 0 0 12px;

        .point {
            color: $color-11st-red;
        }

        .point2 {
            color: $color-11st-blue;
        }

        .lsn {
            letter-spacing: 0;
        }

        &:not(:first-child) {
            &::before {
                position: absolute;
                left: 5px;
                top: 6px;
                width: 2px;
                height: 2px;
                background-color: $color-gray-09;
                border-radius: 50%;
                content: "";
            }
        }
    }
}

// mini_f_make_over.html
.store_recommand_product_wrap {
    position: relative;
    margin-top: 40px;

    .point {
        font-weight: normal;
        color: #ff0038;
    }

    /* MPSR-51108 delete */
    h3 {
        font-weight: normal;
        padding-bottom: 12px;
        font-size: 20px;
        line-height: 28px;
    }

    .view_more {
        position: absolute;
        right: 0;
        top: 9px;
        padding-right: 10px;
        background: url("/img/mini/home/icon_view_all_arrow.png") no-repeat 100% 0;
        font-size: 12px;
        line-height: 18px;
        color: #666;
    }

    /* // MPSR-51108 delete */
}

.store_recommand_product {
    position: relative;
    height: 272px;
    background-color: #fff;
    overflow: hidden;
    @include box-border();
}

.store_recommand_product_list {
    position: relative;
    height: 300px;
    padding: 16px 0 16px 16px;
    font-size: 0;
    white-space: nowrap;
    @include scroll-x();
}

.store_recommand_product_item {
    display: inline-block;
    *display: inline;
    zoom: 1;
    padding-right: 16px;
    vertical-align: top;

    .consult_info {
        display: block;
        left: 14px;
        bottom: 8px;
        top: auto;
        text-align: left;
    }
}

.store_recommand_product_link {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 240px;
    padding-top: 100%;
    vertical-align: top;
}

// recommand_product
.recommand_product_thumb {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    margin: auto;
}

.recommand_product_mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url("/img/mini/home/thumb_mask.png") no-repeat 50% 50%;
}

.recommand_product_price_area {
    position: absolute;
    left: 14px;
    bottom: 14px;
    color: #fff;

    .old_price {
        position: absolute;
        bottom: 18px;
        font-size: 12px;
        line-height: 16px;
        text-decoration: line-through;

        em {
            font-family: sans-serif;
        }
    }

    .new_price {
        em {
            display: inline-block;
            padding-top: 3px;
            font-weight: bold;
            font-size: 15px;
            line-height: 17px;
            font-family: sans-serif;
            vertical-align: top;
        }

        .price_unit {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            vertical-align: top;
        }
    }
}

// delivery_product
.delivery_header {
    overflow: hidden;
    zoom: 1;
    margin-top: 30px;

    .delivery_title {
        float: left;
        margin-right: 20px;
        padding: 4px 0;
        font-weight: normal;
        font-size: 22px;
        line-height: 32px;
    }

    .delivery_explain {
        @extend .fs14;
        float: left;
        padding: 13px 0 7px;
        color: #666;

        em {
            color: $color-11st-red;
        }
    }
}

.delivery_product {
    overflow: hidden;
    margin-top: 20px;
    zoom: 1;
    @include box-border();
}

.delivery_title {
    float: left;
    padding: 4px 0;
    font-weight: normal;
    font-size: 22px;
    line-height: 32px;
}

.selected_product {
    overflow: hidden;
    float: left;
    width: 300px;
    background-color: #788394;
}

.selected_product_title {
    @extend .fs14;
    padding: 19px 0 13px;
    font-weight: normal;
    color: #fff;
    text-align: center;
}

.bundle_product_title {
    @extend .fs14;
    padding: 19px 30px 13px;
    font-weight: normal;
    color: #111;
}

.bundle_product_wrap {
    position: relative;
    height: 421px;
    background-color: #fff;
    overflow: hidden;
    zoom: 1;
}

.bundle_product {
    overflow: hidden;
    overflow-x: auto;
    position: relative;
    height: 400px;
    -webkit-overflow-scrolling: touch;
}

.bundle_product_list {
    overflow: hidden;
    padding: 0 22px;
}

.bundle_product_item {
    overflow: hidden;
    float: left;
    width: 256px;
}

.bundle_product_link {
    display: block;
    position: relative;
    margin: 8px;
    zoom: 1;

    &:focus .bundle_product_name,
    &:active .bundle_product_name,
    &:hover .bundle_product_name {
        text-decoration: underline;
    }
}

.bundle_product_thumb_wrap {
    position: relative;
    padding-top: 100%;
}

.bundle_product_thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.bundle_product_info {
    padding: 12px 15px 43px;
}

.bundle_product_name {
    @extend .fs14;
    display: block;
    height: 20px;
    font-weight: normal;
    letter-spacing: -1px;
    overflow: hidden;
    white-space: nowrap;
    /*text-overflow: ellipsis;*/
}

.bundle_product_price_area {
    display: block;
    height: 34px;
    margin-top: 4px;
}

.bundle_product_old_price {
    display: block;
    height: 15px;
    color: #999;

    em {
        display: inline-block;
        padding-right: 1px;
        font-size: 14px;
        font-family: sans-serif;
        line-height: 15px;
        letter-spacing: -1px;
        text-decoration: line-through;
        vertical-align: top;
    }

    .price_unit {
        display: inline-block;
        font-size: 12px;
        line-height: 15px;
        vertical-align: top;
        text-decoration: line-through;
    }
}

.bundle_product_new_price {
    display: block;
    color: #111;

    em {
        display: inline-block;
        padding-right: 1px;
        font-weight: bold;
        font-size: 18px;
        line-height: 19px;
        font-family: sans-serif;
        letter-spacing: -1px;
        vertical-align: top;
    }

    .price_unit {
        display: inline-block;
        font-size: 14px;
        line-height: 19px;
        vertical-align: top;
    }
}

.bundle_product_benefit {
    position: absolute;
    left: 15px;
    bottom: 15px;
    font-size: 14px;
    line-height: 18px;
    color: #666;
    word-spacing: 4px;
    white-space: nowrap;

    span {
        word-spacing: 0;
    }
}

.selected_product {
    .bundle_product_link,
    .bundle_product_name,
    .bundle_product_old_price,
    .bundle_product_new_price,
    .bundle_product_benefit {
        color: #fff;
    }
}

// promotion
.store_module_wrap.wrap_type2 {
    padding: 20px 0;
    background-color: #fff;
    @include box-border();

    .store_title {
        padding: 8px 80px 21px 20px;
    }

    .btn_more {
        top: 35px;
        right: 20px;
    }
}

// MPSR-61892 적용 후 삭제 시작 , 결과없음
.store_content_nothing_wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: -30px auto 0;
}

.store_nothing_title {
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #111;
    text-align: center;

    strong {
        font-weight: normal;
        color: $color-11st-red;
    }
}

.store_nothing_explain {
    margin-top: 9px;
    font-size: 18px;
    line-height: 24px;
    color: #666;
    text-align: center;
}
// MPSR-61892 적용 후 삭제 끝

.s_store_result {
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;

    p {
        font-size: 18px;
        line-height: 24px;
        color: #666;

        & > strong {
            display: block;
            margin: 0 auto 13px;
            color: #111;
            font-size: 20px;
            font-weight: normal;

            i {
                font-style: normal;
                color: $color-11st-red;
            }
        }
    }
}
