@charset "UTF-8";

.store_header {
    border-bottom: 1px solid #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    background-color: #fff;
}

.store_gnb_wrap {
    border-bottom: 1px solid #f7f7f7;
    background-color: #fcfcfc;
}

.store_gnb {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding: 11px 0;
    z-index: 300;
    zoom: 1;

    &:after {
        display: block;
        clear: both;
        content: "";
    }
}

.store_info {
    display: inline-block;
    float: left;
    margin-right: 15px;
    padding: 1px 27px 1px 0;
    background: url("/img/mini/home/icon_seller_info_arrow.png") no-repeat 100% 1px;
}

.store_info_layer_close {
    position: absolute;
    right: 15px;
    top: 12px;
    width: 26px;
    height: 26px;
    background: url("/img/mini/home/icon_store_info_layer_x.png") no-repeat 50% 50%;
}

.ly_store_info {
    display: none;
    position: absolute;
    left: 0;
    top: 33px;
    width: 378px;
    border: 1px solid #333;
    background-color: #fff;
    color: #111;
}

.store_info_section {
    padding: 15px 20px 20px;
}

.ly_store_info {
    h3 {
        margin: 0 -20px;
        padding: 0 50px 13px 20px;
        border-bottom: 1px solid #e5e5e5;
        font-size: 14px;
        line-height: 20px;
    }

    .sub_tit {
        margin-top: 21px;
        font-size: 13px;
    }

    .store_grade_summary {
        margin-top: 17px;
        padding: 19px 0 14px;
        text-align: center;
        font-size: 0;
        border-top: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;

        li {
            display: inline-block;
            width: 33%;
            font-size: 12px;
            color: #666;
        }

        .num {
            display: block;
            margin-bottom: 4px;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            color: #111;
        }

        .txt_ing {
            display: block;
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 20px;
            color: #ccc;
        }

        .bg_box {
            display: block;
            position: relative;
            margin: 0 auto 9px;
            width: 42px;
            height: 20px;
            background: url("/img/common/sp_grade.png") no-repeat 0 0;

            .percent {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                background: url("/img/common/sp_grade.png") no-repeat 0 -24px;
                line-height: 200px;
            }
        }
    }

    .store_status {
        margin: 10px 0 20px;
        overflow: hidden;

        dt {
            float: left;
            clear: both;
            width: 61px;
            margin: 10px 9px 0 0;
            border: 1px solid #eee;
            border-radius: 20px;
            font-size: 10px;
            line-height: 18px;
            text-align: center;
        }

        dd {
            float: left;
            margin-top: 10px;
            color: #666;
            line-height: 20px;

            .txt_point {
                color: $color-11st-blue;
            }
        }
    }

    .popularity_box {
        margin-top: 15px;
        padding: 20px;
        background-color: #fafafa;
        font-size: 12px;
        color: #8d8d8d;
        line-height: 18px;

        .dsc {
            font-size: 11px;
            line-height: 17px;
            color: #666;

            dt {
                color: #111;
            }

            dd + dt {
                margin-top: 10px;
            }

            dd:before {
                display: inline-block;
                width: 2px;
                height: 2px;
                margin-right: 6px;
                background: #666;
                content: "";
                vertical-align: middle;
            }
        }
    }

    .store_info_detail {
        overflow: hidden;
        margin-top: 2px;
        font-size: 12px;
        line-height: 18px;

        dt {
            float: left;
            padding: 12px 8px 0 0;
            color: #999;
        }

        dd {
            overflow: hidden;
            min-height: 20px;
            padding: 12px 0 0;
            word-break: keep-all;
            word-wrap: break-word;
            zoom: 1;
        }
    }
}

.store_notice {
    color: #999;

    em {
        margin-right: 5px;
        color: #666;
    }
}

.my_store_area {
    position: absolute;
    right: 0;
    top: 8px;

    .my_store_setting {
        display: inline-block;
        margin-right: 6px;
        padding: 4px 12px 3px 30px;
        border-bottom: 1px solid #d83643;
        background: #fe404f url("/img/mini/home/icon_my_store_setting.png") no-repeat 0 0;
        color: #fff;
    }
}

.my_store {
    display: inline-block;
    padding: 3px 30px 3px 10px;
    border: 1px solid #ccc;
    background: #fff url("/img/mini/home/icon_my_stores_arrow.png") no-repeat 100% 0;
    color: #666;

    &.on {
        background-position: 100% 100%;
    }
}

.my_store_list_layer {
    display: none;
    position: absolute;
    right: 0;
    top: 34px;
    width: 500px;
    border: 1px solid #333;
    background-color: #fff;
}

.my_store_layer_close {
    position: absolute;
    right: 15px;
    top: 12px;
    width: 26px;
    height: 26px;
    background: url("/img/mini/home/icon_store_info_layer_x.png") no-repeat 50% 50%;
}

.my_store_list_layer h3 {
    @extend .fs14;
    padding: 15px 20px;
}

.my_store_list_area {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 404px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.my_store_list {
    overflow: hidden;
    margin: -1px;
    padding: 0.5px;
    background-color: #ebebeb;
    zoom: 1;
}

.my_store_item {
    float: left;
    width: 50%;
}

.my_store_link {
    display: block;
    padding: 0.5px;
    font-size: 14px;
    line-height: 20px;

    > span {
        display: block;
        height: 20px;
        padding: 12px 20px;
        background-color: #fff;
        white-space: nowrap;
    }

    &:focus,
    &:active,
    &:hover {
        text-decoration: none;
        outline: none;
    }
}

a.my_store_link {
    &:focus > span,
    &:active > span,
    &:hover > span {
        background-color: #f4f4f4;
        outline: 1px solid #ccc;
    }
}

.my_store_link {
    .my_store_name {
        display: inline-block;
        max-width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: top;
    }

    em {
        display: inline-block;
        padding-top: 2px;
        font-size: 11px;
        line-height: 18px;
        word-spacing: -2px;
        vertical-align: top;

        &.event {
            color: #536dfe;
        }

        &.coupon {
            color: $color-11st-red;
        }
    }
}

.my_store_nothing {
    padding: 42px 0 45px;
    border-top: 1px solid #ccc;

    .p1 {
        font-size: 18px;
        line-height: 28px;
        color: #333;
        text-align: center;
    }

    .p2 {
        @extend .fs14;
        margin-top: 7px;
        color: #999;
        text-align: center;
    }

    .p1 span {
        color: $color-11st-red;
    }
}

.my_store_list_layer {
    .btn_area {
        padding: 15px 0;
        text-align: center;
    }

    .btn_my_store {
        display: inline-block;
        padding: 9px 35px;
        background-color: #333;
        color: #fff;
        text-align: center;

        &:focus,
        &:active,
        &:hover {
            text-decoration: none;
        }
    }
}

.store_name_area {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding: 23px 0 29px;
    z-index: 200;
}

.store_name {
    display: inline-block;
    *display: inline;
    zoom: 1;
    margin-right: 10px;
    font-size: 28px;
    line-height: 36px;
    color: #111;
    vertical-align: top;
}

.store_emblem {
    display: inline-block;
    width: 32px;
    height: 34px;
    margin: 3px 10px -3px 0;
    background: url("/img/mini/home/icon_emblem.png") no-repeat;
    vertical-align: middle;
}

.store_copy_area {
    display: inline-block;
    *display: inline;
    zoom: 1;
    margin-top: 10px;
    font-size: 0;
    vertical-align: top;

    & > span {
        display: inline-block;
        height: 18px;
        padding: 0 7px 0 6px;
        margin-right: 6px;
        font-size: 12px;
        border-width: 1px;
        border-style: solid;
        border-radius: 15px;
        letter-spacing: -1px;
        vertical-align: middle;
    }

    .store_type {
        background: #fe4050;
        border-color: #fe4050;
        color: #fff;
    }

    .store_best {
        background: #fff;
        border-color: rgba(244, 49, 66, 0.8);
        color: $color-11st-red;
    }

    .store_road_bg {
        background: #51d3b0;
        border-color: #51d3b0;
        color: #fff;
    }

    .store_road_txt {
        background: #fff;
        border-color: #51d3b0;
        color: #01c48f;
    }

    .store_copy {
        padding: 1px 0;
        border: 0 none;
    }

    .store_tag {
        margin: 0 0 0 2px;
        padding: 1px 0;
        border: 0 none;
        &:before {
            content: "#";
        }
        & + .store_tag {
            margin-left: 4px;
        }
    }
}

.store_zzim {
    position: absolute;
    right: 65px;
    top: 26px;
    height: 18px;
    padding: 9px 5px 9px 43px;
    background: url("/img/mini/home/icon_store_zzim.png") no-repeat 0 0;

    &.on {
        background-position: 0 -36px;
    }

    .store_count {
        font-weight: bold;
    }
}

.store_share {
    position: absolute;
    right: 13px;
    top: 26px;
    width: 36px;
    height: 36px;
    background: url("/img/mini/home/icon_store_share.png") no-repeat 0 0;
}

.store_share_layer {
    display: none;
    position: absolute;
    right: 0;
    top: 68px;
    width: 338px;
    border: 1px solid #333;
    background-color: #fff;
}

.store_share_layer_close {
    position: absolute;
    right: 15px;
    top: 12px;
    width: 26px;
    height: 26px;
    background: url("/img/mini/home/icon_store_info_layer_x.png") no-repeat 50% 50%;
}

.store_share_layer {
    h3 {
        @extend .fs14;
        padding: 15px 20px;
    }

    .sns_list_area {
        padding: 20px 20px 3px;
        border-top: 1px solid #ccc;
    }

    .sns_list {
        text-align: center;
        white-space: nowrap;
    }

    .sns_item {
        display: inline-block;
        width: 66px;
        text-align: center;
        vertical-align: top;
    }

    .sns_icon {
        display: block;
        width: 50px;
        height: 50px;
        margin: 0 auto 3px;
        border-radius: 25px;
        background: url("/img/mini/home/icon_sns.png") no-repeat -100px 0;

        &.facebook {
            background-position: -1px -1px;
        }

        &.twitter {
            background-position: -1px -53px;
        }

        &.kakaostory {
            background-position: -1px -105px;
        }

        &--x {
            background-position: -1px -157px;
        }
    }

    .copy_url {
        padding: 20px;

        input {
            @extend .fs14;
            width: 217px;
            height: 20px;
            padding: 9px 0;
            border: solid #ccc;
            border-width: 1px 0 1px 1px;
            color: #999;
            text-indent: 8px;
        }

        button {
            @extend .fs14;
            width: 80px;
            height: 40px;
            padding: 10px 0;
            border: 0;
            background-color: #333;
            color: #fff;
            text-align: center;
        }
    }
}

.layer_frequenter {
    position: absolute;
    top: 75px;
    right: 0;
    width: 211px;
    padding: 38px 22px 16px;
    border: 1px solid #4c4c4c;
    background: #fff;
    letter-spacing: -1px;
    text-align: center;

    .freq_store {
        @extend .fs12;
        display: block;
        max-width: 135px;
        margin: 0 auto;
        font-size: 12px;
        font-weight: normal;
        color: #333;
    }

    .freq_cntbx {
        min-height: 60px;
        vertical-align: middle;

        &:before {
            display: inline-block;
            min-height: 60px;
            vertical-align: middle;
            content: "";
        }
    }

    .freq_inner {
        display: inline-block;
        vertical-align: middle;
    }

    .freq_txt2 {
        @extend .fs14;
        margin-top: 8px;
        font-weight: bold;
    }

    .freq_txt {
        @extend .fs12;

        .store {
            font-weight: bold;
        }

        .point {
            font-weight: normal;
            color: $color-11st-red;
        }
    }

    .btn_bx {
        margin-top: 16px;

        a {
            display: inline-block;
            width: 100px;
            margin-left: 2px;
            font-size: 12px;
            line-height: 30px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .btn_like {
        @include button-style(null, #333, #fff);
    }

    .btn_next {
        @include button-style(null, #999, #fff);
    }

    .inp_wrap {
        position: relative;
        margin-top: 16px;
        text-align: left;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            border: 0;
            background: none;
            opacity: 0;
            filter: Alpha(opacity=0);
        }

        label {
            @extend .fs12;
            display: inline-block;
            padding-left: 24px;
            background: url("/img/mini/home/icon_check.png") no-repeat 0 50%;
            color: #666;
            line-height: 18px;
        }

        &.checked label {
            background-image: url("/img/mini/home/icon_check_active.png");
        }
    }

    .ico_arrow {
        position: absolute;
        top: 0;
        left: 50%;
        width: 20px;
        height: 11px;
        margin: -11px 0 0 -10px;
        background: url("/img/mini/home/icon_frequenter_arrow.png") no-repeat;
    }

    .btn_close {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 34px;
        height: 34px;
        background: url("/img/mini/home/icon_frequenter_close.png") no-repeat 50% 50%;
    }
}
