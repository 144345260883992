@charset "UTF-8";

// 별점
.star_box {
    position: relative;
    width: 74px;
    height: 15px;
    background: url("/img/mini/home/sp_star.png") no-repeat;

    .star_point {
        overflow: hidden;
        position: absolute;
        top: -1px;
        left: 0;
        height: 100%;
        background: url("/img/mini/home/sp_star.png") no-repeat 0 -18px;
        font-size: 0;
        line-height: 200px;
    }
}

.store_percent_list {
    margin-top: 12px;
    font-size: 12px;
    color: $color-11st-bluegray;

    li + li {
        margin-top: 2px;
    }

    .txt {
        float: left;
        width: 60px;
        color: #111;
        text-align: center;
    }

    .bg_box {
        float: left;
        position: relative;
        width: 212px;
        height: 6px;
        margin: 6px 0 0;
        background: #eff1f7;
        vertical-align: middle;
    }

    .percent {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        height: 6px;
        background: $primary-default-color;
        line-height: 200px;
    }

    .txt_point {
        display: inline-block;
        width: 50px;
        color: $primary-default-color;
        text-align: right;
    }

    .good {
        .percent {
            background: $color-11st-blue;
        }

        .txt_point {
            color: $color-11st-blue;
        }
    }
}
