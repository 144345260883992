@charset "UTF-8";

.store_category_coupon {
    padding-top: 38px;

    .store_coupon_profile .btn_dnld {
        .ico_dnld {
            width: 18px;
            background-position: 0 -19px;
        }

        .num {
            display: none;
        }
    }
}

.store_coupon {
    position: relative;
    z-index: 10;    // 상품목록 필터 레이어(9) 위
}

.store_coupon_list {
    margin: 0 -4px -8px;
    @include clearfix;
}

.store_coupon_item {
    float: left;
    width: 50%;
    margin-bottom: 8px;
}

.store_coupon_profile {
    position: relative;
    margin: 0 4px;
    background: #fff;
    @include clearfix;

    .store_coupon_l {
        height: 40px;
        padding: 20px;
        box-shadow: inset 0 -1px 0 #c4cbcb;
    }

    .store_coupon_r {
        position: absolute;
        top: 0;
        right: 0;
        background: url('/img/mini/home/bg_coupon.gif') no-repeat 0 0;
    }

    .coupon_type {
        display: block;
        margin-bottom: 5px;
        font-size: 15px;
        color: $color-11st-red;

        .num {
            font-size: 16px;
            font-family: $font-family-number;
        }
    }

    .txt {
        margin-right: 5px;

        .num {
            font-size: 13px;
            font-family: $font-family-number;
        }
    }

    .btn {
        color: #999;

        &:after {
            display: inline-block;
            width: 17px;
            height: 17px;
            margin-left: 4px;
            background: url('/img/mini/home/sp_coupon.png') no-repeat -38px 0;
            content: '';
            vertical-align: middle;
        }

        &.btn_add:after {
            background: url('/img/mini/home/sp_coupon.png') no-repeat -19px 0;
        }
    }

    .txt_check {
        color: $color-11st-red;
        font-size: 13px;

        &:before {
            display: inline-block;
            width: 14px;
            height: 9px;
            margin-right: 4px;
            background: url('/img/mini/home/sp_coupon.png') no-repeat -56px -27px;
            content: '';
            vertical-align: middle;
        }
    }

    .btn_dnld {
        width: 76px;
        height: 80px;
        margin-left: 9px;
        font-size: 14px;
        color: #999;
        text-align: center;
        background: #fff;
        box-shadow: inset 0 -1px 0 #c4cbcb;

        .ico_dnld {
            overflow: hidden;
            display: block;
            width: 17px;
            height: 17px;
            margin: 2px auto 0;
            background: url('/img/mini/home/sp_coupon.png') no-repeat 0 0;
            line-height: 200px;
        }

        .num {
            display: block;
            color: $color-11st-red;
        }
    }

    span.btn_dnld {
        display: inline-block;
        overflow: hidden;
        line-height: 78px;
    }

    .layer_frequenter {
        width: 314px;
        top: 66px;
        left: 0;
        right: auto;
        padding: 18px 20px 20px;
        text-align: left;

        .tit {
            color: #111;
            font-size: 14px;
        }

        .cont_box {
            margin-top: 18px;
            padding: 14px 16px 16px;
            background: #f4f4f4;
            line-height: 20px;
        }
    }
}

.coupon_more {
    display: inline-block;
    margin: -5px 0 0 7px;
    font-size: 12px;
    line-height: 22px;
    color: #666;
    vertical-align: middle;

    &:after {
        display: inline-block;
        width: 6px;
        height: 7px;
        margin: 0 0 0 4px;
        background: url('/img/mini/home/sp_coupon.png') no-repeat -21px -19px;
        content: '';
        vertical-align: middle;
    }
}
