@charset "UTF-8";

.store_visual_wrap {
    position: relative;
    margin-top: 30px;

    &+& {
        margin-top: 4px;
    }

    .store_visual_list {
        overflow: hidden;
        position: relative;
        width: 100%;

        .store_visual_list_in {
            margin: -2px;
        }
    }

    .store_visual_item {
        float: left;
        position: relative;
        width: 492px;
        height: 492px;

        .store_visual_link {
            overflow: hidden;
            position: absolute;
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;
            background: url('/img/mini/main/img_default_visual.jpg') no-repeat 0 0;
        }

        figure {
            &:after {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: url('/img/mini/home/thumb_mask_488_x2.png');
                background-size: 100% 100%;
                content: '';
            }

            >img {
                position: absolute;
                top: 0;
                left: 50%;
                height: 100%;
                margin: auto;
                transform: translateX(-50%);
            }
        }

        figcaption {
            position: absolute;
            right: 35px;
            bottom: 35px;
            left: 35px;
            z-index: 2;
            color: $light-color;
            font-size: 20px;
            line-height: 1.2;
            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);

            >strong {
                display: block;
                font-size: 38px;
                @include text-ellipsis;
            }

            >span {
                display: block;
                margin-top: 6px;
            }
            .btn {
                display: inline-block;
                min-width: 75px;
                margin-top: 15px;
                padding: 5px 4px;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.04);
                border: solid 1px rgba(255, 255, 255, 0.6);
                border-radius: 4px;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .text_add {
            display: block;
            margin-top: 12px;
            color: $light-color;
            font-size: 15px;
            font-weight: bold;
        }

        .icon_go {
            display: block;
            width: 43px;
            height: 43px;
            margin: 15px auto 0;
            background: url('/img/mini/home/icon_profile_circle_arrow.png') no-repeat 0 -53px;
            opacity: 0;
            transform: translateY(30px);
            transition: 0.2s ease-out;
        }
    }

    .store_visual_product {
        position: relative;
        float: left;
        width: 50%;
        padding-top: 50%;
    }

    .store_visual_product_link {
        overflow: hidden;
        position: absolute;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        background-color: $light-color;

        figcaption {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
            text-align: center;
            transition: 0.3s ease-out;

            >strong {
                @include text-ellipsis-multiple(2);
                display: none;
                position: absolute;
                left: 20px;
                right: 20px;
                bottom: 130px;
                max-height: 44px;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                color: #fff;
                word-break: keep-all;
                word-wrap: break-word;
                white-space: normal;
                text-overflow: initial;

                &.title {
                    bottom: 100px;
                }
            }

            .text_price {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                top: 124px;
                color: #fff;
                text-align: center;
            }

            .text_em {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                top: 124px;
                text-align: center;
                color: #fff;
                font-size: 15px;
                font-weight: bold;
            }

            .icon_go {
                position: absolute;
                left: 50%;
                bottom: 37px;
                width: 43px;
                height: 43px;
                margin-left: -22px;
                background: url(/img/mini/home/icon_profile_circle_arrow.png) no-repeat 0 -53px;
                opacity: 0;
                transform: translateY(30px);
                transition: 0.2s ease-out;
            }
        }

        &:focus figcaption,
        &:active figcaption,
        &:hover figcaption {
            background-color: rgba(0, 0, 0, 0.5);
            background: url('/img/mini/home/opacity50.png')\9;
        }

        &:focus figcaption strong,
        &:active figcaption strong,
        &:hover figcaption strong {
            display: block;
            display: -webkit-box;
        }

        &:focus figcaption .text_price,
        &:active figcaption .text_price,
        &:hover figcaption .text_price,
        &:focus figcaption .text_em,
        &:active figcaption .text_em,
        &:hover figcaption .text_em {
            display: block;
        }

        &:focus .icon_go,
        &:active .icon_go,
        &:hover .icon_go {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.store_wrap {
    .btn_view_prev {
        overflow: hidden;
        position: absolute;
        z-index: 2;
        left: 0;
        top: 50%;
        width: 60px;
        height: 60px;
        margin-top: -30px;
        background: url(/img/mini/home/icon_swipe_arrow.png) no-repeat -1px -1px;
        line-height: 200px;

        &:hover {
            background-position: -1px -63px;
        }
    }

    .btn_view_next {
        overflow: hidden;
        position: absolute;
        z-index: 2;
        right: 0;
        top: 50%;
        width: 60px;
        height: 60px;
        margin-top: -30px;
        background: url('/img/mini/home/icon_swipe_arrow.png') no-repeat -63px -1px;
        line-height: 200px;

        &:hover {
            background-position: -63px -63px;
        }
    }

    .pagination_view {
        margin-top: 14px;
        height: 10px;
        margin-top: 10px;
        text-align: center;

        .btn_icon {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin: 0 2px;
            background: #ccc;
            border-radius: 5px;
            text-indent: -9999px;
            vertical-align: top;
            line-height: 10px;

            &.active {
                background: #000;
            }
        }
    }
}

.store_visual_list.list_type2 {
    .store_visual_link {
        background: url('/img/mini/main/img_default_visual_22.jpg') no-repeat 0 0;
    }
    .store_visual_item {
        width: 564px;
        height: 282px;

        figure {
            &:after {
                background-image: url('/img/mini/home/thumb_mask_416x278_x2.png');
            }
        }

        &.item_type2 {
            width: 420px;

            figure>img {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: auto;
                transform: translateY(-50%);
            }
        }
    }

    .store_visual_main {
        figcaption>strong {
            font-size: 30px;
        }
    }

    .store_visual_product {
        figcaption {
            >strong {
                top: 86px;
            }

            .text_price {
                top: 142px;
            }

            .icon_go {
                bottom: 55px;
            }
        }
    }
}

.store_visual_list.list_type3 {
    .store_visual_link {
        background: url('/img/mini/main/img_default_visual_21.jpg') no-repeat 0 0;
    }
    .store_visual_item {
        height: 330px;

        figure {
            &:after {
                background-image: url('/img/mini/home/thumb_mask_488x326_x2.png');
            }

            >img {
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: auto;
                transform: translateY(-50%);
            }
        }
    }
}


.store_banner {
    overflow: hidden;
    margin-top: 40px;

    &+& {
        margin-top: 16px;
    }
}

.store_banner_list {
    overflow: hidden;
    margin: -8px;
    zoom: 1;
}

.store_banner_item {
    float: left;
}

.store_banner_list {

    &.banner1 .store_banner_item,
    &.banner2 .store_banner_item {
        width: 50%;
    }

    &.banner3 .store_banner_item {
        width: 33.3333%;
        .store_banner_link {
            height: 158px;
        }
    }
}

.store_banner_link {
    display: block;
    height: 241px;
    margin: 8px;
    background: url('/img/mini/main/img_default_banner.jpg') no-repeat 0 0;
    background-size: 100% 100%;
    @include box-border;

    img {
        display: block;
        width: 100%;
    }
}

// 셀러기획전 배너
.store_banner_seller {
    display: table;
    width: 482px;
    height: 240px;
    margin: 8px;
    background-color: #fff;
    vertical-align: middle;
    text-align: center;
    @include box-border;

    .banner_in {
        display: table-cell;
        vertical-align: middle;
    }

    strong {
        font-size: 24px;
        line-height: 32px;
        color: #000;
        text-decoration: underline;
    }

    .text {
        display: block;
        margin-top: 17px;
        padding: 0 50px;
        font-size: 15px;
        line-height: 1.5;
        color: #000;
    }

    .btn {
        position: relative;
        display: inline-block;
        margin-top: 30px;
        min-width: 111px;
        padding: 10px 20px 10px 44px;
        background-color: #333;
        border-radius: 22px;
        color: #fff;
        font-weight: bold;
        font-size: 15px;
        line-height: 24px;
        word-break: keep-all;
        word-wrap: break-word;

        &:before {
            position: absolute;
            @include sprite-retina($sp_mini_2x_icon_store_zzim);
            top: 50%;
            left: 20px;
            display: inline-block;
            margin-top: -12px;
            vertical-align: middle;
            transition-duration: 0.15s;
            content: '';
        }

        &.active:before {
            @include sprite-retina($sp_mini_2x_icon_store_zzim_on);
        }
    }
}

    /* MPSR-51108 삭제 */
.store_banner_profile {
    position: relative;
    height: 240px;
    margin: 8px;
    background-color: #fff;
    @include box-border;

    strong {
        position: absolute;
        left: 30px;
        right: 30px;
        top: 36px;
        font-size: 24px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        text-decoration: underline;
    }

    p {
        position: absolute;
        left: 30px;
        right: 30px;
        top: 81px;
        font-size: 15px;
        line-height: 26px;
        color: #fff;
        text-align: center;
        word-break: keep-all;
        word-wrap: break-word;
    }

    span {
        position: absolute;
        left: 30px;
        right: 30px;
        bottom: 44px;
        font-size: 15px;
        line-height: 20px;
        color: #666;
        text-align: center;
        word-break: keep-all;
        word-wrap: break-word;

        a {
            display: inline-block;
            min-width: 80px;
            padding: 12px 30px 12px 65px;
            border-radius: 22px;
            background: #fff url('/img/mini/home/icon_store_zzim2.png') no-repeat 24px 2px;
            transition-duration: 0.15s;

            &.on {
                background-position: 24px -38px;
            }
        }

        em {
            font-weight: bold;
        }
    }
}
    /* // MPSR-51108 삭제 */

.category_btn {
    position: relative;
    display: inline-block;
    width: 142px;
    height: 34px;
    margin: -9px 0 -5px;
    padding: 0 25px 0 10px;
    background-color: #fff;
    border: 1px solid #e3e3e8;
    text-align: left;
    color: #333;
    font-weight: bold;
    font-size: 13px !important;
    line-height: 32px !important;

    &:after {
        display: block;
        position: absolute;
        top: 50%;
        right: 13px;
        width: 7px;
        height: 7px;
        margin-top: -6px;
        border: 1px solid #aaa;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
        content: '';
    }

    &.on {
        background-position: 100% 100%;
        border-color: #666;

        &:after {
            border-width: 1px 0 0 1px;
            margin-top: -3px;
        }
    }

    &:focus,
    &:active,
    &:hover {
        text-decoration: none;
    }
}

.category_list_layer {
    display: none;
    position: absolute;
    left: 0;
    top: 33px;
    border: 1px solid #676767;
    background-color: #fff;
    z-index: 1;
    overflow: hidden;

    .category_list {
        float: left;
        width: 140px;
        margin-right: -1px;
        padding: 7px 0;
        border-right: 1px solid #dadada;

        +.category_list {
            margin-left: 1px;
        }
    }

    .category_link {
        display: block;
        padding: 5px 10px 4px;
        color: #333;
        font-size: 13px;

        &:hover {
            color: $color-11st-red;
            text-decoration: underline;
        }
    }
}

/* MPSR-51108 삭제 */
.store_banner_seller .text {
    word-break: normal !important;
    word-wrap: normal !important;
}

.category_list_layer {
    .category_link {
        &:hover {
            background-color: transparent;
            text-decoration: underline;
        }
    }
}
/* // MPSR-51108 삭제 */
