@charset "UTF-8";

.flag_text {
    display: inline-block;
    min-width: 34px;
    padding: 3px 5px;
    background-color: rgba(153, 153, 153, 0.06);
    text-align: center;
    border-radius: 2px;
    vertical-align: middle;
    color: #666;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: -0.5px;
}

.store_etc_wrap {
    position: relative;
    min-height: 708px;
    margin-top: 30px;
    padding: 30px;
    background-color: #fff;

    .store_etc_title {
        margin-bottom: 20px;
        color: #111;
        font-size: 18px;
        line-height: 1.5;
        letter-spacing: -0.5px;
    }
}

table.type_store_etc {
    width: 100%;
    table-layout: fixed;

    colgroup {
        .colwid1 {
            width: 56px;
        }

        .colwid3 {
            width: 80px;
        }
    }

    tr {
        th,
        td {
            height: 28px;
            padding: 10px 0;
            border-bottom: 1px solid #eee;
        }

        .title {
            overflow: hidden;
            display: table-cell;

            > a {
                overflow: hidden;
                display: block;
                width: 100%;
                font-size: 14px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .date {
            text-align: right;
            color: #999;
            font-size: 13px;
        }

        &:first-child {
            th,
            td {
                border-top: 1px solid #eee;
            }
        }

        &.item_em {
            .flag_text {
                background-color: rgba(244, 49, 66, 0.06);
                color: $color-11st-red;
            }

            .title {
                font-weight: bold;
            }
        }
    }
}

.paging {
    margin-top: 40px;
    text-align: center;
    font-size: 0;

    a,
    button,
    strong {
        display: inline-block;
        width: 30px;
        height: 21px;
        margin: 0 2px;
        padding-top: 7px;
        font-size: 12px;
        line-height: 1;
        text-decoration: none;
        vertical-align: middle;
    }

    a:hover,
    strong {
        color: #fff;
        background-color: $color-11st-red;
        border: 1px solid $color-11st-red;
        font-weight: normal;
    }

    a,
    button {
        color: #666;
        background: #fff;
        border: 1px solid #c7c7c7;
        font-size: 12px;
    }

    .first,
    .prev,
    .next,
    .last {
        overflow: hidden;
        width: 32px;
        height: 30px;
        border: none;
        background: url("/html/codebuild/img/sp.png") no-repeat;
        line-height: 999px;
    }

    .first {
        background-position: 0 -42px;
    }

    .prev {
        background-position: -36px -42px;
    }

    .next {
        background-position: 0 -74px;
    }

    .last {
        background-position: -36px -74px;
    }
}

.store_notice_view {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;

    .notice_header {
        padding: 20px 0 16px;

        .title {
            display: block;
            color: #333;
            font-size: 16px;
            letter-spacing: -0.5px;

            .flag_text {
                margin-right: 7px;
            }

            &.item_em {
                .flag_text {
                    background-color: rgba(244, 49, 66, 0.06);
                    color: $color-11st-red;
                }
            }
        }

        .date {
            margin-top: 10px;
            padding-left: 56px;
            color: #999;

            > em {
                margin-right: 8px;
            }
        }
    }

    .notice_content {
        padding: 0 16px 30px 56px;
        color: #333;
        font-size: 14px;
        line-height: 2;
        letter-spacing: -0.5px;
    }
}

.store_btn_wrap {
    margin-top: 20px;
    text-align: right;

    .btn {
        display: inline-block;
        width: 120px;
        height: 40px;
        border: 1px solid #ccc;
        color: #333;
        font-weight: bold;
        font-size: 14px;
        line-height: 38px;
    }
}
