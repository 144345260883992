@charset "UTF-8";

.ly_wrap {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    width: 100%;
    height: 100%;
    background: url('/img/mini/home/opacity50.png') repeat;
    font-family: $font-family-default;

    input[type="text"],
    a,
    button {
        font-family: $font-family-default;
    }

    a {
        text-decoration: none;

        &:focus,
        &:active,
        &:hover {
            text-decoration: none;
        }
    }

    .ly_inner {
        position: absolute;
        left: 50%;
        width: 644px;
        background: #fff;
        margin-left: -322px;
    }

    .ly_top_area {
        padding: 20px 30px;
        background: #fafafa;
    }

    .ly_cont_area {
        overflow-y: auto;
        max-height: calc(100vh - 550px);
        padding: 20px 20px 20px 30px;
    }

    .ly_btn_area {
        padding: 16px 0;
        text-align: center;
    }

    .btn_close {
        position: absolute;
        top: 11px;
        right: 18px;
        width: 40px;
        height: 40px;

        .ico_close {
            display: inline-block;
            overflow: hidden;
            width: 26px;
            height: 26px;
            background: url('/img/mini/home/icon_store_info_layer_x.png') no-repeat;
            font-size: 0;
            line-height: 200px;
            vertical-align: middle;
        }
    }
}
