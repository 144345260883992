@charset "UTF-8";

.store_content {
    .tag_list {
        margin: 20px 0 -10px;
        font-size: 12px;
        line-height: 18px;
    }

    .tag_item {
        display: inline-block;
        margin-bottom: 10px;
        padding: 5px 15px;
        border-radius: 14px;
        background-color: #a3a5a8;
        color: #fff;
        transition: 0.15s ease-out;

        &.on {
            background-color: #454748;
        }

        &:focus,
        &:active,
        &:hover {
            text-decoration: none;
        }
    }
}

.store_content .store_category_wrap {
    margin-top: 30px;
    background-color: #fff;
    @include box-border();
}

/* MPSR-51108 삭제 */
.store_category_path {
    font-size: 16px;
    line-height: 22px;

    a,
    .path_tit {
        display: block;
        padding: 12px 16px;
        font-weight: normal;
        color: #111;
    }

    a {
        background: url('/img/mini/home/icon_category_path.png') no-repeat 100% 100%;

        strong {
            font-weight: normal;
            color: $color-11st-red;
        }
    }

    &.on a {
        background-position: 100% 0;
    }
}
/* // MPSR-51108 삭제 */

.category_path {
    position: relative;
    padding: 7px 16px;
    font-size: 16px;
    line-height: 22px;

    a,
    span {
        display: inline-block;
        padding: 5px 0;
        vertical-align: middle;
        color: #111;
    }

    strong {
        font-weight: normal;
        vertical-align: middle;
        color: $color-11st-red;
    }

    button.view_toggle {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -16px;
    }
}

.store_wrap .btn_icon {
    overflow: hidden;
    position: relative;
    width: 32px;
    height: 32px;
    line-height: 200px;
    white-space: nowrap;

    &.view_toggle {
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 16px;
            height: 2px;
            margin: -1px 0 0 -8px;
            background-color: #7d7d7d;
            content: '';
        }

        &:after {
            @extend .view_toggle:before;
            transform: rotate(90deg);
        }

        &.on:after {
            content: none;
        }
    }
}

.store_category_1depth {
    display: none;
    overflow-y: auto;
    max-height: 120px;
    border-top: 1px solid #f0f0f0;

    &.on {
        display: block;
    }

    .category_list {
        overflow: hidden;
        padding: 8px 0 13px 10px;
        zoom: 1;
    }

    .category_item {
        float: left;
        width: 16.6666%;
    }

    .category_link {
        display: inline-block;
        padding: 4px 10px 6px;
        color: #666;

        &.selected {
            color: $color-11st-red;
        }
    }
}

.store_category_2depth {
    display: none;
    position: relative;
    height: 46px;
    border-top: 1px solid #f0f0f0;
    background-color: #f4f4f4;
    overflow: hidden;

    &.on {
        display: block;
    }

    .category_list {
        height: 50px;
        padding: 9px 0 9px 36px;
        white-space: nowrap;
        cursor: ew-resize;
        @include scroll-x();
    }

    .category_item {
        display: inline-block;
        *display: inline;
        zoom: 1;
        padding-right: 36px;
        font-size: 12px;
        line-height: 18px;

        +.category_item {
            margin-left: -36px;
        }
    }

    .category_link {
        display: block;
        padding: 4px 10px;
        border: 1px solid #f4f4f4;
        border-radius: 14px;
        color: #666;
        transition: 0.15s ease-out;

        &.selected {
            border-color: $color-11st-red;
            background-color: #fcfcfc;
            color: $color-11st-red;
            outline: none;

            &:focus,
            &:active,
            &:hover {
                text-decoration: none;
            }
        }
    }

    .prev {
        position: absolute;
        left: 0;
        top: 0;
        width: 32px;
        height: 46px;
        background: url('/img/mini/home/icon_category_arrow.png') no-repeat 0 0;
    }

    .next {
        position: absolute;
        right: 0;
        top: 0;
        width: 32px;
        height: 46px;
        background: url('/img/mini/home/icon_category_arrow.png') no-repeat 100% 0;
    }
}

.store_category_selected {
    display: none;
    position: relative;
    height: 46px;
    padding-left: 120px;
    border-top: 1px solid #f0f0f0;
    background-color: #f4f4f4;
    overflow: hidden;
    background: #fafafa;

    &.on {
        display: block;
    }

    &:before {
        position: absolute;
        top: 50%;
        left: 86px;
        width: 1px;
        height: 18px;
        margin-top: -8px;
        background: #ccc;
        content: '';
    }

    .prev {
        position: absolute;
        top: 0;
        left: 88px;
        width: 32px;
        height: 46px;
        background: #fafafa url('/img/mini/home/icon_category_arrow.png') no-repeat 0 -50px;
    }

    .next {
        position: absolute;
        right: 0;
        top: 0;
        width: 32px;
        height: 46px;
        background: #fafafa url('/img/mini/home/icon_category_arrow.png') no-repeat -32px -50px;
    }

    .selected_lst {
        position: relative;
        height: 50px;
        padding: 9px 30px 7px 0;
        font-size: 0;
        white-space: nowrap;
        cursor: ew-resize;
        @include scroll-x();
    }

    .category_item {
        display: inline-block;
        *display: inline;
        position: relative;
        zoom: 1;
        margin-right: 8px;
        padding: 0 28px 0 12px;
        border: 1px solid #e4e4e4;
        border-radius: 30px;
        background: #fff;
        font-size: 12px;
        color: #333;
        line-height: 28px;
    }

    .btn_category_clear {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 28px;

        .icon_delete {
            display: inline-block;
            overflow: hidden;
            width: 16px;
            height: 16px;
            margin: -1px 0 1px;
            background: url('/img/mini/home/icon_delete.png') no-repeat;
            line-height: 200px;
            vertical-align: middle;
        }
    }

    .btn_all_clear {
        position: absolute;
        top: 9px;
        left: 16px;
        height: 30px;

        .icon_clear {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin: -1px 6px 1px 0;
            background: url('/img/mini/home/icon_clear.png') no-repeat;
            vertical-align: middle;
        }
    }
}
