@charset "UTF-8";

.store_best_review_main {
    overflow: hidden;
    position: relative;
    height: 424px;
    background-color: #fff;
    @include box-border();

    .store_best_review_list {
        height: 420px;
        margin: 0;
        padding: 16px 0 16px 16px;
        white-space: nowrap;
        @include scroll-x();
    }

    .best_review_cont {
        width: 240px;
        padding-right: 16px;
        border-bottom: 0;
    }

    .store_review_content {
        position: relative;
        width: 238px;
        border: 1px solid #f0f0f0;
    }

    .store_review_area .review_box,
    .review_type_adult .certify_step {
        overflow: hidden;
        height: 54px;
    }

    .review_img_wrap {
        position: relative;
        width: 238px;
        padding-top: 100%;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            min-width: 100%;
            min-height: 100%;
            margin: auto;
            transform: translate(-50%, -50%);
            *top: 0;
            *right: 0;
            *bottom: 0;
            *left: 0;
            *min-height: 238px;
        }
    }

    .review_type_video {
        .review_img_wrap:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('/img/mini/home/opacity30.png') repeat;
            content: '';
        }

        .review_type {
            top: 88px;
        }
    }

    .order_product_box {
        padding: 9px 8px 4px;
        border: 1px solid #f0f0f0;
        border-top: 0;
        background: #f7f7f7;
    }

    .store_review_area {
        padding-top: 10px;

        .review_option {
            top: 10px;
            max-width: 120px;
        }
    }

    .btn_view_prev,
    .btn_view_next {
        z-index: 11;
    }
}

@media \0screen {
    .store_best_review_main .review_img_wrap img {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.store_best_review_list {
    position: relative;
    margin: 16px 0;
    font-size: 0;
}

.best_review_cont {
    display: inline-block;
    *display: inline;
    zoom: 1;
    background: #fff;
    vertical-align: top;
    @include box-border();

    .review_type {
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 1;
    }

    .store_review_content:hover {
        .hover_view {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
        }

        .review_txt,
        .review_box .more_review {
            text-decoration: underline;
        }
    }
}

.review_type_default,
.review_type_experience .review_type,
.review_type_evaluate .review_type {
    padding: 0 8px;
    border: 1px solid #fff;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    font-weight: normal;
    color: #fff;
    line-height: 18px;
}

.store_review_content {
    display: inline-block;
    position: relative;
    width: 316px;
    vertical-align: top;

    .review_link {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        background: url('/img/mini/home/bg_transparent.png') repeat 0 0; //IE9 텍스트에 링크되지 않는 문제 해결
        color: transparent;
        font-size: 1px;
        line-height: 400px;
    }
}

.review_img_wrap {
    display: block;
    overflow: hidden;
    position: relative;
    width: 316px;
    max-height: 316px;
    text-align: center;

    img {
        width: 100%;
        vertical-align: top;
    }

    .icon_multi {
        position: absolute;
        right: 8px;
        bottom: 8px;
        width: 36px;
        height: 36px;
        background: url('/img/mini/home/icon_photo.png') no-repeat;
    }

    .hover_view {
        display: none;

        .hover_txt {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            margin-top: -33px;
            font-size: 16px;
            font-weight: normal;
            color: #fff;
            text-align: center;
        }
    }

    .in_bg {
        display: block;
        width: 100%;
        height: 100%;
        background: #000;
        text-align: center;
        opacity: .3;
        filter: alpha(opacity=30);
    }

    .hover_view .icon_detail {
        display: block;
        width: 38px;
        height: 38px;
        margin: 0 auto 10px;
        background: url('/img/mini/home/icon_detail.png') no-repeat;
    }

    /* MPSR-51108 삭제 */
    &:hover .hover_view {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
    }
    /* // MPSR-51108 삭제 */
}

.store_review_area {
    position: relative;
    padding: 16px 0 9px;
    white-space: normal;

    .review_box {
        position: relative;
    }

    .more_review {
        display: block;
        font-size: 12px;
        font-weight: normal;
        color: #fd7985;
        text-decoration: underline;
        text-align: right;
    }

    /* MPSR-51108 삭제 */
    a .review_txt:hover,
    .review_box:hover .more_review {
        text-decoration: underline;
    }
    /* // MPSR-51108 삭제 */

    .review_txt {
        @include text-ellipsis-multiple(10);
        overflow: hidden;
        max-height: 182px;
        padding: 0 16px;
        font-size: 14px;
        color: #333;
        line-height: 18px;
    }

    .star_box {
        margin: 0 16px 7px;
    }

    .review_option {
        overflow: hidden;
        position: absolute;
        top: 16px;
        right: 16px;
        max-width: 190px;
        font-size: 12px;
        color: #a3a3a3;
        line-height: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .reviewer {
        display: block;
        margin-top: 9px;
        padding: 0 16px;
        font-size: 12px;
        color: #999;
    }
}

.review_btnbx {
    position: absolute;
    right: 16px;
    bottom: 10px;
    z-index: 11;

    button {
        font-size: 12px;
        color: #333;
    }

    .ico_review {
        display: inline-block;
        overflow: hidden;
        width: 11px;
        height: 13px;
        margin: -1px 4px 1px 0;
        color: transparent;
        font-size: 1px;
        line-height: 200px;
        vertical-align: middle;
    }

    .btn_good .ico_review {
        background: url('/img/mini/home/icon_like.png') no-repeat;
    }

    .btn_bad .ico_review {
        background: url('/img/mini/home/icon_bad.png') no-repeat;
    }

    .bar {
        display: inline-block;
        width: 1px;
        height: 10px;
        margin: 0 8px;
        background: #ccc;
        vertical-align: middle;
    }
}

.order_product_box {
    overflow: hidden;
    position: relative;
    display: block;
    border-top: 1px solid #f0f0f0;
    padding: 12px 50px 6px 8px;

    .order_img_wrap,    // MPSR-61501 오픈 후 삭제
    .order_img {
        float: left;
        overflow: hidden;
        position: relative;
        width: 36px;
        height: 36px;
        margin-right: 10px;
        border: 1px solid #e4e4e4;
        border-radius: 50%;

        img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }

    .order_product_info {
        display: block;
        overflow: hidden;
        margin: 1px 0 -1px;
        color: #333;
    }

    .product_name {
        display: block;
        overflow: hidden;
        max-width: 100%;
        font-size: 12px;
        font-weight: normal;
        white-space: nowrap;
        word-break: break-all;
        text-overflow: ellipsis;
    }

    .new_price {
        display: block;
        line-height: 16px;

        em {
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            vertical-align: top;
        }

        .price_unit {
            display: inline-block;
            font-size: 12px;
            vertical-align: top;
        }
    }

    .btn_zzim {
        position: absolute;
        top: 50%;
        right: 0;
        width: 50px;
        height: 48px;
        margin-top: -24px;

        .ico_zzim {
            display: inline-block;
            overflow: hidden;
            width: 18px;
            height: 16px;
            background: url('/img/mini/home/sp_zzim.png') no-repeat;
            line-height: 200px;
            vertical-align: middle;
        }

        &.on .ico_zzim {
            background-position: -22px 0;
        }
    }

    .product_link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('/img/mini/home/bg_transparent.png') repeat 0 0; //IE9 텍스트에 링크되지 않는 문제 해결
    }
}

.review_type_video .review_type {
    overflow: hidden;
    top: 123px;
    left: 50%;
    width: 64px;
    height: 64px;
    margin-left: -32px;
    line-height: 200px;
    background: url('/img/mini/home/icon_play.png');
}

.review_type_adult {
    .review_img_wrap {
        position: relative;
        padding-top: 100%;

        img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            transform: none;
        }
    }

    .star_box .star_point {
        width: 0 !important;
    }

    .review_option,
    .review_btnbx {
        display: none;
    }

    .review_box {
        position: relative;
        display: table;
        width: 100%;
        min-height: 54px;
    }

    /* MPSR-61501 오픈 후 삭제 */
    .certify_step {
        height: 64px;
        text-align: center;

        &:before {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            content: '';
        }

        .txt_adult,
        .txt_adult2 {
            display: inline-block;
            font-size: 14px;
            color: #333;
            line-height: 18px;
        }
    }

    .order_img_wrap {
        width: 38px;
        height: 38px;
        border: 0;
        background: url('/img/mini/home/icon_adult_38.png') no-repeat;
    }
    /* // MPSR-61501 오픈 후 삭제 */

    .text_info {
        display: table-cell;
        padding: 0 8px;
        text-align: center;
        vertical-align: middle;
        color: #111;
        font-size: 13px;

        .text_link {
            color: $color-11st-blue;
            text-decoration: underline;
        }
    }
}

.store_review_checkbox {
    position: absolute;
    top: 11px;
    right: 0;
    font-size: 12px;
    color: #666;
}

.store_review_wrap {
    position: relative;
    margin-top: 30px;

    .best_review_cont {
        position: absolute;
        width: 316px;
        margin-bottom: 16px;

        &:first-child {
            top: 0;
            left: 0;
        }

        &:nth-child(2) {
            top: 0;
            left: 332px;
        }

        &:nth-child(3) {
            top: 0;
            left: 664px;
        }
    }

    .review_type_video .review_type {
        top: 272px;
        right: 8px;
        left: auto;
        width: 36px;
        height: 36px;
        margin-left: 0;
        background-position: 0 -68px;
    }
}

/* MPSR-51108 삭제 */
.store_review_header {
    position: relative;
    min-height: 20px;
    padding: 9px 0 14px;
    border-bottom: 2px solid #111;

    .store_product_heading {
        padding: 0;
        border: 0;
    }
}
/* // MPSR-51108 삭제 */

.ly_wrap {
    .order_product_box {
        display: inline-block;
        max-width: 94%;
        padding: 0;
        border-top: 0;

        .order_img_wrap {
            float: left;
            overflow: hidden;
            position: relative;
            width: 50px;
            height: 50px;
            margin-right: 10px;
            border: 1px solid #e4e4e4;
            border-radius: 50%;
        }

        .order_product_info {
            margin: 2px 0 -2px;
        }

        .product_name {
            font-size: 14px;
        }

        .new_price {
            margin-top: 8px;
            line-height: 18px;

            em {
                font-size: 16px;
            }
        }

        .price_unit {
            margin: -1px 0 1px;
            font-size: 14px;
        }
    }

    .review_simple_info {
        position: relative;
        margin-top: 12px;

        .star_box {
            display: inline-block;
            margin: -1px 0 1px;
            vertical-align: top;
        }

        .reviewer {
            display: inline-block;
            position: relative;
            margin-left: 17px;
            font-size: 12px;
            color: #999;
            vertical-align: top;

            &:before {
                position: absolute;
                top: 50%;
                left: -9px;
                width: 1px;
                height: 13px;
                margin-top: -8px;
                background: #ccc;
                content: '';
            }
        }

        .review_option {
            margin-top: 6px;
            font-size: 12px;
            color: #999;

            .option {
                display: inline-block;
                width: 25px;
                margin: 1px 2px -1px 0;
                border: 1px solid #e2e2e2;
                background: #fff;
                font-size: 10px;
                line-height: 16px;
                text-align: center;
            }
        }
    }

    /* MPSR-61501 오픈 후 삭제 */
    .btn_lk {
        position: relative;
        z-index: 2;
        font-weight: bold;
        color: #5879bf;
        text-decoration: underline;

        &:hover,
        &:active {
            text-decoration: underline;
        }
    }
    /* // MPSR-61501 오픈 후 삭제 */

    .review_btnbx {
        top: 0;
        right: 0;
        bottom: auto;
    }

    .review_box {
        text-align: center;

        img {
            max-width: 100%;
            margin: 7px 0;

            &:first-child {
                margin-top: 0;
            }
        }

        .review_txt,
        p {
            margin: 7px 0;
            font-size: 14px;
            color: #333;
            line-height: 18px;
            text-align: left;
        }

        .review_txt:first-child,
        p:first-child {
            margin-top: 0;
        }
    }

    .btn_zzim,
    .btn_detail {
        display: inline-block;
        width: 130px;
        margin: 0 2px;
        font-size: 14px;
        line-height: 38px;
        vertical-align: top;
        text-align: center;
    }

    .btn_zzim {
        @include button-style($color-11st-red, #fff, $color-11st-red);

        .ico_zzim {
            display: inline-block;
            overflow: hidden;
            width: 15px;
            height: 14px;
            margin-right: 5px;
            background: url('/img/mini/home/sp_zzim.png') no-repeat 0 -20px;
            line-height: 200px;
            vertical-align: middle;
        }

        &.on {
            @include button-style($color-11st-red, $color-11st-red, #fff);

            .ico_zzim {
                background-position: -19px -20px;
            }
        }
    }

    .btn_detail {
        @include button-style(#333, #333, #fff);
    }

    .review_video_area {
        display: inline-block;
        position: relative;
        max-width: 100%;
        margin: 7px 0;

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: #000;
            opacity: .3;
            filter: alpha(opacity=30);
            content: '';
        }

        video {
            display: block;
            max-width: 100%;
            max-height: 432px;
        }

        .btn_video {
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 5;
            width: 64px;
            height: 64px;
            margin: -32px 0 0 -32px;
            background: url('/img/mini/home/icon_play.png');
            line-height: 200px;
        }

        &.review_play_video {

            &:before,
            .btn_video {
                display: none;
            }
        }
    }
}
