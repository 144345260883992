@charset "UTF-8";
@font-face { font-family: "Helvetica Neue"; src: local("Helvetica Neue"); unicode-range: U+0020; }

@font-face { font-family: "Arial"; src: local("Arial"); unicode-range: U+0020; }

.c-n-flag { overflow: hidden; float: left; align-items: center; height: 18px; padding: 0 6px; margin-right: 4px; color: #333; font-size: 12px; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-weight: 700; line-height: 18px; background-color: #f5f6f8; white-space: nowrap; text-align: center; vertical-align: top; }

.c-n-flag--fill { color: #fff; padding: 0 7px; font-weight: 700; font-size: 12px; background-color: #0f0fd9; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-flag { position: relative; display: inline-block; vertical-align: middle; overflow: hidden; zoom: 1; height: 22px; }

.c-flag:after { display: block; content: ""; clear: both; }

.c-flag.c-flag--full { display: block; overflow: visible; }

.c-flag dt { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0; font-size: 6px; line-height: 1; white-space: nowrap; }

.c-flag dd { float: left; background-color: #fff; }

.c-flag dd { margin-right: 2px; }

.c-flag dd:last-of-type { margin-right: 0; }

.c-flag.c-flag--full { height: 24px; }

.c-flag.c-flag--full dd { margin: 0 2px 2px 0; }

.c-flag.c-flag--full dd:last-of-type { margin-right: 0; }

.c-flag .c-flag__item { position: relative; display: block; height: 22px; padding: 2px 4px 1px; border: 0; font-size: 12px; line-height: 1.4; word-break: break-all; box-sizing: border-box; overflow: hidden; background-color: #fff; }

.c-flag .c-flag__item::after { position: absolute; top: 0; bottom: 0; left: 0; right: 0; width: 100%; height: 100%; content: ""; }

.c-flag .c-flag__item { font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-flag .c-flag__item.c-flag__item--ff0038 { border: 1px solid transparent; border-color: rgba(255, 0, 56, 0.4); color: #ff0038; }

.c-flag .c-flag__item.c-flag__item--ff0038::after { background: rgba(255, 0, 56, 0.04); }

.c-flag .c-flag__item.c-flag__time--ff0038 { color: #ff0038; }

.c-flag .c-flag__item.c-flag__item--f43142 { border: 1px solid transparent; border-color: rgba(244, 49, 66, 0.4); color: #f43142; }

.c-flag .c-flag__item.c-flag__item--f43142::after { background: rgba(244, 49, 66, 0.04); }

.c-flag .c-flag__item.c-flag__time--f43142 { color: #f43142; }

.c-flag .c-flag__item.c-flag__item--ff334c { border: 1px solid transparent; border-color: rgba(255, 51, 76, 0.4); color: #ff334c; }

.c-flag .c-flag__item.c-flag__item--ff334c::after { background: rgba(255, 51, 76, 0.04); }

.c-flag .c-flag__item.c-flag__time--ff334c { color: #ff334c; }

.c-flag .c-flag__item.c-flag__item--f01b70 { border: 1px solid transparent; border-color: rgba(240, 27, 112, 0.4); color: #f01b70; }

.c-flag .c-flag__item.c-flag__item--f01b70::after { background: rgba(240, 27, 112, 0.04); }

.c-flag .c-flag__item.c-flag__time--f01b70 { color: #f01b70; }

.c-flag .c-flag__item.c-flag__item--f53fc6 { border: 1px solid transparent; border-color: rgba(245, 63, 198, 0.4); color: #f53fc6; }

.c-flag .c-flag__item.c-flag__item--f53fc6::after { background: rgba(245, 63, 198, 0.04); }

.c-flag .c-flag__item.c-flag__time--f53fc6 { color: #f53fc6; }

.c-flag .c-flag__item.c-flag__item--6423ff { border: 1px solid transparent; border-color: rgba(100, 35, 255, 0.4); color: #6423ff; }

.c-flag .c-flag__item.c-flag__item--6423ff::after { background: rgba(100, 35, 255, 0.04); }

.c-flag .c-flag__item.c-flag__time--6423ff { color: #6423ff; }

.c-flag .c-flag__item.c-flag__item--0b83e6 { border: 1px solid transparent; border-color: rgba(11, 131, 230, 0.4); color: #0b83e6; }

.c-flag .c-flag__item.c-flag__item--0b83e6::after { background: rgba(11, 131, 230, 0.04); }

.c-flag .c-flag__item.c-flag__time--0b83e6 { color: #0b83e6; }

.c-flag .c-flag__item.c-flag__item--00adf2 { border: 1px solid transparent; border-color: rgba(0, 173, 242, 0.4); color: #00adf2; }

.c-flag .c-flag__item.c-flag__item--00adf2::after { background: rgba(0, 173, 242, 0.04); }

.c-flag .c-flag__item.c-flag__time--00adf2 { color: #00adf2; }

.c-flag .c-flag__item.c-flag__item--249356 { border: 1px solid transparent; border-color: rgba(36, 147, 86, 0.4); color: #249356; }

.c-flag .c-flag__item.c-flag__item--249356::after { background: rgba(36, 147, 86, 0.04); }

.c-flag .c-flag__item.c-flag__time--249356 { color: #249356; }

.c-flag .c-flag__item.c-flag__item--ff8100 { border: 1px solid transparent; border-color: rgba(255, 129, 0, 0.4); color: #ff8100; }

.c-flag .c-flag__item.c-flag__item--ff8100::after { background: rgba(255, 129, 0, 0.04); }

.c-flag .c-flag__item.c-flag__time--ff8100 { color: #ff8100; }

.c-flag .c-flag__item.c-flag__item--b9830b { border: 1px solid transparent; border-color: rgba(185, 131, 11, 0.4); color: #b9830b; }

.c-flag .c-flag__item.c-flag__item--b9830b::after { background: rgba(185, 131, 11, 0.04); }

.c-flag .c-flag__item.c-flag__time--b9830b { color: #b9830b; }

.c-flag .c-flag__item.c-flag__item--687581 { border: 1px solid transparent; border-color: rgba(104, 117, 129, 0.4); color: #687581; }

.c-flag .c-flag__item.c-flag__item--687581::after { background: rgba(104, 117, 129, 0.04); }

.c-flag .c-flag__item.c-flag__time--687581 { color: #687581; }

.c-flag .c-flag__item.c-flag__item--426bd1 { border: 1px solid transparent; border-color: rgba(66, 107, 209, 0.4); color: #426bd1; }

.c-flag .c-flag__item.c-flag__item--426bd1::after { background: rgba(66, 107, 209, 0.04); }

.c-flag .c-flag__item.c-flag__time--426bd1 { color: #426bd1; }

.c-flag .c-flag__item.c-flag__item--0097a5 { border: 1px solid transparent; border-color: rgba(0, 151, 165, 0.4); color: #0097a5; }

.c-flag .c-flag__item.c-flag__item--0097a5::after { background: rgba(0, 151, 165, 0.04); }

.c-flag .c-flag__item.c-flag__time--0097a5 { color: #0097a5; }

.c-flag .c-flag__item.c-flag__item--00ae6f { border: 1px solid transparent; border-color: rgba(0, 174, 111, 0.4); color: #00ae6f; }

.c-flag .c-flag__item.c-flag__item--00ae6f::after { background: rgba(0, 174, 111, 0.04); }

.c-flag .c-flag__item.c-flag__time--00ae6f { color: #00ae6f; }

.c-flag .c-flag__item.c-flag__item--3617ce { border: 1px solid transparent; border-color: rgba(54, 23, 206, 0.4); color: #3617ce; }

.c-flag .c-flag__item.c-flag__item--3617ce::after { background: rgba(54, 23, 206, 0.04); }

.c-flag .c-flag__item.c-flag__time--3617ce { color: #3617ce; }

.c-flag .c-flag__item.c-flag__item--000000 { border: 1px solid transparent; border-color: rgba(0, 0, 0, 0.4); color: #000000; }

.c-flag .c-flag__item.c-flag__item--000000::after { background: rgba(0, 0, 0, 0.04); }

.c-flag .c-flag__item.c-flag__time--000000 { color: #000000; }

.c-flag .c-flag__item.c-flag__item--0f0fd9 { border: 1px solid transparent; border-color: rgba(15, 15, 217, 0.4); color: #0f0fd9; }

.c-flag .c-flag__item.c-flag__item--0f0fd9::after { background: rgba(15, 15, 217, 0.04); }

.c-flag .c-flag__item.c-flag__time--0f0fd9 { color: #0f0fd9; }

.c-flag .c-flag__item.c-flag__item--5274ff { border: 1px solid transparent; border-color: rgba(82, 116, 255, 0.4); color: #5274ff; }

.c-flag .c-flag__item.c-flag__item--5274ff::after { background: rgba(82, 116, 255, 0.04); }

.c-flag .c-flag__item.c-flag__time--5274ff { color: #5274ff; }

.c-flag .c-flag__item.c-flag__item--ff7700 { border: 1px solid transparent; border-color: rgba(255, 119, 0, 0.4); color: #ff7700; }

.c-flag .c-flag__item.c-flag__item--ff7700::after { background: rgba(255, 119, 0, 0.04); }

.c-flag .c-flag__item.c-flag__time--ff7700 { color: #ff7700; }

.c-flag .c-flag__item > strong { line-height: 1.45; }

.c-flag .c-flag__time { margin-top: 1px; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; line-height: 21px; }

.c-flag .c-flag__time.c-flag__item--ff0038 { border: 1px solid transparent; border-color: rgba(255, 0, 56, 0.4); color: #ff0038; }

.c-flag .c-flag__time.c-flag__item--ff0038::after { background: rgba(255, 0, 56, 0.04); }

.c-flag .c-flag__time.c-flag__time--ff0038 { color: #ff0038; }

.c-flag .c-flag__time.c-flag__item--f43142 { border: 1px solid transparent; border-color: rgba(244, 49, 66, 0.4); color: #f43142; }

.c-flag .c-flag__time.c-flag__item--f43142::after { background: rgba(244, 49, 66, 0.04); }

.c-flag .c-flag__time.c-flag__time--f43142 { color: #f43142; }

.c-flag .c-flag__time.c-flag__item--ff334c { border: 1px solid transparent; border-color: rgba(255, 51, 76, 0.4); color: #ff334c; }

.c-flag .c-flag__time.c-flag__item--ff334c::after { background: rgba(255, 51, 76, 0.04); }

.c-flag .c-flag__time.c-flag__time--ff334c { color: #ff334c; }

.c-flag .c-flag__time.c-flag__item--f01b70 { border: 1px solid transparent; border-color: rgba(240, 27, 112, 0.4); color: #f01b70; }

.c-flag .c-flag__time.c-flag__item--f01b70::after { background: rgba(240, 27, 112, 0.04); }

.c-flag .c-flag__time.c-flag__time--f01b70 { color: #f01b70; }

.c-flag .c-flag__time.c-flag__item--f53fc6 { border: 1px solid transparent; border-color: rgba(245, 63, 198, 0.4); color: #f53fc6; }

.c-flag .c-flag__time.c-flag__item--f53fc6::after { background: rgba(245, 63, 198, 0.04); }

.c-flag .c-flag__time.c-flag__time--f53fc6 { color: #f53fc6; }

.c-flag .c-flag__time.c-flag__item--6423ff { border: 1px solid transparent; border-color: rgba(100, 35, 255, 0.4); color: #6423ff; }

.c-flag .c-flag__time.c-flag__item--6423ff::after { background: rgba(100, 35, 255, 0.04); }

.c-flag .c-flag__time.c-flag__time--6423ff { color: #6423ff; }

.c-flag .c-flag__time.c-flag__item--0b83e6 { border: 1px solid transparent; border-color: rgba(11, 131, 230, 0.4); color: #0b83e6; }

.c-flag .c-flag__time.c-flag__item--0b83e6::after { background: rgba(11, 131, 230, 0.04); }

.c-flag .c-flag__time.c-flag__time--0b83e6 { color: #0b83e6; }

.c-flag .c-flag__time.c-flag__item--00adf2 { border: 1px solid transparent; border-color: rgba(0, 173, 242, 0.4); color: #00adf2; }

.c-flag .c-flag__time.c-flag__item--00adf2::after { background: rgba(0, 173, 242, 0.04); }

.c-flag .c-flag__time.c-flag__time--00adf2 { color: #00adf2; }

.c-flag .c-flag__time.c-flag__item--249356 { border: 1px solid transparent; border-color: rgba(36, 147, 86, 0.4); color: #249356; }

.c-flag .c-flag__time.c-flag__item--249356::after { background: rgba(36, 147, 86, 0.04); }

.c-flag .c-flag__time.c-flag__time--249356 { color: #249356; }

.c-flag .c-flag__time.c-flag__item--ff8100 { border: 1px solid transparent; border-color: rgba(255, 129, 0, 0.4); color: #ff8100; }

.c-flag .c-flag__time.c-flag__item--ff8100::after { background: rgba(255, 129, 0, 0.04); }

.c-flag .c-flag__time.c-flag__time--ff8100 { color: #ff8100; }

.c-flag .c-flag__time.c-flag__item--b9830b { border: 1px solid transparent; border-color: rgba(185, 131, 11, 0.4); color: #b9830b; }

.c-flag .c-flag__time.c-flag__item--b9830b::after { background: rgba(185, 131, 11, 0.04); }

.c-flag .c-flag__time.c-flag__time--b9830b { color: #b9830b; }

.c-flag .c-flag__time.c-flag__item--687581 { border: 1px solid transparent; border-color: rgba(104, 117, 129, 0.4); color: #687581; }

.c-flag .c-flag__time.c-flag__item--687581::after { background: rgba(104, 117, 129, 0.04); }

.c-flag .c-flag__time.c-flag__time--687581 { color: #687581; }

.c-flag .c-flag__time.c-flag__item--426bd1 { border: 1px solid transparent; border-color: rgba(66, 107, 209, 0.4); color: #426bd1; }

.c-flag .c-flag__time.c-flag__item--426bd1::after { background: rgba(66, 107, 209, 0.04); }

.c-flag .c-flag__time.c-flag__time--426bd1 { color: #426bd1; }

.c-flag .c-flag__time.c-flag__item--0097a5 { border: 1px solid transparent; border-color: rgba(0, 151, 165, 0.4); color: #0097a5; }

.c-flag .c-flag__time.c-flag__item--0097a5::after { background: rgba(0, 151, 165, 0.04); }

.c-flag .c-flag__time.c-flag__time--0097a5 { color: #0097a5; }

.c-flag .c-flag__time.c-flag__item--00ae6f { border: 1px solid transparent; border-color: rgba(0, 174, 111, 0.4); color: #00ae6f; }

.c-flag .c-flag__time.c-flag__item--00ae6f::after { background: rgba(0, 174, 111, 0.04); }

.c-flag .c-flag__time.c-flag__time--00ae6f { color: #00ae6f; }

.c-flag .c-flag__time.c-flag__item--3617ce { border: 1px solid transparent; border-color: rgba(54, 23, 206, 0.4); color: #3617ce; }

.c-flag .c-flag__time.c-flag__item--3617ce::after { background: rgba(54, 23, 206, 0.04); }

.c-flag .c-flag__time.c-flag__time--3617ce { color: #3617ce; }

.c-flag .c-flag__time.c-flag__item--000000 { border: 1px solid transparent; border-color: rgba(0, 0, 0, 0.4); color: #000000; }

.c-flag .c-flag__time.c-flag__item--000000::after { background: rgba(0, 0, 0, 0.04); }

.c-flag .c-flag__time.c-flag__time--000000 { color: #000000; }

.c-flag .c-flag__time.c-flag__item--0f0fd9 { border: 1px solid transparent; border-color: rgba(15, 15, 217, 0.4); color: #0f0fd9; }

.c-flag .c-flag__time.c-flag__item--0f0fd9::after { background: rgba(15, 15, 217, 0.04); }

.c-flag .c-flag__time.c-flag__time--0f0fd9 { color: #0f0fd9; }

.c-flag .c-flag__time.c-flag__item--5274ff { border: 1px solid transparent; border-color: rgba(82, 116, 255, 0.4); color: #5274ff; }

.c-flag .c-flag__time.c-flag__item--5274ff::after { background: rgba(82, 116, 255, 0.04); }

.c-flag .c-flag__time.c-flag__time--5274ff { color: #5274ff; }

.c-flag .c-flag__time.c-flag__item--ff7700 { border: 1px solid transparent; border-color: rgba(255, 119, 0, 0.4); color: #ff7700; }

.c-flag .c-flag__time.c-flag__item--ff7700::after { background: rgba(255, 119, 0, 0.04); }

.c-flag .c-flag__time.c-flag__time--ff7700 { color: #ff7700; }

.c-flag .c-flag__item--11festa { color: #fff; border: 1px solid transparent; border-color: #f44654; background-color: #f44654; }

.c-flag .c-flag__item--choice { color: #fff; border: 1px solid transparent; border-color: #232f3e; background: #232f3e; }

.c-flag .c-flag__item--gradient-red { color: #fff; border: 0; background: linear-gradient(114.84deg, #ff465d 31.64%, #ff00ef 100%); line-height: 16px; }

.c-flag .c-flag__item--ooah { position: relative; width: 37px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--ooah::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 37px; height: 12.33333px; background-position: -203.01266px -171.28205px; background-size: 405px 400px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--freshtable { position: relative; width: 51px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--freshtable::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 51px; height: 11.76923px; background-position: -325.27595px -97.94872px; background-size: 387px 382px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--shockingdeal { position: relative; width: 44px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--shockingdeal::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 44px; height: 18px; background-position: -186px -215px; background-size: 395px 390px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--timedeal { position: relative; width: 57px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--timedeal::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 57px; height: 14px; background-position: -332px -57px; background-size: 395px 390px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--ootd { position: relative; width: 46px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--ootd::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 47px; height: 13px; background-position: -244px -222px; background-size: 395px 390px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--mart-plus { position: relative; width: 60px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--mart-plus::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 60px; height: 12px; background-position: -182.84557px -2.99487px; background-size: 296px 292px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--ssg-emart { position: relative; width: 66px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--ssg-emart::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 66px; height: 20px; background-position: -244px -154px; background-size: 395px 390px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag .c-flag__item--ssg-dawn { position: relative; width: 66px; padding: 0; border: none; background-color: transparent; line-height: 200px; }

.c-flag .c-flag__item--ssg-dawn::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 66px; height: 20px; background-position: -244px -126px; background-size: 395px 390px; position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: auto; content: ""; }

.c-flag-box { overflow: hidden; max-height: 49px; zoom: 1; }

.c-flag-box:after { display: block; content: ""; clear: both; }

.c-flag-box dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-flag-box dd { display: inline; }

.c-flag-box .c-time { margin: 0 4px 4px 0; }

.c-flag-box .c-flag { overflow: initial; height: auto; }

.c-flag-box .c-flag .c-flag__item { float: left; margin: 0 4px 4px 0; }

.c-flag-box .c-flag .c-flag__item:last-child { margin-right: 0; }

.c-flag-box .c-flag .c-flag__time { float: left; margin: 1px 4px 4px 1px; }

.c-flag-box .c-flag .c-flag__time:last-child { margin-right: 0; }

.store_wrap .c-flag-box { max-height: 44px; margin-bottom: 2px; }

.store_wrap .c-flag-box--luxury { margin-top: 6px; margin-bottom: 0; }

.store_wrap .c-flag-box .c-flag__item { height: 18px; padding: 0 5px; font-size: 11px; }

.store_wrap .c-flag-box .c-flag__item > strong { line-height: 1.5; }

.store_wrap .c-flag-box .c-flag__time { margin-top: 0; line-height: 18px; }

.store_wrap .c-flag-box .c-flag__time em { font-weight: 700; }

.store_wrap .c-flag-box .c-flag__time--gradient-purple { height: 18px; padding: 0 6px 0 3px; margin-top: 0; color: #fff; border: 0; background: linear-gradient(90deg, rgba(120, 14, 255, 0.85) 0%, rgba(44, 89, 255, 0.85) 100%); line-height: 18px; }

.store_wrap .c-flag-box .c-flag__time--gradient-purple::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 14px; height: 13.06667px; background-position: -122.04557px -226.8px; background-size: 368px 364px; display: inline-block; margin: -1px 2px 0 0; vertical-align: middle; content: ""; }

.store_wrap .c-flag-box .c-flag__time--gradient-purple [role="timer"] { font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 11px; font-weight: 700; }

.store_wrap .c-flag-box .c-flag__item--gradient-red strong { line-height: 18px; }

.store_wrap .c-flag-box .c-time-flag .c-time { height: 18px; padding: 0 5px; text-align: center; line-height: 16px; }

.store_wrap .c-flag-box .c-time-flag .c-time::before { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 14px; height: 13.06667px; background-position: -122.04557px -226.8px; background-size: 368px 364px; display: inline-block; margin: 0 2px 0 0; vertical-align: middle; content: ""; }

.store_wrap .c-flag-box .c-time-flag .c-time [role="timer"] { font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 11px; font-weight: 700; }

.store_wrap, .store_wrap input[type="text"], .store_wrap button { font-size: 12px; font-family: "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; line-height: 18px; }

.store_wrap button { box-sizing: border-box; }

.store_wrap, .store_wrap a { color: #333; }

.store_wrap a, .store_wrap a:focus, .store_wrap a:active, .store_wrap a:hover { text-decoration: none; }

.fs12, .store_chk_wrap label, .layer_frequenter .freq_store, .layer_frequenter .freq_txt, .layer_frequenter .inp_wrap label { font-size: 12px; line-height: 16px; }

.fs14, .view_more_bar, .store_chk_wrap.type2 label, .my_store_list_layer h3, .my_store_nothing .p2, .store_share_layer h3, .store_share_layer .copy_url input, .store_share_layer .copy_url button, .layer_frequenter .freq_txt2, .store_lnb_f .store_navigation, .store_lnb_f .f_heading, .store_promotion figcaption .store_promotion_subject, .store_content .store_filter, .store_product_name, .delivery_header .delivery_explain, .selected_product_title, .bundle_product_title, .bundle_product_name, .store_instagram .store_instagram_author, .store_instagram_popularity dl { font-size: 14px; line-height: 20px; }

.fs16 { font-size: 16px; line-height: 22px; }

/* body{overflow-x: hidden;} */
#wrap { min-width: 1240px; background-color: #e9edf0; }

#layBodyWrap { width: 100%; padding-top: 0; }

.store_container { overflow: hidden; position: relative; width: 1200px; margin: 20px auto 0; }

.store_content_wrap { overflow: hidden; background-color: #fff; zoom: 1; }

.store_content_wrap .store_content { overflow: hidden; position: relative; min-height: 768px; margin-top: -30px; margin-left: 200px; padding-left: 20px; background-color: #e9edf0; }

.store_content_wrap.w1200 .store_content { min-height: auto; margin-left: 0; padding-left: 0; }

.store_module_wrap { position: relative; margin-top: 40px; }

.store_title { padding: 0 60px 18px 0; color: #333; font-weight: bold; font-size: 20px; line-height: 28px; letter-spacing: -1px; }

.store_title > i { margin-left: 6px; color: #666; font-weight: normal; font-size: 14px; letter-spacing: -0.5px; font-style: normal; }

.btn_more { position: absolute; right: 0; top: 9px; padding-right: 12px; color: #666; font-size: 14px; line-height: 18px; letter-spacing: -0.88px; }

.btn_more:before { position: absolute; top: 50%; right: 0; width: 7px; height: 7px; margin: -1px 5px 0 0; border: 1px solid #666; border-width: 1px 1px 0 0; vertical-align: middle; transform: rotate(45deg) translateY(-50%); content: ''; }

.c_sticky { position: relative; }

.c_sticky_fixed_top { position: fixed; top: 0; }

.c_sticky_fixed_bottom { position: fixed; bottom: 0; }

.c_sticky_fixed_top, .c_sticky_fixed_bottom { bottom: 0; }

.c_sticky_fixed_top:before, .c_sticky_fixed_bottom:before { position: absolute; top: 0; bottom: 0; left: -20px; width: 20px; background-color: #e9edf0; content: ''; }

.c_sticky_absolute { position: absolute; bottom: 0; }

.c_sticky_absolute + .c_sticky_dummy { display: block; }

.c_sticky_dummy { display: none; }

.c_sticky_fixed_top + .c_sticky_dummy, .c_sticky_fixed_bottom + .c_sticky_dummy { display: block; }

.wing_banner { top: 141px; margin-left: 610px; padding-top: 10px; }

#layBodyWrap .wing_banner.wing_fixed { top: 0; }

/* my history 1200px */
div.footer_search2, div.my_history2_wrap, div.ftr_banner { width: 1200px; }

.my_history2_wrap .recom_prd h5 { padding-left: 51px; }

.my_history2_wrap .btnctr_pn { padding-right: 51px; }

.store_wrap .btn_view_prev { overflow: hidden; position: absolute; left: 0; top: 50%; width: 60px; height: 60px; margin-top: -30px; background: url(/img/mini/home/icon_swipe_arrow.png) no-repeat -1px -1px; line-height: 200px; }

.store_wrap .btn_view_prev:hover { background-position: -1px -63px; }

.store_wrap .btn_view_next { overflow: hidden; position: absolute; right: 0; top: 50%; width: 60px; height: 60px; margin-top: -30px; background: url("/img/mini/home/icon_swipe_arrow.png") no-repeat -63px -1px; line-height: 200px; }

.store_wrap .btn_view_next:hover { background-position: -63px -63px; }

.store_common_prev { position: absolute; left: 0; top: 50%; width: 60px; height: 60px; margin-top: -30px; background: url(/img/mini/home/icon_swipe_arrow.png) no-repeat -1px -1px; overflow: hidden; line-height: 200px; }

.store_common_prev:hover { background-position: -1px -63px; }

.store_common_next { position: absolute; right: 0; top: 50%; width: 60px; height: 60px; margin-top: -30px; background: url("/img/mini/home/icon_swipe_arrow.png") no-repeat -63px -1px; overflow: hidden; line-height: 200px; }

.store_common_next:hover { background-position: -63px -63px; }

.view_more_bar { display: block; position: relative; margin-top: 20px; padding: 14px 0; border: 1px solid #cdd1d3; background-color: #d6dadd; text-align: center; }

.view_more_bar .icon_more { display: inline-block; padding-right: 24px; background: url("/img/mini/home/icon_view_more_bar.png") no-repeat 100% 0; }

.view_more_bar .icon_go { display: inline-block; padding-right: 24px; background: url("/img/mini/home/icon_view_more_bar.png") no-repeat 100% 100%; }

/* MPSR-51108 삭제 (_product.scss 이동) */
.store_product_heading { padding: 9px 0 14px; border-bottom: 2px solid #111; font-weight: normal; font-size: 15px; }

.store_product_heading a { display: inline-block; width: 100px; margin: -5px 0; padding: 5px 9px 5px; border-radius: 0; border: 1px solid #dadada; background: #fff url("/img/mini/home/icon_select_arrow.png") no-repeat 100% 0; font-size: 12px; line-height: 18px; vertical-align: top; }

.store_product_heading a.on { background-position: 100% 100%; }

.store_product_heading a:focus, .store_product_heading a:active, .store_product_heading a:hover { text-decoration: none; }

.category_list_layer { display: none; position: absolute; left: 0; top: 34px; border: 1px solid #676767; background-color: #fff; z-index: 1; overflow: hidden; }

.category_list_layer .category_list { float: left; width: 118px; margin-right: -1px; padding: 6px 0; border-right: 1px solid #dadada; }

.category_list_layer .category_list + .category_list { margin-left: 1px; }

.category_list_layer .category_item { font-size: 12px; line-height: 22px; }

.category_list_layer .category_link { display: block; height: 22px; padding: 1px 10px 1px 8px; overflow: hidden; }

.category_list_layer .category_link:hover { background-color: #f4f4f4; text-decoration: none; }

.store_search_title { margin-top: 30px; margin-bottom: -30px; padding: 9px 0; font-weight: normal; font-size: 22px; line-height: 32px; }

.store_search_title strong { font-weight: normal; color: #ff0038; }

.store_header_wrap { overflow: hidden; margin: 30px 0 -23px; }

.store_header_wrap .store_content_title { float: left; margin-right: 20px; padding: 4px 0; font-weight: normal; font-size: 22px; line-height: 32px; }

.store_header_wrap .store_content_explain { float: left; padding: 13px 0 7px; font-size: 14px; color: #666; }

.store_product_top { padding: 9px 0 14px; border-bottom: 2px solid #111; zoom: 1; }

.store_product_top:after { display: block; clear: both; content: ''; }

.store_product_top .store_product_heading { float: left; padding: 0; border: 0; }

.store_product_top .store_chk_wrap { float: left; margin-left: 10px; }

/* // MPSR-51108 삭제 (_product.scss 이동) */
/* MPSR-51108 삭제 */
.store_chk_wrap { position: relative; }

.store_chk_wrap input { position: absolute; top: 0; left: 0; width: 16px; height: 16px; border: 0; background: none; opacity: 0; filter: alpha(opacity=0); }

.store_chk_wrap label { display: inline-block; padding-left: 24px; background: url("/img/mini/home/icon_check.png") no-repeat 0 50%; color: #666; line-height: 18px; }

.store_chk_wrap.checked label { background-image: url("/img/mini/home/icon_check_active.png"); }

.store_chk_wrap.type2 label { color: #333; }

.store_chk_wrap.type2.checked label { background-image: url("/img/mini/home/icon_check2_active.png"); }

/* // MPSR-51108 삭제 */
.dropdown_wrap { display: inline-block; position: relative; z-index: 9; width: 142px; height: 32px; background-color: #fff; text-align: left; font-size: 13px; color: #333; line-height: 31px; border: 1px solid #e3e3e8; vertical-align: middle; }

.dropdown_wrap .dropdown_selected { position: relative; line-height: 0; }

.dropdown_wrap .dropdown_selected .selected { position: relative; display: block; width: 100%; padding: 0 25px 0 29px; text-align: left; color: #333; font-weight: bold; font-size: 13px; line-height: 32px; }

.dropdown_wrap .dropdown_selected .selected:hover { text-decoration: underline; }

.dropdown_wrap .dropdown_selected .selected:after { display: block; position: absolute; top: 50%; right: 13px; width: 7px; height: 7px; margin-top: -6px; border: 1px solid #aaa; border-width: 0 1px 1px 0; transform: rotate(45deg); content: ''; }

.dropdown_wrap.active { border-color: #666; }

.dropdown_wrap.active .dropdown_selected .item:after { border-width: 1px 0 0 1px; margin-top: -3px; }

.dropdown_wrap.active .dropdown_list { display: block; }

.dropdown_wrap .dropdown_list { display: none; position: absolute; top: 33px; left: -1px; right: -1px; padding: 7px 0; border: 1px solid #666; border-top: 0; background: #fff; }

.dropdown_wrap .dropdown_list li { position: relative; line-height: 0; }

.dropdown_wrap .dropdown_list .item { width: 100%; padding: 0 0 5px 29px; text-align: left; color: #333; font-size: 13px; line-height: 22px; }

.dropdown_wrap .dropdown_list .item:hover { color: #ff0038; text-decoration: underline; }

.dropdown_wrap .dropdown_list .help_box { margin-top: -15px; }

.dropdown_wrap .help_box { position: absolute; top: 50%; left: 7px; margin-top: -16px; }

.dropdown_wrap .help_box .btn_help .sp_search2 { margin: 0; vertical-align: -2px; }

.ly_wrap { display: none; position: fixed; top: 0; left: 0; z-index: 5000; width: 100%; height: 100%; background: url("/img/mini/home/opacity50.png") repeat; font-family: "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.ly_wrap input[type="text"], .ly_wrap a, .ly_wrap button { font-family: "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.ly_wrap a { text-decoration: none; }

.ly_wrap a:focus, .ly_wrap a:active, .ly_wrap a:hover { text-decoration: none; }

.ly_wrap .ly_inner { position: absolute; left: 50%; width: 644px; background: #fff; margin-left: -322px; }

.ly_wrap .ly_top_area { padding: 20px 30px; background: #fafafa; }

.ly_wrap .ly_cont_area { overflow-y: auto; max-height: calc(100vh - 550px); padding: 20px 20px 20px 30px; }

.ly_wrap .ly_btn_area { padding: 16px 0; text-align: center; }

.ly_wrap .btn_close { position: absolute; top: 11px; right: 18px; width: 40px; height: 40px; }

.ly_wrap .btn_close .ico_close { display: inline-block; overflow: hidden; width: 26px; height: 26px; background: url("/img/mini/home/icon_store_info_layer_x.png") no-repeat; font-size: 0; line-height: 200px; vertical-align: middle; }

.star_box { position: relative; width: 74px; height: 15px; background: url("/img/mini/home/sp_star.png") no-repeat; }

.star_box .star_point { overflow: hidden; position: absolute; top: -1px; left: 0; height: 100%; background: url("/img/mini/home/sp_star.png") no-repeat 0 -18px; font-size: 0; line-height: 200px; }

.store_percent_list { margin-top: 12px; font-size: 12px; color: #6d96c0; }

.store_percent_list li + li { margin-top: 2px; }

.store_percent_list .txt { float: left; width: 60px; color: #111; text-align: center; }

.store_percent_list .bg_box { float: left; position: relative; width: 212px; height: 6px; margin: 6px 0 0; background: #eff1f7; vertical-align: middle; }

.store_percent_list .percent { overflow: hidden; position: absolute; top: 0; left: 0; height: 6px; background: #96aec5; line-height: 200px; }

.store_percent_list .txt_point { display: inline-block; width: 50px; color: #96aec5; text-align: right; }

.store_percent_list .good .percent { background: #0b83e6; }

.store_percent_list .good .txt_point { color: #0b83e6; }

.store_header { border-bottom: 1px solid #ccc; border-color: rgba(0, 0, 0, 0.2); background-color: #fff; }

.store_gnb_wrap { border-bottom: 1px solid #f7f7f7; background-color: #fcfcfc; }

.store_gnb { position: relative; width: 1200px; margin: 0 auto; padding: 11px 0; z-index: 300; zoom: 1; }

.store_gnb:after { display: block; clear: both; content: ""; }

.store_info { display: inline-block; float: left; margin-right: 15px; padding: 1px 27px 1px 0; background: url("/img/mini/home/icon_seller_info_arrow.png") no-repeat 100% 1px; }

.store_info_layer_close { position: absolute; right: 15px; top: 12px; width: 26px; height: 26px; background: url("/img/mini/home/icon_store_info_layer_x.png") no-repeat 50% 50%; }

.ly_store_info { display: none; position: absolute; left: 0; top: 33px; width: 378px; border: 1px solid #333; background-color: #fff; color: #111; }

.store_info_section { padding: 15px 20px 20px; }

.ly_store_info h3 { margin: 0 -20px; padding: 0 50px 13px 20px; border-bottom: 1px solid #e5e5e5; font-size: 14px; line-height: 20px; }

.ly_store_info .sub_tit { margin-top: 21px; font-size: 13px; }

.ly_store_info .store_grade_summary { margin-top: 17px; padding: 19px 0 14px; text-align: center; font-size: 0; border-top: 1px solid #f4f4f4; border-bottom: 1px solid #f4f4f4; }

.ly_store_info .store_grade_summary li { display: inline-block; width: 33%; font-size: 12px; color: #666; }

.ly_store_info .store_grade_summary .num { display: block; margin-bottom: 4px; font-weight: normal; font-size: 20px; line-height: 24px; color: #111; }

.ly_store_info .store_grade_summary .txt_ing { display: block; margin-bottom: 10px; font-size: 20px; line-height: 20px; color: #ccc; }

.ly_store_info .store_grade_summary .bg_box { display: block; position: relative; margin: 0 auto 9px; width: 42px; height: 20px; background: url("/img/common/sp_grade.png") no-repeat 0 0; }

.ly_store_info .store_grade_summary .bg_box .percent { overflow: hidden; position: absolute; top: 0; left: 0; height: 20px; background: url("/img/common/sp_grade.png") no-repeat 0 -24px; line-height: 200px; }

.ly_store_info .store_status { margin: 10px 0 20px; overflow: hidden; }

.ly_store_info .store_status dt { float: left; clear: both; width: 61px; margin: 10px 9px 0 0; border: 1px solid #eee; border-radius: 20px; font-size: 10px; line-height: 18px; text-align: center; }

.ly_store_info .store_status dd { float: left; margin-top: 10px; color: #666; line-height: 20px; }

.ly_store_info .store_status dd .txt_point { color: #0b83e6; }

.ly_store_info .popularity_box { margin-top: 15px; padding: 20px; background-color: #fafafa; font-size: 12px; color: #8d8d8d; line-height: 18px; }

.ly_store_info .popularity_box .dsc { font-size: 11px; line-height: 17px; color: #666; }

.ly_store_info .popularity_box .dsc dt { color: #111; }

.ly_store_info .popularity_box .dsc dd + dt { margin-top: 10px; }

.ly_store_info .popularity_box .dsc dd:before { display: inline-block; width: 2px; height: 2px; margin-right: 6px; background: #666; content: ""; vertical-align: middle; }

.ly_store_info .store_info_detail { overflow: hidden; margin-top: 2px; font-size: 12px; line-height: 18px; }

.ly_store_info .store_info_detail dt { float: left; padding: 12px 8px 0 0; color: #999; }

.ly_store_info .store_info_detail dd { overflow: hidden; min-height: 20px; padding: 12px 0 0; word-break: keep-all; word-wrap: break-word; zoom: 1; }

.store_notice { color: #999; }

.store_notice em { margin-right: 5px; color: #666; }

.my_store_area { position: absolute; right: 0; top: 8px; }

.my_store_area .my_store_setting { display: inline-block; margin-right: 6px; padding: 4px 12px 3px 30px; border-bottom: 1px solid #d83643; background: #fe404f url("/img/mini/home/icon_my_store_setting.png") no-repeat 0 0; color: #fff; }

.my_store { display: inline-block; padding: 3px 30px 3px 10px; border: 1px solid #ccc; background: #fff url("/img/mini/home/icon_my_stores_arrow.png") no-repeat 100% 0; color: #666; }

.my_store.on { background-position: 100% 100%; }

.my_store_list_layer { display: none; position: absolute; right: 0; top: 34px; width: 500px; border: 1px solid #333; background-color: #fff; }

.my_store_layer_close { position: absolute; right: 15px; top: 12px; width: 26px; height: 26px; background: url("/img/mini/home/icon_store_info_layer_x.png") no-repeat 50% 50%; }

.my_store_list_layer h3 { padding: 15px 20px; }

.my_store_list_area { overflow-x: hidden; overflow-y: auto; max-height: 404px; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; }

.my_store_list { overflow: hidden; margin: -1px; padding: 0.5px; background-color: #ebebeb; zoom: 1; }

.my_store_item { float: left; width: 50%; }

.my_store_link { display: block; padding: 0.5px; font-size: 14px; line-height: 20px; }

.my_store_link > span { display: block; height: 20px; padding: 12px 20px; background-color: #fff; white-space: nowrap; }

.my_store_link:focus, .my_store_link:active, .my_store_link:hover { text-decoration: none; outline: none; }

a.my_store_link:focus > span, a.my_store_link:active > span, a.my_store_link:hover > span { background-color: #f4f4f4; outline: 1px solid #ccc; }

.my_store_link .my_store_name { display: inline-block; max-width: 160px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; vertical-align: top; }

.my_store_link em { display: inline-block; padding-top: 2px; font-size: 11px; line-height: 18px; word-spacing: -2px; vertical-align: top; }

.my_store_link em.event { color: #536dfe; }

.my_store_link em.coupon { color: #ff0038; }

.my_store_nothing { padding: 42px 0 45px; border-top: 1px solid #ccc; }

.my_store_nothing .p1 { font-size: 18px; line-height: 28px; color: #333; text-align: center; }

.my_store_nothing .p2 { margin-top: 7px; color: #999; text-align: center; }

.my_store_nothing .p1 span { color: #ff0038; }

.my_store_list_layer .btn_area { padding: 15px 0; text-align: center; }

.my_store_list_layer .btn_my_store { display: inline-block; padding: 9px 35px; background-color: #333; color: #fff; text-align: center; }

.my_store_list_layer .btn_my_store:focus, .my_store_list_layer .btn_my_store:active, .my_store_list_layer .btn_my_store:hover { text-decoration: none; }

.store_name_area { position: relative; width: 1200px; margin: 0 auto; padding: 23px 0 29px; z-index: 200; }

.store_name { display: inline-block; *display: inline; zoom: 1; margin-right: 10px; font-size: 28px; line-height: 36px; color: #111; vertical-align: top; }

.store_emblem { display: inline-block; width: 32px; height: 34px; margin: 3px 10px -3px 0; background: url("/img/mini/home/icon_emblem.png") no-repeat; vertical-align: middle; }

.store_copy_area { display: inline-block; *display: inline; zoom: 1; margin-top: 10px; font-size: 0; vertical-align: top; }

.store_copy_area > span { display: inline-block; height: 18px; padding: 0 7px 0 6px; margin-right: 6px; font-size: 12px; border-width: 1px; border-style: solid; border-radius: 15px; letter-spacing: -1px; vertical-align: middle; }

.store_copy_area .store_type { background: #fe4050; border-color: #fe4050; color: #fff; }

.store_copy_area .store_best { background: #fff; border-color: rgba(244, 49, 66, 0.8); color: #ff0038; }

.store_copy_area .store_road_bg { background: #51d3b0; border-color: #51d3b0; color: #fff; }

.store_copy_area .store_road_txt { background: #fff; border-color: #51d3b0; color: #01c48f; }

.store_copy_area .store_copy { padding: 1px 0; border: 0 none; }

.store_copy_area .store_tag { margin: 0 0 0 2px; padding: 1px 0; border: 0 none; }

.store_copy_area .store_tag:before { content: "#"; }

.store_copy_area .store_tag + .store_tag { margin-left: 4px; }

.store_zzim { position: absolute; right: 65px; top: 26px; height: 18px; padding: 9px 5px 9px 43px; background: url("/img/mini/home/icon_store_zzim.png") no-repeat 0 0; }

.store_zzim.on { background-position: 0 -36px; }

.store_zzim .store_count { font-weight: bold; }

.store_share { position: absolute; right: 13px; top: 26px; width: 36px; height: 36px; background: url("/img/mini/home/icon_store_share.png") no-repeat 0 0; }

.store_share_layer { display: none; position: absolute; right: 0; top: 68px; width: 338px; border: 1px solid #333; background-color: #fff; }

.store_share_layer_close { position: absolute; right: 15px; top: 12px; width: 26px; height: 26px; background: url("/img/mini/home/icon_store_info_layer_x.png") no-repeat 50% 50%; }

.store_share_layer h3 { padding: 15px 20px; }

.store_share_layer .sns_list_area { padding: 20px 20px 3px; border-top: 1px solid #ccc; }

.store_share_layer .sns_list { text-align: center; white-space: nowrap; }

.store_share_layer .sns_item { display: inline-block; width: 66px; text-align: center; vertical-align: top; }

.store_share_layer .sns_icon { display: block; width: 50px; height: 50px; margin: 0 auto 3px; border-radius: 25px; background: url("/img/mini/home/icon_sns.png") no-repeat -100px 0; }

.store_share_layer .sns_icon.facebook { background-position: -1px -1px; }

.store_share_layer .sns_icon.twitter { background-position: -1px -53px; }

.store_share_layer .sns_icon.kakaostory { background-position: -1px -105px; }

.store_share_layer .sns_icon--x { background-position: -1px -157px; }

.store_share_layer .copy_url { padding: 20px; }

.store_share_layer .copy_url input { width: 217px; height: 20px; padding: 9px 0; border: solid #ccc; border-width: 1px 0 1px 1px; color: #999; text-indent: 8px; }

.store_share_layer .copy_url button { width: 80px; height: 40px; padding: 10px 0; border: 0; background-color: #333; color: #fff; text-align: center; }

.layer_frequenter { position: absolute; top: 75px; right: 0; width: 211px; padding: 38px 22px 16px; border: 1px solid #4c4c4c; background: #fff; letter-spacing: -1px; text-align: center; }

.layer_frequenter .freq_store { display: block; max-width: 135px; margin: 0 auto; font-size: 12px; font-weight: normal; color: #333; }

.layer_frequenter .freq_cntbx { min-height: 60px; vertical-align: middle; }

.layer_frequenter .freq_cntbx:before { display: inline-block; min-height: 60px; vertical-align: middle; content: ""; }

.layer_frequenter .freq_inner { display: inline-block; vertical-align: middle; }

.layer_frequenter .freq_txt2 { margin-top: 8px; font-weight: bold; }

.layer_frequenter .freq_txt .store { font-weight: bold; }

.layer_frequenter .freq_txt .point { font-weight: normal; color: #ff0038; }

.layer_frequenter .btn_bx { margin-top: 16px; }

.layer_frequenter .btn_bx a { display: inline-block; width: 100px; margin-left: 2px; font-size: 12px; line-height: 30px; }

.layer_frequenter .btn_bx a:first-child { margin-left: 0; }

.layer_frequenter .btn_like { border: 1px solid; background: #333; color: #fff; }

.layer_frequenter .btn_next { border: 1px solid; background: #999; color: #fff; }

.layer_frequenter .inp_wrap { position: relative; margin-top: 16px; text-align: left; }

.layer_frequenter .inp_wrap input { position: absolute; top: 0; left: 0; width: 16px; height: 16px; border: 0; background: none; opacity: 0; filter: Alpha(opacity=0); }

.layer_frequenter .inp_wrap label { display: inline-block; padding-left: 24px; background: url("/img/mini/home/icon_check.png") no-repeat 0 50%; color: #666; line-height: 18px; }

.layer_frequenter .inp_wrap.checked label { background-image: url("/img/mini/home/icon_check_active.png"); }

.layer_frequenter .ico_arrow { position: absolute; top: 0; left: 50%; width: 20px; height: 11px; margin: -11px 0 0 -10px; background: url("/img/mini/home/icon_frequenter_arrow.png") no-repeat; }

.layer_frequenter .btn_close { position: absolute; top: 2px; right: 2px; width: 34px; height: 34px; background: url("/img/mini/home/icon_frequenter_close.png") no-repeat 50% 50%; }

.store_lnb_f { position: absolute; left: 0; top: 0; bottom: 0; width: 200px; background-color: #fff; z-index: 10; /* MPSR-51108 delete */ /* // MPSR-51108 delete */ }

.store_lnb_f .store_search { padding: 10px 10px 0; }

.store_lnb_f .store_search .input_search[type='text'] { display: block; position: relative; *top: -1px; width: 142px; height: 18px; padding: 9px 0; border: 0; background-color: #f4f4f4; text-indent: 10px; }

.store_lnb_f .store_search .button_search { overflow: hidden; position: absolute; right: 10px; top: 10px; width: 38px; height: 36px; background-color: #333; text-align: center; border: 0; }

.store_lnb_f .store_search .button_search:after { background-image: url("/img/mini/sprites/sp_mini_2x_2024719_13141.png"); background-position: -29px -35px; width: 20px; height: 20px; background-size: 78px 59px; display: inline-block; vertical-align: middle; content: ''; }

.store_lnb_f .store_navigation { margin: 20px 0; overflow-y: auto; overflow-x: hidden; height: 691px; }

.store_lnb_f .store_tag { padding: 20px 0; border-top: 1px solid #ebebeb; }

.store_lnb_f .navigation_item_em .navigation_link { position: relative; color: #333; font-size: 13px; }

.store_lnb_f .navigation_item_em .navigation_link:after { position: absolute; right: 21px; top: 50%; width: 7px; height: 7px; margin: -3px -3px 0 0; border: 1px solid #111; border-width: 1px 1px 0 0; transform: rotate(45deg); content: ''; }

.store_lnb_f .f_heading { position: relative; padding: 5px 20px; }

.store_lnb_f .f_heading a { position: absolute; right: 8px; top: 0; width: 30px; height: 30px; background: url("/img/mini/home/icon_category_all.png") no-repeat 0 0; }

.store_lnb_f .navigation_item { position: relative; }

.store_lnb_f .navigation_item:hover .navigation_link { font-weight: bold; color: #333; text-decoration: none; outline: none; }

.store_lnb_f .navigation_item.on .navigation_link { font-weight: bold; color: #111; }

.store_lnb_f .navigation_link { display: block; padding: 5px 20px; color: #666; transition: 0.15s ease-out; }

.store_lnb_f .navigation_link:focus, .store_lnb_f .navigation_link:active, .store_lnb_f .navigation_link:hover { font-weight: bold; color: #333; text-decoration: none; outline: none; }

.store_lnb_f .promotion { position: relative; margin-top: 13px; padding-top: 17px; }

.store_lnb_f .promotion:before { position: absolute; left: 20px; top: 0; width: 16px; height: 1px; background-color: #ccc; content: ''; }

.store_lnb_f .promotion .navigation_link { color: #fd7985; }

.store_lnb_f .promotion .navigation_link:focus, .store_lnb_f .promotion .navigation_link:active, .store_lnb_f .promotion .navigation_link:hover { color: #ff0038; }

.store_lnb_f .promotion .navigation_item:hover .navigation_link { color: #ff0038; }

.store_lnb_f .etc { position: relative; margin-top: 13px; padding-top: 13px; }

.store_lnb_f .etc:before { position: absolute; left: 20px; top: 0; width: 16px; height: 1px; background-color: #ccc; content: ''; }

.store_lnb_f .etc .navigation_link { color: #111; }

.store_lnb_f .etc .item_notice > a { position: relative; }

.store_lnb_f .etc .item_notice > a:before { background-image: url("/img/mini/sprites/sp_mini_2x_2024719_13141.png"); background-position: -64px 0px; width: 14px; height: 14px; background-size: 78px 59px; display: inline-block; margin: -2px 8px 0 0; vertical-align: middle; content: ''; }

.store_lnb_f .etc .item_instagram { margin-top: 19px; padding: 0 10px; }

.store_lnb_f .etc .item_instagram > a { position: relative; padding: 10px 12px 10px 50px; border: 1px solid #ddd; font-size: 12px; line-height: 16px; }

.store_lnb_f .etc .item_instagram > a:before { background-image: url("/img/mini/sprites/sp_mini_2x_2024719_13141.png"); background-position: 0px 0px; width: 30px; height: 30px; background-size: 78px 59px; position: absolute; top: 11px; left: 10px; content: ''; }

.store_lnb_f .etc .item_instagram > a:hover { font-weight: normal; }

.store_lnb_f .etc .item_instagram > a > em { display: block; margin-top: 2px; font-weight: bold; line-height: 1; word-break: break-word; word-break: break-all; }

.store_lnb { width: 200px; min-height: 768px; background-color: #fff; }

.store_lnb .tag_list { padding: 0 20px 10px; }

.store_lnb .tag_item { display: inline-block; margin: 2px 0; padding: 5px 10px; border-radius: 15px; background-color: #f4f4f4; color: #666; }

.store_category_all { display: none; position: absolute; left: 0; top: 56px; min-width: 198px; padding: 9px 0 11px; border: 1px solid #333; background-color: #fff; white-space: nowrap; z-index: 1; }

.store_category_all .category_heading { position: relative; padding: 5px 19px; }

.store_category_all .category_list { display: inline-block; *display: inline; zoom: 1; width: 153px; vertical-align: top; }

.store_category_all .category_item { position: relative; zoom: 1; }

.store_category_all .category_link { display: block; padding: 5px 19px; color: #666; zoom: 1; }

.store_category_all .category_link:focus, .store_category_all .category_link:active, .store_category_all .category_link:hover { color: #333; text-decoration: underline; }

.store_category_all_layer_close { position: absolute; right: 11px; top: 12px; width: 26px; height: 26px; background: url("/img/mini/home/icon_store_info_layer_x.png") no-repeat 50% 50%; }

/* MPSR-51108 delete */
.store_main_spot { margin-top: 30px; }

.store_main_spot + .store_main_spot { margin-top: 10px; }

.store_profile_area { overflow: hidden; margin: -2px; zoom: 1; }

.store_profile_link, .store_profile_product_link { position: absolute; left: 2px; right: 2px; top: 2px; bottom: 2px; overflow: hidden; }

.store_profile_link:hover, .store_profile_product_link:hover { text-decoration: none; }

.store_profile figure, .store_profile_product figure { position: absolute; left: 0; right: 0; top: 0; bottom: 0; overflow: hidden; }

.store_profile { float: left; position: relative; width: 50%; padding-top: 50%; }

.store_profile figure img { display: block; width: 100%; }

.store_profile figcaption { position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: url("/img/mini/home/thumb_mask_488.png"); }

.store_profile figcaption strong { position: absolute; left: 30px; right: 30px; top: 192px; height: 96px; font-weight: bold; font-size: 40px; line-height: 48px; color: #fff; text-align: center; word-break: keep-all; word-wrap: break-word; overflow: hidden; }

.store_profile figcaption p { position: absolute; left: 30px; right: 30px; top: 314px; font-size: 16px; line-height: 24px; color: #fff; text-align: center; }

.store_profile figcaption .icon_go { position: absolute; left: 50%; right: 0; bottom: 48px; width: 53px; height: 53px; margin-left: -26px; background: url("/img/mini/home/icon_profile_circle_arrow.png") no-repeat 0 0; }

.store_profile_link, .store_profile_product_link { overflow: hidden; position: absolute; left: 2px; right: 2px; top: 2px; bottom: 2px; background-color: #fff; }

.store_profile_link:hover, .store_profile_product_link:hover { text-decoration: none; }

.store_profile figure, .store_profile_product figure { position: absolute; left: 0; right: 0; top: 0; bottom: 0; overflow: hidden; }

.store_profile_product { float: left; position: relative; width: 25%; padding-top: 25%; }

.store_profile_product img { position: absolute; top: 0; right: 0; left: 0; bottom: 0; width: 100%; margin: auto; }

.store_profile_product figcaption { position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-color: transparent; transition: 0.3s ease-out; }

.store_profile_product figcaption strong { display: none; position: absolute; left: 20px; right: 20px; top: 68px; height: 44px; font-weight: normal; font-size: 16px; line-height: 22px; color: #fff; text-align: center; word-break: keep-all; word-wrap: break-word; overflow: hidden; }

.store_profile_product figcaption .title { top: 98px; }

.store_profile_product .consult_info { display: none; position: absolute; left: 0; right: 0; top: 124px; text-align: center; }

.store_profile_product .consult_info .consult_txt { color: #fff; font-size: 15px; font-weight: bold; }

.store_profile_product .store_profile_product_price { display: none; position: absolute; left: 0; right: 0; top: 124px; color: #fff; text-align: center; }

.store_profile_product .store_profile_product_price em { display: inline-block; padding-top: 3px; font-weight: bold; font-size: 15px; line-height: 17px; font-family: sans-serif; vertical-align: top; }

.store_profile_product .store_profile_product_price .price_unit { display: inline-block; font-size: 14px; line-height: 20px; vertical-align: top; }

.store_profile_product figcaption .icon_go { position: absolute; left: 50%; bottom: 37px; width: 43px; height: 43px; margin-left: -22px; background: url("/img/mini/home/icon_profile_circle_arrow.png") no-repeat 0 -53px; opacity: 0; transform: translateY(30px); transition: 0.2s ease-out; }

.store_profile_product_link:focus figcaption, .store_profile_product_link:active figcaption, .store_profile_product_link:hover figcaption { background-color: rgba(0, 0, 0, 0.5); background: url("/img/mini/home/opacity50.png") \9; }

.store_profile_product_link:focus figcaption strong, .store_profile_product_link:active figcaption strong, .store_profile_product_link:hover figcaption strong, .store_profile_product_link:focus figcaption .store_profile_product_price, .store_profile_product_link:active figcaption .store_profile_product_price, .store_profile_product_link:hover figcaption .store_profile_product_price, .store_profile_product_link:focus figcaption .consult_info, .store_profile_product_link:active figcaption .consult_info, .store_profile_product_link:hover figcaption .consult_info { display: block; }

.store_profile_product_link:focus .icon_go, .store_profile_product_link:active .icon_go, .store_profile_product_link:hover .icon_go { opacity: 1; transform: translateY(0); }

/* // MPSR-51108 delete */
.store_visual_wrap { position: relative; margin-top: 30px; }

.store_visual_wrap + .store_visual_wrap { margin-top: 4px; }

.store_visual_wrap .store_visual_list { overflow: hidden; position: relative; width: 100%; }

.store_visual_wrap .store_visual_list .store_visual_list_in { margin: -2px; }

.store_visual_wrap .store_visual_item { float: left; position: relative; width: 492px; height: 492px; }

.store_visual_wrap .store_visual_item .store_visual_link { overflow: hidden; position: absolute; top: 2px; right: 2px; bottom: 2px; left: 2px; background: url("/img/mini/main/img_default_visual.jpg") no-repeat 0 0; }

.store_visual_wrap .store_visual_item figure:after { position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: url("/img/mini/home/thumb_mask_488_x2.png"); background-size: 100% 100%; content: ''; }

.store_visual_wrap .store_visual_item figure > img { position: absolute; top: 0; left: 50%; height: 100%; margin: auto; transform: translateX(-50%); }

.store_visual_wrap .store_visual_item figcaption { position: absolute; right: 35px; bottom: 35px; left: 35px; z-index: 2; color: #fff; font-size: 20px; line-height: 1.2; text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2); }

.store_visual_wrap .store_visual_item figcaption > strong { display: block; font-size: 38px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.store_visual_wrap .store_visual_item figcaption > span { display: block; margin-top: 6px; }

.store_visual_wrap .store_visual_item figcaption .btn { display: inline-block; min-width: 75px; margin-top: 15px; padding: 5px 4px; text-align: center; background-color: rgba(0, 0, 0, 0.04); border: solid 1px rgba(255, 255, 255, 0.6); border-radius: 4px; font-size: 14px; line-height: 20px; }

.store_visual_wrap .store_visual_item .text_add { display: block; margin-top: 12px; color: #fff; font-size: 15px; font-weight: bold; }

.store_visual_wrap .store_visual_item .icon_go { display: block; width: 43px; height: 43px; margin: 15px auto 0; background: url("/img/mini/home/icon_profile_circle_arrow.png") no-repeat 0 -53px; opacity: 0; transform: translateY(30px); transition: 0.2s ease-out; }

.store_visual_wrap .store_visual_product { position: relative; float: left; width: 50%; padding-top: 50%; }

.store_visual_wrap .store_visual_product_link { overflow: hidden; position: absolute; left: 2px; right: 2px; top: 2px; bottom: 2px; background-color: #fff; }

.store_visual_wrap .store_visual_product_link figcaption { position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-color: transparent; text-align: center; transition: 0.3s ease-out; }

.store_visual_wrap .store_visual_product_link figcaption > strong { display: -webkit-box; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical; white-space: initial; display: none; position: absolute; left: 20px; right: 20px; bottom: 130px; max-height: 44px; font-weight: normal; font-size: 16px; line-height: 22px; color: #fff; word-break: keep-all; word-wrap: break-word; white-space: normal; text-overflow: initial; }

.store_visual_wrap .store_visual_product_link figcaption > strong.title { bottom: 100px; }

.store_visual_wrap .store_visual_product_link figcaption .text_price { display: none; position: absolute; left: 0; right: 0; top: 124px; color: #fff; text-align: center; }

.store_visual_wrap .store_visual_product_link figcaption .text_em { display: none; position: absolute; left: 0; right: 0; top: 124px; text-align: center; color: #fff; font-size: 15px; font-weight: bold; }

.store_visual_wrap .store_visual_product_link figcaption .icon_go { position: absolute; left: 50%; bottom: 37px; width: 43px; height: 43px; margin-left: -22px; background: url(/img/mini/home/icon_profile_circle_arrow.png) no-repeat 0 -53px; opacity: 0; transform: translateY(30px); transition: 0.2s ease-out; }

.store_visual_wrap .store_visual_product_link:focus figcaption, .store_visual_wrap .store_visual_product_link:active figcaption, .store_visual_wrap .store_visual_product_link:hover figcaption { background-color: rgba(0, 0, 0, 0.5); background: url("/img/mini/home/opacity50.png") \9; }

.store_visual_wrap .store_visual_product_link:focus figcaption strong, .store_visual_wrap .store_visual_product_link:active figcaption strong, .store_visual_wrap .store_visual_product_link:hover figcaption strong { display: block; display: -webkit-box; }

.store_visual_wrap .store_visual_product_link:focus figcaption .text_price, .store_visual_wrap .store_visual_product_link:active figcaption .text_price, .store_visual_wrap .store_visual_product_link:hover figcaption .text_price, .store_visual_wrap .store_visual_product_link:focus figcaption .text_em, .store_visual_wrap .store_visual_product_link:active figcaption .text_em, .store_visual_wrap .store_visual_product_link:hover figcaption .text_em { display: block; }

.store_visual_wrap .store_visual_product_link:focus .icon_go, .store_visual_wrap .store_visual_product_link:active .icon_go, .store_visual_wrap .store_visual_product_link:hover .icon_go { opacity: 1; transform: translateY(0); }

.store_wrap .btn_view_prev { overflow: hidden; position: absolute; z-index: 2; left: 0; top: 50%; width: 60px; height: 60px; margin-top: -30px; background: url(/img/mini/home/icon_swipe_arrow.png) no-repeat -1px -1px; line-height: 200px; }

.store_wrap .btn_view_prev:hover { background-position: -1px -63px; }

.store_wrap .btn_view_next { overflow: hidden; position: absolute; z-index: 2; right: 0; top: 50%; width: 60px; height: 60px; margin-top: -30px; background: url("/img/mini/home/icon_swipe_arrow.png") no-repeat -63px -1px; line-height: 200px; }

.store_wrap .btn_view_next:hover { background-position: -63px -63px; }

.store_wrap .pagination_view { margin-top: 14px; height: 10px; margin-top: 10px; text-align: center; }

.store_wrap .pagination_view .btn_icon { display: inline-block; width: 10px; height: 10px; margin: 0 2px; background: #ccc; border-radius: 5px; text-indent: -9999px; vertical-align: top; line-height: 10px; }

.store_wrap .pagination_view .btn_icon.active { background: #000; }

.store_visual_list.list_type2 .store_visual_link { background: url("/img/mini/main/img_default_visual_22.jpg") no-repeat 0 0; }

.store_visual_list.list_type2 .store_visual_item { width: 564px; height: 282px; }

.store_visual_list.list_type2 .store_visual_item figure:after { background-image: url("/img/mini/home/thumb_mask_416x278_x2.png"); }

.store_visual_list.list_type2 .store_visual_item.item_type2 { width: 420px; }

.store_visual_list.list_type2 .store_visual_item.item_type2 figure > img { position: absolute; top: 50%; left: 0; width: 100%; height: auto; transform: translateY(-50%); }

.store_visual_list.list_type2 .store_visual_main figcaption > strong { font-size: 30px; }

.store_visual_list.list_type2 .store_visual_product figcaption > strong { top: 86px; }

.store_visual_list.list_type2 .store_visual_product figcaption .text_price { top: 142px; }

.store_visual_list.list_type2 .store_visual_product figcaption .icon_go { bottom: 55px; }

.store_visual_list.list_type3 .store_visual_link { background: url("/img/mini/main/img_default_visual_21.jpg") no-repeat 0 0; }

.store_visual_list.list_type3 .store_visual_item { height: 330px; }

.store_visual_list.list_type3 .store_visual_item figure:after { background-image: url("/img/mini/home/thumb_mask_488x326_x2.png"); }

.store_visual_list.list_type3 .store_visual_item figure > img { position: absolute; top: 50%; left: 0; width: 100%; height: auto; transform: translateY(-50%); }

.store_banner { overflow: hidden; margin-top: 40px; }

.store_banner + .store_banner { margin-top: 16px; }

.store_banner_list { overflow: hidden; margin: -8px; zoom: 1; }

.store_banner_item { float: left; }

.store_banner_list.banner1 .store_banner_item, .store_banner_list.banner2 .store_banner_item { width: 50%; }

.store_banner_list.banner3 .store_banner_item { width: 33.3333%; }

.store_banner_list.banner3 .store_banner_item .store_banner_link { height: 158px; }

.store_banner_link { display: block; height: 241px; margin: 8px; background: url("/img/mini/main/img_default_banner.jpg") no-repeat 0 0; background-size: 100% 100%; border-bottom: 1px solid #c6cacc; }

.store_banner_link img { display: block; width: 100%; }

.store_banner_seller { display: table; width: 482px; height: 240px; margin: 8px; background-color: #fff; vertical-align: middle; text-align: center; border-bottom: 1px solid #c6cacc; }

.store_banner_seller .banner_in { display: table-cell; vertical-align: middle; }

.store_banner_seller strong { font-size: 24px; line-height: 32px; color: #000; text-decoration: underline; }

.store_banner_seller .text { display: block; margin-top: 17px; padding: 0 50px; font-size: 15px; line-height: 1.5; color: #000; }

.store_banner_seller .btn { position: relative; display: inline-block; margin-top: 30px; min-width: 111px; padding: 10px 20px 10px 44px; background-color: #333; border-radius: 22px; color: #fff; font-weight: bold; font-size: 15px; line-height: 24px; word-break: keep-all; word-wrap: break-word; }

.store_banner_seller .btn:before { position: absolute; background-image: url("/img/mini/sprites/sp_mini_2x_2024719_13141.png"); background-position: -35px 0px; width: 24px; height: 24px; background-size: 78px 59px; top: 50%; left: 20px; display: inline-block; margin-top: -12px; vertical-align: middle; transition-duration: 0.15s; content: ''; }

.store_banner_seller .btn.active:before { background-image: url("/img/mini/sprites/sp_mini_2x_2024719_13141.png"); background-position: 0px -35px; width: 24px; height: 24px; background-size: 78px 59px; }

/* MPSR-51108 삭제 */
.store_banner_profile { position: relative; height: 240px; margin: 8px; background-color: #fff; border-bottom: 1px solid #c6cacc; }

.store_banner_profile strong { position: absolute; left: 30px; right: 30px; top: 36px; font-size: 24px; line-height: 32px; color: #fff; text-align: center; text-decoration: underline; }

.store_banner_profile p { position: absolute; left: 30px; right: 30px; top: 81px; font-size: 15px; line-height: 26px; color: #fff; text-align: center; word-break: keep-all; word-wrap: break-word; }

.store_banner_profile span { position: absolute; left: 30px; right: 30px; bottom: 44px; font-size: 15px; line-height: 20px; color: #666; text-align: center; word-break: keep-all; word-wrap: break-word; }

.store_banner_profile span a { display: inline-block; min-width: 80px; padding: 12px 30px 12px 65px; border-radius: 22px; background: #fff url("/img/mini/home/icon_store_zzim2.png") no-repeat 24px 2px; transition-duration: 0.15s; }

.store_banner_profile span a.on { background-position: 24px -38px; }

.store_banner_profile span em { font-weight: bold; }

/* // MPSR-51108 삭제 */
.category_btn { position: relative; display: inline-block; width: 142px; height: 34px; margin: -9px 0 -5px; padding: 0 25px 0 10px; background-color: #fff; border: 1px solid #e3e3e8; text-align: left; color: #333; font-weight: bold; font-size: 13px !important; line-height: 32px !important; }

.category_btn:after { display: block; position: absolute; top: 50%; right: 13px; width: 7px; height: 7px; margin-top: -6px; border: 1px solid #aaa; border-width: 0 1px 1px 0; transform: rotate(45deg); content: ''; }

.category_btn.on { background-position: 100% 100%; border-color: #666; }

.category_btn.on:after { border-width: 1px 0 0 1px; margin-top: -3px; }

.category_btn:focus, .category_btn:active, .category_btn:hover { text-decoration: none; }

.category_list_layer { display: none; position: absolute; left: 0; top: 33px; border: 1px solid #676767; background-color: #fff; z-index: 1; overflow: hidden; }

.category_list_layer .category_list { float: left; width: 140px; margin-right: -1px; padding: 7px 0; border-right: 1px solid #dadada; }

.category_list_layer .category_list + .category_list { margin-left: 1px; }

.category_list_layer .category_link { display: block; padding: 5px 10px 4px; color: #333; font-size: 13px; }

.category_list_layer .category_link:hover { color: #ff0038; text-decoration: underline; }

/* MPSR-51108 삭제 */
.store_banner_seller .text { word-break: normal !important; word-wrap: normal !important; }

.category_list_layer .category_link:hover { background-color: transparent; text-decoration: underline; }

/* // MPSR-51108 삭제 */
/* MPSR-55604 삭제 */
.store_promotion_header { position: relative; overflow: hidden; margin-top: 30px; background-color: #8bc8e4; zoom: 1; }

.store_promotion_header img { float: left; width: 320px; height: 320px; }

.store_promotion_header figcaption { float: left; position: relative; width: 660px; height: 320px; color: #fff; }

.store_promotion_header .store_promotion_subject { display: block; margin-top: 53px; margin-bottom: -55px; font-size: 22px; line-height: 28px; text-align: center; text-decoration: underline; word-break: keep-all; word-wrap: break-word; }

.store_promotion_header .store_promotion_title { overflow: hidden; height: 88px; margin-top: 77px; padding: 0 100px; font-size: 36px; line-height: 44px; text-align: center; word-break: keep-all; word-wrap: break-word; }

.store_promotion_header .store_promotion_explain { overflow: hidden; height: 56px; margin-top: 20px; padding: 0 100px; font-size: 18px; line-height: 28px; text-align: center; word-break: keep-all; word-wrap: break-word; }

/* //MPSR-55604 삭제 */
.store_promotion_link { display: block; position: relative; margin: 2px; }

.store_promotion.viewtype { width: auto; margin: 0; }

.store_promotion.viewtype ul { width: auto; padding: 0 12px; zoom: 1; }

.store_promotion.viewtype ul:after { display: block; content: ""; clear: both; }

.store_promotion.viewtype li { width: 233px; padding: 0 3px; }

.store_promotion.viewtype li .img_plot { margin-bottom: 13px; }

.store_promotion.viewtype li .img_plot img { width: 100%; }

.store_promotion.viewtype li .box_pd .pname { padding: 0; }

.store_promotion.viewtype li .box_pd .pname p { overflow: hidden; height: 22px; font-size: 15px; white-space: nowrap; text-overflow: ellipsis; }

.store_promotion.viewtype li .box_pd .pname .price_info { font-size: 13px; }

.store_promotion.viewtype li .box_pd .pname .price_info.mini .price_detail .sale_price { font-size: 18px; }

.store_promotion.viewtype li .box_pd .pname .price_info .sale { color: #ff0038; font-weight: bold; font-size: 18px; }

.store_deal { position: relative; }

.store_deal + .store_deal { margin-top: 16px; }

.store_deal_list { position: relative; overflow: hidden; width: 100%; }

.store_deal_list .store_deal_view_prd { overflow: hidden; height: 387px; }

.store_deal_list .box { float: left; width: 1220px; }

.store_deal_list li { float: left; width: 482px; padding: 0 16px 20px 0; }

.store_deal_list .thumb img { display: block; max-width: 100%; height: 243px; margin: auto; }

.store_deal_list .product_type1 > a { position: relative; display: block; height: 387px; background-color: #fff; }

.store_deal_list .product_type1 > a .thumb .line_frame { *width: 283px; *height: 140px; }

.store_deal_list .product_type1 > a .ico_emblem { position: absolute; top: 5px; left: 5px; width: 80px; height: 80px; text-align: center; }

.store_deal_list .product_type1 > a .ico_emblem img { width: 100%; height: 100%; }

.store_deal_list .product_type1 > a .border_frame { position: absolute; left: 0px; top: 0px; width: 100%; height: 100%; border: 1px solid #ff0038; box-sizing: border-box; transition: opacity 0.2s; opacity: 0; filter: alpha(opacity=0); cursor: pointer; }

.store_deal_list .product_type1 > a:hover .border_frame { opacity: 1; filter: alpha(opacity=100); }

.store_deal_list .product_type1 > a .summary_in { padding: 10px 20px 10px 20px; height: calc(144px - 59px); }

.store_deal_list .product_type1 > a .summary_in .info .sale { color: #ff0038; }

.store_deal_list .product_type1 > a .summary_in .info .sale.sale_tx { font-size: 16px; letter-spacing: -1px; }

.store_deal_list .product_type1 > a .summary_in .info .sale .n { font-size: 16px; }

.store_deal_list .product_type1 > a .summary_in .info .price { font-size: 13px; margin-left: 4px; }

.store_deal_list .product_type1 > a .summary_in .info .price:first-child { margin-left: 0; }

.store_deal_list .product_type1 > a .summary_in .info .price .n { font-size: 22px; line-height: 27px; }

.store_deal_list .product_type1 > a .summary_in .info .price .normal_price { margin-left: 4px; color: #bbb; text-decoration: line-through; }

.store_deal_list .product_type1 > a .summary_in .info .price .text_price { font-size: 16px; line-height: 27px; letter-spacing: -1px; }

.store_deal_list .product_type1 > a .subject { margin-bottom: 5px; }

.store_deal_list .product_type1 > a .amount { float: right; margin: 8px 0 0 0; font-size: 11px; color: #999; }

.store_deal_list .product_type1 > a .amount .n { font-family: Tahoma, sans-serif; letter-spacing: 0; }

.store_deal_list .product_type1 > a .prod_benefit { padding: 10px 20px; border-top: 1px solid #eee; color: #666; font-size: 13px; line-height: 18px; }

.store_deal_list .product_type1 > a .prod_benefit .uiq_sale { padding-left: 6px; }

.store_deal_list .product_type1 > a .prod_benefit .uiq_sale:before { display: inline-block; margin: -3px 5px 0 -6px; width: 2px; height: 2px; background-color: #ddd; border-radius: 50%; vertical-align: middle; content: ""; }

.store_deal_list .product_type1 > a .prod_benefit .uiq_sale:first-child { padding-left: 0; }

.store_deal_list .product_type1 > a .prod_benefit .uiq_sale:first-child:before { display: none; }

.store_deal_list .product_type1 > a .prod_benefit .uiq_sale em { color: #ff0038; }

.store_deal_list .product_type1 > a .add { height: 36px; line-height: 0; padding: 0; border-bottom: 1px solid #c6cacc; }

.store_deal_list .product_type1 > a .prod_coupon { position: absolute; left: 0; bottom: 1px; width: 100%; height: 36px; }

.store_deal_list .product_type1 > a .prod_coupon .tx { height: 36px; padding: 0 20px; line-height: 36px; font-size: 11px; }

.store_deal_list .product_type1 > a .prod_coupon .lk_go { position: relative; display: inline-block; height: 20px; padding: 8px 8px 8px 0; line-height: 20px; }

.store_deal_list .product_type1 > a .prod_coupon .lk_go.full { margin: 0 20px; }

.store_deal_list .product_type1 > a .prod_coupon .lk_go.full .coupon_rate { width: 237px; }

.store_deal_list .product_type1 > a .prod_coupon .lk_go.full .coupon_rate .coupon_name { width: 180px; }

.store_deal_list .product_type1 > a .prod_coupon .lk_go.lt { float: left; margin-left: 20px; }

.store_deal_list .product_type1 > a .prod_coupon .lk_go.rt { float: right; margin-right: 20px; }

.store_deal_list .product_type1 > a .prod_coupon .lk_go:after { transform: rotate(45deg); height: 5px; width: 5px; position: absolute; top: 50%; right: 0; margin-top: -3px; border: solid #666; border-width: 1px 1px 0 0; content: ""; }

.store_deal_list .product_type1 > a .prod_coupon .coupon_rate { display: inline-block; }

.store_deal_list .product_type1 > a .prod_coupon .coupon_rate > span { display: inline-block; float: left; }

.store_deal_list .product_type1 > a .prod_coupon .coupon_rate .coupon_sale { position: relative; margin-right: 6px; padding: 0 8px 0 4px; background-color: #fff; color: #666; text-align: center; letter-spacing: -1px; line-height: 19px; }

.store_deal_list .product_type1 > a .prod_coupon .coupon_rate .coupon_sale em { position: relative; font-weight: bold; white-space: nowrap; font-size: 10px; color: #ff0038; letter-spacing: 0; vertical-align: top; line-height: 20px; }

.store_deal_list .product_type1 > a .prod_coupon .coupon_rate .coupon_sale:before { display: inline-block; background-image: url(/img/main/v2/sp_main.png); background-position: 100% -167px; width: 12px; height: 20px; position: absolute; right: 0; top: 0; content: ""; }

.store_deal_list .product_type1 > a .prod_coupon .coupon_rate .coupon_sale:after { display: inline-block; position: absolute; left: 0; right: 0; top: 0; bottom: 0; border: 1px solid rgba(0, 0, 0, 0.1); content: ""; }

.store_deal_list .product_type1 > a .prod_coupon .coupon_rate .coupon_sale.num_type { letter-spacing: 0; }

.store_deal_list .product_type1 > a .prod_coupon .coupon_rate .coupon_name { font-size: 11px; line-height: 20px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.store_deal_list .product_type1 > a .prod_coupon .store { position: relative; display: inline-block; height: 20px; font-size: 11px; vertical-align: middle; }

.store_deal_list .product_type1 > a .prod_coupon .store:before { display: inline-block; width: 16px; height: 16px; margin: 0 3px -2px 0; background-image: url(/img/main/v2/sp_main.png); background-position: 100% -139px; content: ""; }

.store_deal_list .product_type1 > a .prod_coupon .store .txt { vertical-align: top; width: 90px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 11px; line-height: 16px; }

.store_deal_list .product_type1 > a.closed .thumb .line_frame { border: 0; background: url("/img/default/bg_000_07.png"); opacity: 1; filter: alpha(opacity=100); text-align: center; }

.store_deal_list .product_type1 > a.closed .thumb .line_frame .txt { display: inline-block; padding-top: 55px; font-size: 18px; font-weight: bold; color: #fefefe; }

.store_deal_list .product_type1 > a.closed .txt_closed { padding-top: 5px; font-size: 13px; font-weight: bold; color: #999; }

.store_deal_list .product_type1 > a.closed a:hover .subject { text-decoration: none; }

.store_deal_list .product_type1 > a.closed .amount, .store_deal_list .product_type1 > a.closed .sale, .store_deal_list .product_type1 > a.closed .price { display: none; }

.store_deal_list .product_type1 > a .consult_txt { display: block; padding-top: 5px; font-size: 13px; font-weight: bold; color: #0b83e6; }

.store_deal_list.list_type2 .store_deal_view_prd { height: auto; }

.store_deal_list.list_type2 li { float: none; width: 980px; padding: 0; }

.store_deal_list.list_type2 .product_type1 > a { height: auto; padding: 16px; zoom: 1; }

.store_deal_list.list_type2 .product_type1 > a:after { display: block; content: ""; clear: both; }

.store_deal_list.list_type2 .product_type1 .thumb { float: left; width: 482px; margin-right: 20px; }

.store_deal_list.list_type2 .product_type1 .summary_in { padding: 28px 0 0; min-height: 203px; box-sizing: border-box; }

.store_deal_list.list_type2 .product_type1 .subject { margin: 0; padding: 0 0 16px; height: 48px; white-space: normal; }

.store_deal_list.list_type2 .product_type1 .info { padding-bottom: 16px; }

.store_deal_list.list_type2 .product_type1 .info .sale { display: block; margin-bottom: 8px; }

.store_deal_list.list_type2 .product_type1 .info .price { margin-left: 0; }

.store_deal_list.list_type2 .product_type1 .prod_benefit { padding: 10px 0; }

/* MPSR-55604 삭제 */
.pagination_view { height: 10px; margin-top: 10px; text-align: center; }

.pagination_view .btn_icon { display: inline-block; width: 10px; height: 10px; margin: 0 2px; background: #ccc; border-radius: 5px; text-indent: -9999px; vertical-align: top; line-height: 10px; }

.pagination_view .btn_icon.active { background: #000; }

/* // MPSR-55604 삭제 */
/* CSS copy */
.viewtype { overflow: hidden; position: relative; width: 1202px; margin: 0px -1px; }

.viewtype ol, .viewtype ul { width: 100%; zoom: 1; padding: 0 1px; }

.viewtype li { float: left; padding: 3px 10px 3px; }

.viewtype li .box_pd { position: relative; padding: 1px; }

.viewtype li .box_pd:hover { padding: 0; border: 1px solid #ff0038; }

.viewtype li .box_pd img { display: inline-block; vertical-align: top; }

.viewtype li .box_pd.ad_sec, .viewtype li .box_pd.sell_sec { background: #f7f7f7; }

.viewtype li .box_pd .pname { padding: 7px 9px 9px; }

.viewtype li .box_pd .pname p { overflow: hidden; display: block; height: 37px; margin-right: -1px; padding-right: 1px; font-size: 14px; line-height: 18px; color: #333; word-break: break-all; }

.viewtype li .box_pd .pname .price_info.mini { padding: 4px 0 0; }

.viewtype li .box_pd .pname .price_info.mini .price_detail .sale_price { margin-top: 0; font-size: 14px; }

.viewtype li .box_pd.sell { padding: 0; border: 1px solid #ff0038; }

.viewtype li .box_pd.sell .sell_prd { position: absolute; top: 0; left: 0; z-index: 5; width: 100%; padding: 6px 0 9px; background: #ff0038; font-size: 12px; color: #fff; text-align: center; vertical-align: top; }

.viewtype li .box_pd span.best { position: absolute; left: 0; top: 0; width: 31px; height: 23px; padding: 5px 9px 0 0; background: url("/img/category/v2/sp_category.png") -186px -51px no-repeat; font: normal 14px Tahoma, sans-serif; color: #fff; text-align: center; }

.viewtype li .box_pd a { display: block; position: relative; text-decoration: none; }

.viewtype li .box_pd a:hover { text-decoration: none; }

.viewtype li .box_pd a:hover span.best { background-position: -142px -51px; }

.viewtype li .box_pd a:hover .pname > p { text-decoration: underline; }

.viewtype li .box_pd .img_plot { position: relative; }

.viewtype li .box_pd .img_plot:before { position: absolute; top: 0; right: 0; left: 0; bottom: 0; border: 1px solid rgba(0, 0, 0, 0.05); content: ""; }

.viewtype li .box_pd .prd_flag { color: #666; font-size: 12px; padding: 10px 0 8px; text-decoration: none; }

.viewtype li .box_pd .prd_flag em.r_ico { display: inline-block; height: 18px; padding: 0 7px; font-size: 12px; line-height: 16px; border: 1px solid #666; border-radius: 10px; margin-right: 4px; vertical-align: middle; }

.viewtype li .box_pd .s_flag { display: block; margin-top: 9px; }

.viewtype li .box_pd .s_flag em { display: inline-block; padding: 0; font-size: 12px; color: #666; border: none; }

.viewtype li .box_pd .s_flag > em { margin-right: 5px; padding: 0 0 0 0; line-height: 1.4; vertical-align: middle; }

.viewtype li .box_pd .s_flag > em.one { padding: 0 5px 0 0; line-height: 110%; margin-right: 5px; }

.viewtype li .box_pd .s_flag > em.now { background: none; }

.viewtype li .box_pd .s_flag > em.now img { vertical-align: bottom; }

.viewtype li .box_pd .s_flag .bar_10_da { margin-right: 5px; vertical-align: middle; }

.viewtype li .box_pd .s_flag img { width: auto; height: auto; }

.viewtype li .box_pd .s_flag .txt { display: block; margin-top: 2px; color: #999; }

.viewtype li .box_pd .list_btm .info_txt_11day { font-size: 13px; height: auto; }

.viewtype li .box_pd .list_btm .info_txt_11day .point { color: #0b83e6; }

.viewtype li .option_ico { display: inline-block; font-size: 12px; color: #999; font-weight: normal; margin-top: 5px; vertical-align: top; line-height: 130%; }

.viewtype .selr_star_s { width: 74px; }

.viewtype .price_info .sale { font-size: 18px; }

.viewtype .price_info .price_detail .normal_price { bottom: 21px; font-size: 14px; }

.viewtype .price_info .price_detail .sale_price { margin-top: 21px; font-size: 18px; }

.product_type1 { background: #fff; }

.product_type1 .info_etc .tx, .product_type1 .add .tx { display: inline-block; font-size: 12px; color: #666; }

.product_type1 .info_etc .tx .point, .product_type1 .add .tx .point { color: #ff0038; }

.product_type1 .info_etc .tx .point2, .product_type1 .add .tx .point2 { color: #0b83e6; }

.product_type1 .info_etc .tx .lsn, .product_type1 .add .tx .lsn { letter-spacing: 0; }

.product_type1 a { display: block; text-decoration: none; }

.product_type1 .thumb { position: relative; display: block; text-align: center; }

.product_type1 .thumb .line_frame { position: absolute; left: 0px; top: 0px; display: block; width: 100%; height: 100%; border: 1px solid #000; opacity: 0.06; filter: alpha(opacity=6); box-sizing: border-box; }

.product_type1 .category { margin: 12px 0 0 0; color: #5074dd; font-size: 12px; }

.product_type1 .summary_in { position: relative; display: block; padding: 2px 10px 3px 10px; }

.product_type1 .subject { overflow: hidden; display: block; height: 21px; margin-top: 4px; font-size: 16px; line-height: 1.31; color: #333; }

.product_type1 .subject_row2 { height: 42px; }

.product_type1 a:hover .subject { text-decoration: underline; }

.product_type1 .info { display: block; }

.product_type1 .info.have_price_normal { margin-top: 29px; }

.product_type1 .sale { font-size: 14px; color: #ff0038; }

.product_type1 .sale_tx { font-size: 18px; font-weight: bold; }

.product_type1 .sale .n { font-size: 18px; font-family: Tahoma, sans-serif; font-weight: bold; letter-spacing: 0; }

.product_type1 .price { position: relative; margin: 0 0 0 2px; font-size: 15px; color: #333; }

.product_type1 .price .n { font-size: 18px; font-family: Tahoma, sans-serif; font-weight: bold; letter-spacing: 0; }

.product_type1 .price .text_price { font-size: 16px; }

.product_type1 .price_normal { position: absolute; left: 0px; bottom: 20px; color: #999; font-size: 13px; text-decoration: line-through; }

.product_type1 .price_normal .n { font-size: 13px; font-family: Tahoma, sans-serif; font-weight: normal; }

.product_type1 .info_etc { margin: 2px 0 6px 0; }

.product_type1 .info_etc .tx { font-size: 11px; }

.product_type1 .info_etc .tx .n { font-family: Tahoma, sans-serif; letter-spacing: 0; }

.product_type1 .info_etc .group { display: block; line-height: 1.1em; }

.product_type1 .add { overflow: hidden; height: 37px; line-height: 37px; padding: 0 10px 0 10px; background: #fcfcfc; border-top: 1px solid #f4f4f4; }

.product_type1 .add .opt { margin: 0 4px 0 0; font-size: 12px; color: #999; }

.product_type1 .add .tx { font-size: 12px; }

.product_type1 .add .tx_bar { display: inline-block; width: 1px; height: 11px; margin: 0 4px 0 5px; background: #dbdbdb; vertical-align: middle; }

.product_type1 .consult_txt { font-size: 14px; color: #0b83e6; font-weight: bold; }

/* // CSS copy */
/* MPSR-51108 delete */
.store_promotion { float: left; position: relative; }

.store_promotion figure { overflow: hidden; zoom: 1; }

.store_promotion figure img { float: left; width: 242px; height: 242px; }

.store_promotion figure img + figcaption { width: 246px; }

.store_promotion figcaption { float: left; position: relative; width: 242px; height: 242px; background-color: #8bc8e4; color: #fff; }

.store_promotion figcaption .store_promotion_subject { position: absolute; left: 29px; right: 29px; top: 25px; text-decoration: underline; word-break: keep-all; word-wrap: break-word; overflow: hidden; }

.store_promotion figcaption .store_promotion_title { position: absolute; left: 29px; right: 29px; top: 55px; max-height: 52px; font-weight: normal; font-size: 20px; line-height: 26px; word-break: keep-all; word-wrap: break-word; overflow: hidden; }

.store_promotion figcaption .store_promotion_explain { position: absolute; left: 29px; right: 29px; top: 115px; max-height: 36px; word-break: keep-all; word-wrap: break-word; overflow: hidden; }

.store_promotion figcaption .go_store_promotion { position: absolute; left: 29px; bottom: 29px; padding: 6px 15px 6px; border: 1px solid; font-size: 12px; line-height: 18px; }

.store_promotion.viewtype { float: none; }

.promotion_header { position: relative; overflow: hidden; margin-top: 30px; height: 184px; border-bottom: 1px solid #ccc; border-color: rgba(0, 0, 0, 0.2); background-color: #fff; zoom: 1; }

.promotion_header .store_promotion_title { overflow: hidden; height: 44px; margin-top: 55px; padding: 0 100px; font-size: 36px; line-height: 44px; text-align: center; word-break: keep-all; word-wrap: break-word; }

.promotion_header .store_promotion_explain { overflow: hidden; height: 28px; margin: 7px 0 50px 0; padding: 0 100px; font-size: 18px; line-height: 28px; text-align: center; word-break: keep-all; word-wrap: break-word; }

.store_promotion_wrap { position: relative; margin-top: 40px; }

.store_promotion_wrap h3 { font-weight: bold; padding-bottom: 12px; font-size: 20px; line-height: 28px; }

.store_promotion_wrap .store_promotion_list { overflow: hidden; margin-top: 6px; padding: 8px; border-bottom: 1px solid #ccc; border-color: rgba(0, 0, 0, 0.2); background-color: #fff; }

.store_promotion_wrap .store_sub_promotion { float: left; position: relative; margin: 8px; border: 1px solid #eee; }

.store_promotion_wrap .store_sub_promotion figure { overflow: hidden; zoom: 1; }

.store_promotion_wrap .store_sub_promotion figure .thumb { position: relative; float: left; width: 160px; height: 160px; }

.store_promotion_wrap .store_sub_promotion figure img { width: 160px; height: 160px; }

.store_promotion_wrap .store_sub_promotion figure .bg_frame { position: absolute; left: 0px; top: 0px; display: block; width: 100%; height: 100%; background-color: #000; opacity: 0.03; filter: alpha(opacity=3); box-sizing: border-box; }

.store_promotion_wrap .store_sub_promotion .store_promotion_link { margin: 0; }

.store_promotion_wrap .store_sub_promotion figcaption { float: left; position: relative; width: 264px; height: 160px; padding: 0 20px; background-color: #fff; color: #333; }

.store_promotion_wrap .store_sub_promotion figcaption .store_promotion_title { display: block; padding-top: 54px; height: 26px; font-weight: bold; font-size: 20px; line-height: 26px; word-break: keep-all; word-wrap: break-word; overflow: hidden; }

.store_promotion_wrap .store_sub_promotion figcaption .store_promotion_explain { margin-top: 9px; font-size: 14px; line-height: 18px; word-break: keep-all; word-wrap: break-word; overflow: hidden; }

.store_content .tag_list { margin: 20px 0 -10px; font-size: 12px; line-height: 18px; }

.store_content .tag_item { display: inline-block; margin-bottom: 10px; padding: 5px 15px; border-radius: 14px; background-color: #a3a5a8; color: #fff; transition: 0.15s ease-out; }

.store_content .tag_item.on { background-color: #454748; }

.store_content .tag_item:focus, .store_content .tag_item:active, .store_content .tag_item:hover { text-decoration: none; }

.store_content .store_category_wrap { margin-top: 30px; background-color: #fff; border-bottom: 1px solid #c6cacc; }

/* MPSR-51108 삭제 */
.store_category_path { font-size: 16px; line-height: 22px; }

.store_category_path a, .store_category_path .path_tit { display: block; padding: 12px 16px; font-weight: normal; color: #111; }

.store_category_path a { background: url("/img/mini/home/icon_category_path.png") no-repeat 100% 100%; }

.store_category_path a strong { font-weight: normal; color: #ff0038; }

.store_category_path.on a { background-position: 100% 0; }

/* // MPSR-51108 삭제 */
.category_path { position: relative; padding: 7px 16px; font-size: 16px; line-height: 22px; }

.category_path a, .category_path span { display: inline-block; padding: 5px 0; vertical-align: middle; color: #111; }

.category_path strong { font-weight: normal; vertical-align: middle; color: #ff0038; }

.category_path button.view_toggle { position: absolute; top: 50%; right: 10px; margin-top: -16px; }

.store_wrap .btn_icon { overflow: hidden; position: relative; width: 32px; height: 32px; line-height: 200px; white-space: nowrap; }

.store_wrap .btn_icon.view_toggle:before, .store_wrap .btn_icon.view_toggle:after { position: absolute; top: 50%; left: 50%; width: 16px; height: 2px; margin: -1px 0 0 -8px; background-color: #7d7d7d; content: ''; }

.store_wrap .btn_icon.view_toggle:after { transform: rotate(90deg); }

.store_wrap .btn_icon.view_toggle.on:after { content: none; }

.store_category_1depth { display: none; overflow-y: auto; max-height: 120px; border-top: 1px solid #f0f0f0; }

.store_category_1depth.on { display: block; }

.store_category_1depth .category_list { overflow: hidden; padding: 8px 0 13px 10px; zoom: 1; }

.store_category_1depth .category_item { float: left; width: 16.6666%; }

.store_category_1depth .category_link { display: inline-block; padding: 4px 10px 6px; color: #666; }

.store_category_1depth .category_link.selected { color: #ff0038; }

.store_category_2depth { display: none; position: relative; height: 46px; border-top: 1px solid #f0f0f0; background-color: #f4f4f4; overflow: hidden; }

.store_category_2depth.on { display: block; }

.store_category_2depth .category_list { height: 50px; padding: 9px 0 9px 36px; white-space: nowrap; cursor: ew-resize; overflow-x: auto; overflow-y: hidden; -webkit-overflow-scrolling: touch; }

.store_category_2depth .category_item { display: inline-block; *display: inline; zoom: 1; padding-right: 36px; font-size: 12px; line-height: 18px; }

.store_category_2depth .category_item + .category_item { margin-left: -36px; }

.store_category_2depth .category_link { display: block; padding: 4px 10px; border: 1px solid #f4f4f4; border-radius: 14px; color: #666; transition: 0.15s ease-out; }

.store_category_2depth .category_link.selected { border-color: #ff0038; background-color: #fcfcfc; color: #ff0038; outline: none; }

.store_category_2depth .category_link.selected:focus, .store_category_2depth .category_link.selected:active, .store_category_2depth .category_link.selected:hover { text-decoration: none; }

.store_category_2depth .prev { position: absolute; left: 0; top: 0; width: 32px; height: 46px; background: url("/img/mini/home/icon_category_arrow.png") no-repeat 0 0; }

.store_category_2depth .next { position: absolute; right: 0; top: 0; width: 32px; height: 46px; background: url("/img/mini/home/icon_category_arrow.png") no-repeat 100% 0; }

.store_category_selected { display: none; position: relative; height: 46px; padding-left: 120px; border-top: 1px solid #f0f0f0; background-color: #f4f4f4; overflow: hidden; background: #fafafa; }

.store_category_selected.on { display: block; }

.store_category_selected:before { position: absolute; top: 50%; left: 86px; width: 1px; height: 18px; margin-top: -8px; background: #ccc; content: ''; }

.store_category_selected .prev { position: absolute; top: 0; left: 88px; width: 32px; height: 46px; background: #fafafa url("/img/mini/home/icon_category_arrow.png") no-repeat 0 -50px; }

.store_category_selected .next { position: absolute; right: 0; top: 0; width: 32px; height: 46px; background: #fafafa url("/img/mini/home/icon_category_arrow.png") no-repeat -32px -50px; }

.store_category_selected .selected_lst { position: relative; height: 50px; padding: 9px 30px 7px 0; font-size: 0; white-space: nowrap; cursor: ew-resize; overflow-x: auto; overflow-y: hidden; -webkit-overflow-scrolling: touch; }

.store_category_selected .category_item { display: inline-block; *display: inline; position: relative; zoom: 1; margin-right: 8px; padding: 0 28px 0 12px; border: 1px solid #e4e4e4; border-radius: 30px; background: #fff; font-size: 12px; color: #333; line-height: 28px; }

.store_category_selected .btn_category_clear { position: absolute; top: 0; right: 0; bottom: 0; width: 28px; }

.store_category_selected .btn_category_clear .icon_delete { display: inline-block; overflow: hidden; width: 16px; height: 16px; margin: -1px 0 1px; background: url("/img/mini/home/icon_delete.png") no-repeat; line-height: 200px; vertical-align: middle; }

.store_category_selected .btn_all_clear { position: absolute; top: 9px; left: 16px; height: 30px; }

.store_category_selected .btn_all_clear .icon_clear { display: inline-block; width: 15px; height: 15px; margin: -1px 6px 1px 0; background: url("/img/mini/home/icon_clear.png") no-repeat; vertical-align: middle; }

.store_filter_title { margin-top: 20px; padding: 15px 0 18px; font-weight: normal; font-size: 18px; line-height: 24px; }

.store_content .store_filter { overflow: hidden; position: relative; margin-top: 0; margin-bottom: -12px; padding: 15px 20px; border: 1px solid #cdd1d4; background-color: #d6dadd; zoom: 1; }

.store_content .store_filter .filter_area1, .store_content .store_filter .filter_area2, .store_content .store_filter .filter_area3 { float: left; position: relative; margin-right: 15px; padding-right: 21px; }

.store_content .store_filter .filter_area1:after { position: absolute; right: 0; top: 6px; width: 1px; height: 18px; background-color: #999; content: ''; }

.store_content .store_filter input[type="checkbox"] { margin: -1px 0 1px; vertical-align: middle; }

.store_content .store_filter input[type="text"] { width: 138px; height: 18px; padding: 5px 0 7px; border: 0; text-indent: 10px; vertical-align: middle; }

.store_content .store_filter .filter_area1 input[type="text"] { width: 382px; }

.store_content .store_filter button { position: absolute; right: 20px; top: 15px; width: 100px; height: 30px; padding: 5px 0 7px; border: 0; background-color: #666; color: #fff; vertical-align: middle; }

.store_content .store_filter span { display: inline-block; padding: 4px 10px 6px; color: #666; vertical-align: middle; }

.store_content .store_filter label { display: inline-block; padding: 4px 15px 6px 3px; color: #111; vertical-align: middle; }

.c-button { position: relative; display: inline-block; box-sizing: border-box; vertical-align: middle; text-align: center; border: 1px solid transparent; }

.c-button[type="button"], .c-button[type="submit"] { border: 1px solid transparent; }

.c-button--bold { font-weight: bold; }

.c-button--text-left { text-align: left; }

.c-button:disabled, .c-button--disabled { cursor: pointer; }

.c-button:hover { text-decoration: none; }

.c-button--primary { color: #fff; background-color: #ff0038; border-color: #ff0038; }

.c-button--primary[type="button"], .c-button--primary[type="submit"] { color: #fff; background-color: #ff0038; border-color: #ff0038; }

.c-button--primary::after { border-color: #fff; }

.c-button--primary::after[type="button"], .c-button--primary::after[type="submit"] { border-color: #fff; }

.c-button--primary:disabled, .c-button--primary.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--primary:disabled[type="button"], .c-button--primary:disabled[type="submit"], .c-button--primary.c-button--disabled[type="button"], .c-button--primary.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--alert { color: #ff0038; background-color: #fff; border-color: #ff0038; }

.c-button--alert[type="button"], .c-button--alert[type="submit"] { color: #ff0038; background-color: #fff; border-color: #ff0038; }

.c-button--alert::after { border-color: #ff0038; }

.c-button--alert::after[type="button"], .c-button--alert::after[type="submit"] { border-color: #ff0038; }

.c-button--alert:disabled, .c-button--alert.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--alert:disabled[type="button"], .c-button--alert:disabled[type="submit"], .c-button--alert.c-button--disabled[type="button"], .c-button--alert.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--normal { color: #111; background-color: #fff; border-color: #ccc; }

.c-button--normal[type="button"], .c-button--normal[type="submit"] { color: #111; background-color: #fff; border-color: #ccc; }

.c-button--normal::after { border-color: #ccc; }

.c-button--normal::after[type="button"], .c-button--normal::after[type="submit"] { border-color: #ccc; }

.c-button--normal:disabled, .c-button--normal.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--normal:disabled[type="button"], .c-button--normal:disabled[type="submit"], .c-button--normal.c-button--disabled[type="button"], .c-button--normal.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--secondary { color: #0b83e6; background-color: #fff; border-color: #0b83e6; }

.c-button--secondary[type="button"], .c-button--secondary[type="submit"] { color: #0b83e6; background-color: #fff; border-color: #0b83e6; }

.c-button--secondary::after { border-color: #0b83e6; }

.c-button--secondary::after[type="button"], .c-button--secondary::after[type="submit"] { border-color: #0b83e6; }

.c-button--secondary:disabled, .c-button--secondary.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--secondary:disabled[type="button"], .c-button--secondary:disabled[type="submit"], .c-button--secondary.c-button--disabled[type="button"], .c-button--secondary.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--black { color: #fff; background-color: #333; border-color: #333; }

.c-button--black[type="button"], .c-button--black[type="submit"] { color: #fff; background-color: #333; border-color: #333; }

.c-button--black::after { border-color: #fff; }

.c-button--black::after[type="button"], .c-button--black::after[type="submit"] { border-color: #fff; }

.c-button--black:disabled, .c-button--black.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--black:disabled[type="button"], .c-button--black:disabled[type="submit"], .c-button--black.c-button--disabled[type="button"], .c-button--black.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button-luxy { position: relative; display: inline-block; box-sizing: border-box; vertical-align: middle; text-align: center; border: 1px solid transparent; }

.c-button-luxy[type="button"], .c-button-luxy[type="submit"] { border: 1px solid transparent; }

.c-button-luxy--bold { font-weight: bold; }

.c-button-luxy--text-left { text-align: left; }

.c-button-luxy:disabled, .c-button-luxy--disabled { cursor: pointer; }

.c-button-luxy:hover { text-decoration: none; }

.c-button-luxy--primary { color: #fff; background-color: #0f0fd9; border-color: #0f0fd9; }

.c-button-luxy--primary[type="button"], .c-button-luxy--primary[type="submit"] { color: #fff; background-color: #0f0fd9; border-color: #0f0fd9; }

.c-button-luxy--primary::after { border-color: #0f0fd9; }

.c-button-luxy--primary::after[type="button"], .c-button-luxy--primary::after[type="submit"] { border-color: #0f0fd9; }

.c-button-luxy--primary:disabled, .c-button-luxy--primary.c-button-luxy--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button-luxy--primary:disabled[type="button"], .c-button-luxy--primary:disabled[type="submit"], .c-button-luxy--primary.c-button-luxy--disabled[type="button"], .c-button-luxy--primary.c-button-luxy--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

/* MPSR-51108 delete */
.store_product_heading { padding: 9px 0 11px; border-bottom: 2px solid #111; font-weight: normal; font-size: 15px; }

/* // MPSR-51108 delete */
.store_search_title { margin-top: 30px; margin-bottom: -30px; padding: 9px 0; font-weight: normal; font-size: 22px; line-height: 32px; }

.store_search_title strong { font-weight: normal; color: #ff0038; }

.store_product_top { padding: 9px 0 14px; border-bottom: 2px solid #111; zoom: 1; }

.store_product_top:after { display: block; clear: both; content: ""; }

.store_product_top .store_product_heading { float: left; padding: 0; border: 0; }

.store_product_top .store_chk_wrap { float: left; margin-left: 10px; }

.store_product_sorting { position: absolute; right: 0; top: 11px; color: #999; }

.title_lg { display: block; padding: 10px 0 5px; font-weight: normal; font-size: 22px; line-height: 1.3; }

.title_lg .text_sub { display: inline-block; margin-left: 3px; vertical-align: 2px; color: #666; font-size: 14px; }

.product_list_header { position: relative; display: table; width: 100%; min-height: 38px; border-bottom: 2px solid #111; }

.product_list_header .title { display: table-cell; padding: 9px 0 11px; vertical-align: top; font-weight: normal; font-size: 15px; }

.product_list_header .side { text-align: right; vertical-align: top; }

.product_list_header .store_product_top { display: table-cell; vertical-align: top; border-bottom: none; }

.product_list_header .dropdown_wrap { margin-left: 16px; vertical-align: top; }

.product_list_header.header_style_1 { padding: 7px 0 6px; }

.product_list_header.header_style_1 .title { font-size: 22px; }

.product_list_header.header_style_1 .text_sub { display: inline-block; margin-left: 3px; vertical-align: 2px; color: #666; font-size: 14px; }

.product_list_header.header_style_1 .check_style_filter { padding-top: 16px; }

.check_style_filter { display: inline-block; padding: 9px 0; vertical-align: top; font-size: 13px; cursor: pointer; }

.check_style_filter > input { position: absolute; width: 0; height: 0; opacity: 0; }

.check_style_filter > label { display: block; color: #111; cursor: pointer; }

.check_style_filter > label:before { display: inline-block; width: 5px; height: 9px; margin: -5px 6px 0 3px; border: 1px solid #ccc; border-width: 0px 1px 1px 0; transform: rotate(45deg); vertical-align: middle; content: ""; }

.check_style_filter input:checked + label:before { border-color: #ff0038; }

.check_style_filter input.focus-visible + label { margin: 0 -2px; padding: 0 2px; box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5); }

.check_style_filter + .check_style_filter { margin-left: 9px; }

/* 검색 스타일 복사 (ctgr_sch_v2.scss) */
.help_box { position: relative; display: inline-block; height: 100%; }

.help_box.on { z-index: 2; }

.help_box.on .layer_def_b3, .help_box.on .layer_def_b { display: block; }

.help_box .layer_def_b3, .help_box .layer_def_b { display: none; top: 26px; left: 50%; width: 250px; margin-left: -125px; font-family: "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; line-height: 20px; letter-spacing: 0; }

.help_box .layer_def_b3 .layer_conts, .help_box .layer_def_b .layer_conts { padding: 7px 4px 6px 9px; }

.help_box .layer_def_b3 .layer_tit, .help_box .layer_def_b .layer_tit { display: block; }

.help_box .layer_def_b { top: 22px; left: auto; right: 0; }

.help_box .btn_help { width: 20px; height: 100%; vertical-align: middle; }

.help_box .btn_help .sp_search2 { display: inline-block; overflow: hidden; width: 14px; height: 14px; margin: -1px 0 1px; vertical-align: middle; background: url("/img/search/v2/sp_search2.png") no-repeat -37px -39px; font-size: 1px; line-height: 200px; white-space: nowrap; }

.help_box .btn_help.btn_beta { position: relative; width: 49px; margin: -2px 0 2px; }

.help_box .btn_help.btn_beta .sp_search2 { width: 49px; height: 22px; background-position: -42px -88px; }

/* // 검색 스타일 복사 (ctgr_sch_v2.scss) */
.store_product_sorting_item { display: inline-block; }

.store_product_sorting_item + .store_product_sorting_item { padding-left: 7px; background: url("/img/mini/home/icon_sorting_dot.png") no-repeat 1px 50%; }

.store_product_sorting a { color: #666; }

.store_product_sorting a:focus, .store_product_sorting a:active, .store_product_sorting a:hover { text-decoration: underline; }

.store_product_sorting a.on { display: block; padding-left: 24px; background: url("/img/mini/home/icon_sorting_on.png") no-repeat 2px 0; color: #ff0038; text-decoration: none; }

.store_product_wrap { position: relative; margin-top: 30px; }

.store_product { overflow: hidden; position: relative; min-height: 400px; margin-top: 16px; }

.store_product.with_button_type .s_store_result { margin-top: -25px; }

.store_product.with_button_type .view_more_bar { position: absolute; bottom: 0; left: 0; width: 100%; margin-top: 0; }

.store_product_filter { display: flex; justify-content: space-between; }

.store_product_filter .c-button { width: auto; height: 31px; padding: 0 8px; min-width: 34px; font-size: 13px; line-height: calc(31px - 2px); border-radius: 4px; }

.store_product_filter .c-button--item { margin-right: 6px; color: #111; border-color: #ddd !important; background-color: #fafafa; }

.store_product_filter .c-button--item .delete { display: none; }

.store_product_filter .c-button--selected { padding-right: 24px; font-weight: bold; background-color: #ff0038; border-color: #ff0038 !important; color: #fff; }

.store_product_filter .c-button--selected .delete { display: block; position: absolute; top: 6px; right: 4px; padding: 3px; width: 10px; height: 10px; overflow: hidden; line-height: 200px; line-height: 200px; }

.store_product_filter .c-button--selected .delete:before, .store_product_filter .c-button--selected .delete:after { position: absolute; top: 50%; left: 50%; width: 13px; height: 1px; background-color: #f7ced7; content: ""; }

.store_product_filter .c-button--selected .delete:before { -webkit-transform: translate(-50%, -50%) rotate(45deg); transform: translate(-50%, -50%) rotate(45deg); }

.store_product_filter .c-button--selected .delete:after { -webkit-transform: translate(-50%, -50%) rotate(135deg); transform: translate(-50%, -50%) rotate(135deg); }

.store_product_filter .filter_box { display: flex; font-size: 0; line-height: 0; }

.store_product_filter .delivery_filter { position: relative; }

.store_product_filter .delivery_filter + .promotion_filter::before { content: ""; display: inline-block; margin: 2px 10px 0 4px; width: 1px; height: 28px; background-color: #ddd; vertical-align: top; }

.store_product_filter .side { height: 16px; margin-top: 6px; }

.store_product_filter .side li { position: relative; float: left; }

.store_product_filter .side li:last-child::before { content: ""; float: left; margin: 6px 7px; width: 2px; height: 2px; border-radius: 4px; background-color: #ccc; }

.store_product_filter .side .help_box { position: absolute; top: -4px; left: 0; }

.store_product_filter .side .help_box .help { display: inline-block; overflow: hidden; width: 14px; height: 14px; margin: -1px 0 1px; vertical-align: middle; font-size: 1px; line-height: 200px; background-image: url("/img/mini/sprites/sp_mini_2x_2024719_13141.png"); background-position: -64px -19px; width: 14px; height: 14px; background-size: 78px 59px; }

.store_product_filter .side .c-button { float: left; }

.store_product_filter .side .c-button--bold { margin-left: 17px; }

.store_product_filter .side .c-button--item { margin-right: 0; width: auto; height: 16px; padding: 0 3px; min-width: 34px; font-size: 13px; line-height: calc(16px - 2px); border-radius: 0; background-color: transparent; border: 0 none !important; color: #000; }

.store_product_list { display: flex !important; flex-wrap: wrap; width: calc(100% + 16px); margin-left: calc(-16px / 2); }

.store_product_item { flex-grow: 0; flex-shrink: 1; width: 33.333%; padding: 8px 0; }

.store_product_link { display: block; position: relative; margin: 8px; height: 100%; background-color: #fff; zoom: 1; border-bottom: 1px solid #c6cacc; }

.store_product_link:hover .store_product_name, .store_product_link:active .store_product_name, .store_product_link:focus .store_product_name { text-decoration: underline; }

.store_product_thumb_wrap { position: relative; padding-top: 100%; }

.store_product_thumb { position: absolute; left: 0; right: 0; top: 0; bottom: 0; max-width: 100%; height: 100%; margin: auto; }

.store_product_info { position: relative; padding: 12px 15px 43px; zoom: 1; }

.store_product_info * { font-family: "Lato all", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.store_product_info .consult_info { display: block; position: static; margin-top: 19px; text-align: left; }

.store_product_info .consult_info .consult_txt { color: #0b83e6; font-size: 14px; font-weight: normal; }

.store_product_name { display: block; height: 20px; font-weight: normal; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.store_product_price_area { display: block; height: 34px; margin-top: 4px; }

.store_product_old_price { display: block; height: 15px; color: #999; line-height: 15px; }

.store_product_old_price em { display: inline-block; padding-right: 1px; font-size: 14px; line-height: 15px; text-decoration: line-through; vertical-align: baseline; }

.store_product_old_price .price_unit { display: inline-block; font-size: 12px; text-decoration: line-through; }

.store_product_new_price { display: block; height: 19px; color: #111; line-height: 19px; }

.store_product_new_price em { display: inline-block; padding-right: 1px; font-weight: bold; font-size: 18px; line-height: 19px; vertical-align: top; }

.store_product_new_price .price_unit { display: inline-block; font-size: 14px; }

.store_product_benefit { zoom: 1; position: absolute; left: 3px; bottom: 15px; overflow: hidden; height: 14px; width: inherit; color: #666; font-family: "Lato all", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 12px; line-height: 14px; }

.store_product_benefit:after { display: block; content: ""; clear: both; }

.store_product_benefit em.uiq_sale, .store_product_benefit span.uiq_sale { position: relative; float: left; display: block; color: #666; word-spacing: 0; padding: 0 0 0 12px; }

.store_product_benefit em.uiq_sale .point, .store_product_benefit span.uiq_sale .point { color: #ff0038; }

.store_product_benefit em.uiq_sale .point2, .store_product_benefit span.uiq_sale .point2 { color: #0b83e6; }

.store_product_benefit em.uiq_sale .lsn, .store_product_benefit span.uiq_sale .lsn { letter-spacing: 0; }

.store_product_benefit em.uiq_sale:not(:first-child)::before, .store_product_benefit span.uiq_sale:not(:first-child)::before { position: absolute; left: 5px; top: 6px; width: 2px; height: 2px; background-color: #ccc; border-radius: 50%; content: ""; }

.store_recommand_product_wrap { position: relative; margin-top: 40px; /* MPSR-51108 delete */ /* // MPSR-51108 delete */ }

.store_recommand_product_wrap .point { font-weight: normal; color: #ff0038; }

.store_recommand_product_wrap h3 { font-weight: normal; padding-bottom: 12px; font-size: 20px; line-height: 28px; }

.store_recommand_product_wrap .view_more { position: absolute; right: 0; top: 9px; padding-right: 10px; background: url("/img/mini/home/icon_view_all_arrow.png") no-repeat 100% 0; font-size: 12px; line-height: 18px; color: #666; }

.store_recommand_product { position: relative; height: 272px; background-color: #fff; overflow: hidden; border-bottom: 1px solid #c6cacc; }

.store_recommand_product_list { position: relative; height: 300px; padding: 16px 0 16px 16px; font-size: 0; white-space: nowrap; overflow-x: auto; overflow-y: hidden; -webkit-overflow-scrolling: touch; }

.store_recommand_product_item { display: inline-block; *display: inline; zoom: 1; padding-right: 16px; vertical-align: top; }

.store_recommand_product_item .consult_info { display: block; left: 14px; bottom: 8px; top: auto; text-align: left; }

.store_recommand_product_link { display: inline-block; overflow: hidden; position: relative; width: 240px; padding-top: 100%; vertical-align: top; }

.recommand_product_thumb { position: absolute; top: 0; right: 0; bottom: 0; left: 0; width: 100%; min-height: 100%; margin: auto; }

.recommand_product_mask { position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: url("/img/mini/home/thumb_mask.png") no-repeat 50% 50%; }

.recommand_product_price_area { position: absolute; left: 14px; bottom: 14px; color: #fff; }

.recommand_product_price_area .old_price { position: absolute; bottom: 18px; font-size: 12px; line-height: 16px; text-decoration: line-through; }

.recommand_product_price_area .old_price em { font-family: sans-serif; }

.recommand_product_price_area .new_price em { display: inline-block; padding-top: 3px; font-weight: bold; font-size: 15px; line-height: 17px; font-family: sans-serif; vertical-align: top; }

.recommand_product_price_area .new_price .price_unit { display: inline-block; font-size: 14px; line-height: 20px; vertical-align: top; }

.delivery_header { overflow: hidden; zoom: 1; margin-top: 30px; }

.delivery_header .delivery_title { float: left; margin-right: 20px; padding: 4px 0; font-weight: normal; font-size: 22px; line-height: 32px; }

.delivery_header .delivery_explain { float: left; padding: 13px 0 7px; color: #666; }

.delivery_header .delivery_explain em { color: #ff0038; }

.delivery_product { overflow: hidden; margin-top: 20px; zoom: 1; border-bottom: 1px solid #c6cacc; }

.delivery_title { float: left; padding: 4px 0; font-weight: normal; font-size: 22px; line-height: 32px; }

.selected_product { overflow: hidden; float: left; width: 300px; background-color: #788394; }

.selected_product_title { padding: 19px 0 13px; font-weight: normal; color: #fff; text-align: center; }

.bundle_product_title { padding: 19px 30px 13px; font-weight: normal; color: #111; }

.bundle_product_wrap { position: relative; height: 421px; background-color: #fff; overflow: hidden; zoom: 1; }

.bundle_product { overflow: hidden; overflow-x: auto; position: relative; height: 400px; -webkit-overflow-scrolling: touch; }

.bundle_product_list { overflow: hidden; padding: 0 22px; }

.bundle_product_item { overflow: hidden; float: left; width: 256px; }

.bundle_product_link { display: block; position: relative; margin: 8px; zoom: 1; }

.bundle_product_link:focus .bundle_product_name, .bundle_product_link:active .bundle_product_name, .bundle_product_link:hover .bundle_product_name { text-decoration: underline; }

.bundle_product_thumb_wrap { position: relative; padding-top: 100%; }

.bundle_product_thumb { position: absolute; left: 0; top: 0; width: 100%; height: 100%; }

.bundle_product_info { padding: 12px 15px 43px; }

.bundle_product_name { display: block; height: 20px; font-weight: normal; letter-spacing: -1px; overflow: hidden; white-space: nowrap; /*text-overflow: ellipsis;*/ }

.bundle_product_price_area { display: block; height: 34px; margin-top: 4px; }

.bundle_product_old_price { display: block; height: 15px; color: #999; }

.bundle_product_old_price em { display: inline-block; padding-right: 1px; font-size: 14px; font-family: sans-serif; line-height: 15px; letter-spacing: -1px; text-decoration: line-through; vertical-align: top; }

.bundle_product_old_price .price_unit { display: inline-block; font-size: 12px; line-height: 15px; vertical-align: top; text-decoration: line-through; }

.bundle_product_new_price { display: block; color: #111; }

.bundle_product_new_price em { display: inline-block; padding-right: 1px; font-weight: bold; font-size: 18px; line-height: 19px; font-family: sans-serif; letter-spacing: -1px; vertical-align: top; }

.bundle_product_new_price .price_unit { display: inline-block; font-size: 14px; line-height: 19px; vertical-align: top; }

.bundle_product_benefit { position: absolute; left: 15px; bottom: 15px; font-size: 14px; line-height: 18px; color: #666; word-spacing: 4px; white-space: nowrap; }

.bundle_product_benefit span { word-spacing: 0; }

.selected_product .bundle_product_link, .selected_product .bundle_product_name, .selected_product .bundle_product_old_price, .selected_product .bundle_product_new_price, .selected_product .bundle_product_benefit { color: #fff; }

.store_module_wrap.wrap_type2 { padding: 20px 0; background-color: #fff; border-bottom: 1px solid #c6cacc; }

.store_module_wrap.wrap_type2 .store_title { padding: 8px 80px 21px 20px; }

.store_module_wrap.wrap_type2 .btn_more { top: 35px; right: 20px; }

.store_content_nothing_wrap { position: absolute; left: 0; right: 0; top: 50%; margin: -30px auto 0; }

.store_nothing_title { font-weight: normal; font-size: 20px; line-height: 28px; color: #111; text-align: center; }

.store_nothing_title strong { font-weight: normal; color: #ff0038; }

.store_nothing_explain { margin-top: 9px; font-size: 18px; line-height: 24px; color: #666; text-align: center; }

.s_store_result { display: block; position: absolute; width: 100%; top: 50%; transform: translateY(-50%); text-align: center; }

.s_store_result p { font-size: 18px; line-height: 24px; color: #666; }

.s_store_result p > strong { display: block; margin: 0 auto 13px; color: #111; font-size: 20px; font-weight: normal; }

.s_store_result p > strong i { font-style: normal; color: #ff0038; }

.store_best_review_main { overflow: hidden; position: relative; height: 424px; background-color: #fff; border-bottom: 1px solid #c6cacc; }

.store_best_review_main .store_best_review_list { height: 420px; margin: 0; padding: 16px 0 16px 16px; white-space: nowrap; overflow-x: auto; overflow-y: hidden; -webkit-overflow-scrolling: touch; }

.store_best_review_main .best_review_cont { width: 240px; padding-right: 16px; border-bottom: 0; }

.store_best_review_main .store_review_content { position: relative; width: 238px; border: 1px solid #f0f0f0; }

.store_best_review_main .store_review_area .review_box, .store_best_review_main .review_type_adult .certify_step { overflow: hidden; height: 54px; }

.store_best_review_main .review_img_wrap { position: relative; width: 238px; padding-top: 100%; }

.store_best_review_main .review_img_wrap img { position: absolute; top: 50%; left: 50%; width: auto; min-width: 100%; min-height: 100%; margin: auto; transform: translate(-50%, -50%); *top: 0; *right: 0; *bottom: 0; *left: 0; *min-height: 238px; }

.store_best_review_main .review_type_video .review_img_wrap:after { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url("/img/mini/home/opacity30.png") repeat; content: ''; }

.store_best_review_main .review_type_video .review_type { top: 88px; }

.store_best_review_main .order_product_box { padding: 9px 8px 4px; border: 1px solid #f0f0f0; border-top: 0; background: #f7f7f7; }

.store_best_review_main .store_review_area { padding-top: 10px; }

.store_best_review_main .store_review_area .review_option { top: 10px; max-width: 120px; }

.store_best_review_main .btn_view_prev, .store_best_review_main .btn_view_next { z-index: 11; }

@media \0screen { .store_best_review_main .review_img_wrap img { top: 0; right: 0; bottom: 0; left: 0; } }

.store_best_review_list { position: relative; margin: 16px 0; font-size: 0; }

.best_review_cont { display: inline-block; *display: inline; zoom: 1; background: #fff; vertical-align: top; border-bottom: 1px solid #c6cacc; }

.best_review_cont .review_type { position: absolute; top: 16px; left: 16px; z-index: 1; }

.best_review_cont .store_review_content:hover .hover_view { display: block; position: absolute; top: 0; left: 0; z-index: 10; width: 100%; height: 100%; }

.best_review_cont .store_review_content:hover .review_txt, .best_review_cont .store_review_content:hover .review_box .more_review { text-decoration: underline; }

.review_type_default, .review_type_experience .review_type, .review_type_evaluate .review_type { padding: 0 8px; border: 1px solid #fff; border-radius: 20px; background: rgba(0, 0, 0, 0.3); font-size: 12px; font-weight: normal; color: #fff; line-height: 18px; }

.store_review_content { display: inline-block; position: relative; width: 316px; vertical-align: top; }

.store_review_content .review_link { overflow: hidden; position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 10; width: 100%; background: url("/img/mini/home/bg_transparent.png") repeat 0 0; color: transparent; font-size: 1px; line-height: 400px; }

.review_img_wrap { display: block; overflow: hidden; position: relative; width: 316px; max-height: 316px; text-align: center; /* MPSR-51108 삭제 */ /* // MPSR-51108 삭제 */ }

.review_img_wrap img { width: 100%; vertical-align: top; }

.review_img_wrap .icon_multi { position: absolute; right: 8px; bottom: 8px; width: 36px; height: 36px; background: url("/img/mini/home/icon_photo.png") no-repeat; }

.review_img_wrap .hover_view { display: none; }

.review_img_wrap .hover_view .hover_txt { position: absolute; top: 50%; right: 0; left: 0; margin-top: -33px; font-size: 16px; font-weight: normal; color: #fff; text-align: center; }

.review_img_wrap .in_bg { display: block; width: 100%; height: 100%; background: #000; text-align: center; opacity: .3; filter: alpha(opacity=30); }

.review_img_wrap .hover_view .icon_detail { display: block; width: 38px; height: 38px; margin: 0 auto 10px; background: url("/img/mini/home/icon_detail.png") no-repeat; }

.review_img_wrap:hover .hover_view { display: block; position: absolute; top: 0; left: 0; z-index: 10; width: 100%; height: 100%; }

.store_review_area { position: relative; padding: 16px 0 9px; white-space: normal; /* MPSR-51108 삭제 */ /* // MPSR-51108 삭제 */ }

.store_review_area .review_box { position: relative; }

.store_review_area .more_review { display: block; font-size: 12px; font-weight: normal; color: #fd7985; text-decoration: underline; text-align: right; }

.store_review_area a .review_txt:hover, .store_review_area .review_box:hover .more_review { text-decoration: underline; }

.store_review_area .review_txt { display: -webkit-box; overflow: hidden; -webkit-line-clamp: 10; -webkit-box-orient: vertical; white-space: initial; overflow: hidden; max-height: 182px; padding: 0 16px; font-size: 14px; color: #333; line-height: 18px; }

.store_review_area .star_box { margin: 0 16px 7px; }

.store_review_area .review_option { overflow: hidden; position: absolute; top: 16px; right: 16px; max-width: 190px; font-size: 12px; color: #a3a3a3; line-height: 15px; white-space: nowrap; text-overflow: ellipsis; }

.store_review_area .reviewer { display: block; margin-top: 9px; padding: 0 16px; font-size: 12px; color: #999; }

.review_btnbx { position: absolute; right: 16px; bottom: 10px; z-index: 11; }

.review_btnbx button { font-size: 12px; color: #333; }

.review_btnbx .ico_review { display: inline-block; overflow: hidden; width: 11px; height: 13px; margin: -1px 4px 1px 0; color: transparent; font-size: 1px; line-height: 200px; vertical-align: middle; }

.review_btnbx .btn_good .ico_review { background: url("/img/mini/home/icon_like.png") no-repeat; }

.review_btnbx .btn_bad .ico_review { background: url("/img/mini/home/icon_bad.png") no-repeat; }

.review_btnbx .bar { display: inline-block; width: 1px; height: 10px; margin: 0 8px; background: #ccc; vertical-align: middle; }

.order_product_box { overflow: hidden; position: relative; display: block; border-top: 1px solid #f0f0f0; padding: 12px 50px 6px 8px; }

.order_product_box .order_img_wrap, .order_product_box .order_img { float: left; overflow: hidden; position: relative; width: 36px; height: 36px; margin-right: 10px; border: 1px solid #e4e4e4; border-radius: 50%; }

.order_product_box .order_img_wrap img, .order_product_box .order_img img { position: absolute; top: 0; right: 0; left: 0; bottom: 0; width: 100%; height: 100%; }

.order_product_box .order_product_info { display: block; overflow: hidden; margin: 1px 0 -1px; color: #333; }

.order_product_box .product_name { display: block; overflow: hidden; max-width: 100%; font-size: 12px; font-weight: normal; white-space: nowrap; word-break: break-all; text-overflow: ellipsis; }

.order_product_box .new_price { display: block; line-height: 16px; }

.order_product_box .new_price em { display: inline-block; font-size: 14px; font-weight: bold; vertical-align: top; }

.order_product_box .new_price .price_unit { display: inline-block; font-size: 12px; vertical-align: top; }

.order_product_box .btn_zzim { position: absolute; top: 50%; right: 0; width: 50px; height: 48px; margin-top: -24px; }

.order_product_box .btn_zzim .ico_zzim { display: inline-block; overflow: hidden; width: 18px; height: 16px; background: url("/img/mini/home/sp_zzim.png") no-repeat; line-height: 200px; vertical-align: middle; }

.order_product_box .btn_zzim.on .ico_zzim { background-position: -22px 0; }

.order_product_box .product_link { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: url("/img/mini/home/bg_transparent.png") repeat 0 0; }

.review_type_video .review_type { overflow: hidden; top: 123px; left: 50%; width: 64px; height: 64px; margin-left: -32px; line-height: 200px; background: url("/img/mini/home/icon_play.png"); }

.review_type_adult { /* MPSR-61501 오픈 후 삭제 */ /* // MPSR-61501 오픈 후 삭제 */ }

.review_type_adult .review_img_wrap { position: relative; padding-top: 100%; }

.review_type_adult .review_img_wrap img { position: absolute; top: 0; right: 0; left: 0; bottom: 0; width: 100%; transform: none; }

.review_type_adult .star_box .star_point { width: 0 !important; }

.review_type_adult .review_option, .review_type_adult .review_btnbx { display: none; }

.review_type_adult .review_box { position: relative; display: table; width: 100%; min-height: 54px; }

.review_type_adult .certify_step { height: 64px; text-align: center; }

.review_type_adult .certify_step:before { display: inline-block; height: 100%; vertical-align: middle; content: ''; }

.review_type_adult .certify_step .txt_adult, .review_type_adult .certify_step .txt_adult2 { display: inline-block; font-size: 14px; color: #333; line-height: 18px; }

.review_type_adult .order_img_wrap { width: 38px; height: 38px; border: 0; background: url("/img/mini/home/icon_adult_38.png") no-repeat; }

.review_type_adult .text_info { display: table-cell; padding: 0 8px; text-align: center; vertical-align: middle; color: #111; font-size: 13px; }

.review_type_adult .text_info .text_link { color: #0b83e6; text-decoration: underline; }

.store_review_checkbox { position: absolute; top: 11px; right: 0; font-size: 12px; color: #666; }

.store_review_wrap { position: relative; margin-top: 30px; }

.store_review_wrap .best_review_cont { position: absolute; width: 316px; margin-bottom: 16px; }

.store_review_wrap .best_review_cont:first-child { top: 0; left: 0; }

.store_review_wrap .best_review_cont:nth-child(2) { top: 0; left: 332px; }

.store_review_wrap .best_review_cont:nth-child(3) { top: 0; left: 664px; }

.store_review_wrap .review_type_video .review_type { top: 272px; right: 8px; left: auto; width: 36px; height: 36px; margin-left: 0; background-position: 0 -68px; }

/* MPSR-51108 삭제 */
.store_review_header { position: relative; min-height: 20px; padding: 9px 0 14px; border-bottom: 2px solid #111; }

.store_review_header .store_product_heading { padding: 0; border: 0; }

/* // MPSR-51108 삭제 */
.ly_wrap { /* MPSR-61501 오픈 후 삭제 */ /* // MPSR-61501 오픈 후 삭제 */ }

.ly_wrap .order_product_box { display: inline-block; max-width: 94%; padding: 0; border-top: 0; }

.ly_wrap .order_product_box .order_img_wrap { float: left; overflow: hidden; position: relative; width: 50px; height: 50px; margin-right: 10px; border: 1px solid #e4e4e4; border-radius: 50%; }

.ly_wrap .order_product_box .order_product_info { margin: 2px 0 -2px; }

.ly_wrap .order_product_box .product_name { font-size: 14px; }

.ly_wrap .order_product_box .new_price { margin-top: 8px; line-height: 18px; }

.ly_wrap .order_product_box .new_price em { font-size: 16px; }

.ly_wrap .order_product_box .price_unit { margin: -1px 0 1px; font-size: 14px; }

.ly_wrap .review_simple_info { position: relative; margin-top: 12px; }

.ly_wrap .review_simple_info .star_box { display: inline-block; margin: -1px 0 1px; vertical-align: top; }

.ly_wrap .review_simple_info .reviewer { display: inline-block; position: relative; margin-left: 17px; font-size: 12px; color: #999; vertical-align: top; }

.ly_wrap .review_simple_info .reviewer:before { position: absolute; top: 50%; left: -9px; width: 1px; height: 13px; margin-top: -8px; background: #ccc; content: ''; }

.ly_wrap .review_simple_info .review_option { margin-top: 6px; font-size: 12px; color: #999; }

.ly_wrap .review_simple_info .review_option .option { display: inline-block; width: 25px; margin: 1px 2px -1px 0; border: 1px solid #e2e2e2; background: #fff; font-size: 10px; line-height: 16px; text-align: center; }

.ly_wrap .btn_lk { position: relative; z-index: 2; font-weight: bold; color: #5879bf; text-decoration: underline; }

.ly_wrap .btn_lk:hover, .ly_wrap .btn_lk:active { text-decoration: underline; }

.ly_wrap .review_btnbx { top: 0; right: 0; bottom: auto; }

.ly_wrap .review_box { text-align: center; }

.ly_wrap .review_box img { max-width: 100%; margin: 7px 0; }

.ly_wrap .review_box img:first-child { margin-top: 0; }

.ly_wrap .review_box .review_txt, .ly_wrap .review_box p { margin: 7px 0; font-size: 14px; color: #333; line-height: 18px; text-align: left; }

.ly_wrap .review_box .review_txt:first-child, .ly_wrap .review_box p:first-child { margin-top: 0; }

.ly_wrap .btn_zzim, .ly_wrap .btn_detail { display: inline-block; width: 130px; margin: 0 2px; font-size: 14px; line-height: 38px; vertical-align: top; text-align: center; }

.ly_wrap .btn_zzim { border: 1px solid; border-color: #ff0038; background: #fff; color: #ff0038; }

.ly_wrap .btn_zzim .ico_zzim { display: inline-block; overflow: hidden; width: 15px; height: 14px; margin-right: 5px; background: url("/img/mini/home/sp_zzim.png") no-repeat 0 -20px; line-height: 200px; vertical-align: middle; }

.ly_wrap .btn_zzim.on { border: 1px solid; border-color: #ff0038; background: #ff0038; color: #fff; }

.ly_wrap .btn_zzim.on .ico_zzim { background-position: -19px -20px; }

.ly_wrap .btn_detail { border: 1px solid; border-color: #333; background: #333; color: #fff; }

.ly_wrap .review_video_area { display: inline-block; position: relative; max-width: 100%; margin: 7px 0; }

.ly_wrap .review_video_area:before { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: #000; opacity: .3; filter: alpha(opacity=30); content: ''; }

.ly_wrap .review_video_area video { display: block; max-width: 100%; max-height: 432px; }

.ly_wrap .review_video_area .btn_video { overflow: hidden; position: absolute; top: 50%; left: 50%; z-index: 5; width: 64px; height: 64px; margin: -32px 0 0 -32px; background: url("/img/mini/home/icon_play.png"); line-height: 200px; }

.ly_wrap .review_video_area.review_play_video:before, .ly_wrap .review_video_area.review_play_video .btn_video { display: none; }

.store_instagram { position: relative; margin-top: 30px; padding: 120px 0 40px; background: #b4d0eb url("/img/mini/home/store_instagram.png") no-repeat 41px 29px; border-bottom: 1px solid #c6cacc; }

.store_instagram .store_common_prev, .store_instagram .store_common_next { top: 170px; margin-top: 0; }

.store_instagram .store_instagram_author { position: absolute; left: 122px; top: 65px; font-family: tahoma, sans-serif; color: #fff; }

.store_instagram_popularity { position: absolute; right: 24px; top: 40px; overflow: hidden; }

.store_instagram_popularity dl { float: left; width: 83px; color: #333; text-align: center; }

.store_instagram_popularity dd { font-family: tahoma, sans-serif; }

.store_instagram_list_area { overflow: hidden; height: 160px; }

.store_instagram_list_area .store_instagram_list { height: 180px; padding-left: 40px; font-size: 0; white-space: nowrap; overflow-x: auto; overflow-y: hidden; -webkit-overflow-scrolling: touch; }

.store_instagram_list_area .store_instagram_item { display: inline-block; *display: inline; padding-right: 41px; zoom: 1; }

.store_instagram_list_area .store_instagram_item + .store_instagram_item { margin-left: -40px; }

.store_instagram_list_area .store_instagram_link, .store_instagram_list_area .store_instagram_thumb { display: block; }

.store_category_coupon { padding-top: 38px; }

.store_category_coupon .store_coupon_profile .btn_dnld .ico_dnld { width: 18px; background-position: 0 -19px; }

.store_category_coupon .store_coupon_profile .btn_dnld .num { display: none; }

.store_coupon { position: relative; z-index: 10; }

.store_coupon_list { margin: 0 -4px -8px; zoom: 1; }

.store_coupon_list:after { display: block; content: ""; clear: both; }

.store_coupon_item { float: left; width: 50%; margin-bottom: 8px; }

.store_coupon_profile { position: relative; margin: 0 4px; background: #fff; zoom: 1; }

.store_coupon_profile:after { display: block; content: ""; clear: both; }

.store_coupon_profile .store_coupon_l { height: 40px; padding: 20px; box-shadow: inset 0 -1px 0 #c4cbcb; }

.store_coupon_profile .store_coupon_r { position: absolute; top: 0; right: 0; background: url("/img/mini/home/bg_coupon.gif") no-repeat 0 0; }

.store_coupon_profile .coupon_type { display: block; margin-bottom: 5px; font-size: 15px; color: #ff0038; }

.store_coupon_profile .coupon_type .num { font-size: 16px; font-family: Tahoma, sans-serif; }

.store_coupon_profile .txt { margin-right: 5px; }

.store_coupon_profile .txt .num { font-size: 13px; font-family: Tahoma, sans-serif; }

.store_coupon_profile .btn { color: #999; }

.store_coupon_profile .btn:after { display: inline-block; width: 17px; height: 17px; margin-left: 4px; background: url("/img/mini/home/sp_coupon.png") no-repeat -38px 0; content: ''; vertical-align: middle; }

.store_coupon_profile .btn.btn_add:after { background: url("/img/mini/home/sp_coupon.png") no-repeat -19px 0; }

.store_coupon_profile .txt_check { color: #ff0038; font-size: 13px; }

.store_coupon_profile .txt_check:before { display: inline-block; width: 14px; height: 9px; margin-right: 4px; background: url("/img/mini/home/sp_coupon.png") no-repeat -56px -27px; content: ''; vertical-align: middle; }

.store_coupon_profile .btn_dnld { width: 76px; height: 80px; margin-left: 9px; font-size: 14px; color: #999; text-align: center; background: #fff; box-shadow: inset 0 -1px 0 #c4cbcb; }

.store_coupon_profile .btn_dnld .ico_dnld { overflow: hidden; display: block; width: 17px; height: 17px; margin: 2px auto 0; background: url("/img/mini/home/sp_coupon.png") no-repeat 0 0; line-height: 200px; }

.store_coupon_profile .btn_dnld .num { display: block; color: #ff0038; }

.store_coupon_profile span.btn_dnld { display: inline-block; overflow: hidden; line-height: 78px; }

.store_coupon_profile .layer_frequenter { width: 314px; top: 66px; left: 0; right: auto; padding: 18px 20px 20px; text-align: left; }

.store_coupon_profile .layer_frequenter .tit { color: #111; font-size: 14px; }

.store_coupon_profile .layer_frequenter .cont_box { margin-top: 18px; padding: 14px 16px 16px; background: #f4f4f4; line-height: 20px; }

.coupon_more { display: inline-block; margin: -5px 0 0 7px; font-size: 12px; line-height: 22px; color: #666; vertical-align: middle; }

.coupon_more:after { display: inline-block; width: 6px; height: 7px; margin: 0 0 0 4px; background: url("/img/mini/home/sp_coupon.png") no-repeat -21px -19px; content: ''; vertical-align: middle; }

.flag_text { display: inline-block; min-width: 34px; padding: 3px 5px; background-color: rgba(153, 153, 153, 0.06); text-align: center; border-radius: 2px; vertical-align: middle; color: #666; font-weight: normal; font-size: 11px; letter-spacing: -0.5px; }

.store_etc_wrap { position: relative; min-height: 708px; margin-top: 30px; padding: 30px; background-color: #fff; }

.store_etc_wrap .store_etc_title { margin-bottom: 20px; color: #111; font-size: 18px; line-height: 1.5; letter-spacing: -0.5px; }

table.type_store_etc { width: 100%; table-layout: fixed; }

table.type_store_etc colgroup .colwid1 { width: 56px; }

table.type_store_etc colgroup .colwid3 { width: 80px; }

table.type_store_etc tr th, table.type_store_etc tr td { height: 28px; padding: 10px 0; border-bottom: 1px solid #eee; }

table.type_store_etc tr .title { overflow: hidden; display: table-cell; }

table.type_store_etc tr .title > a { overflow: hidden; display: block; width: 100%; font-size: 14px; white-space: nowrap; text-overflow: ellipsis; }

table.type_store_etc tr .date { text-align: right; color: #999; font-size: 13px; }

table.type_store_etc tr:first-child th, table.type_store_etc tr:first-child td { border-top: 1px solid #eee; }

table.type_store_etc tr.item_em .flag_text { background-color: rgba(244, 49, 66, 0.06); color: #ff0038; }

table.type_store_etc tr.item_em .title { font-weight: bold; }

.paging { margin-top: 40px; text-align: center; font-size: 0; }

.paging a, .paging button, .paging strong { display: inline-block; width: 30px; height: 21px; margin: 0 2px; padding-top: 7px; font-size: 12px; line-height: 1; text-decoration: none; vertical-align: middle; }

.paging a:hover, .paging strong { color: #fff; background-color: #ff0038; border: 1px solid #ff0038; font-weight: normal; }

.paging a, .paging button { color: #666; background: #fff; border: 1px solid #c7c7c7; font-size: 12px; }

.paging .first, .paging .prev, .paging .next, .paging .last { overflow: hidden; width: 32px; height: 30px; border: none; background: url("/html/codebuild/img/sp.png") no-repeat; line-height: 999px; }

.paging .first { background-position: 0 -42px; }

.paging .prev { background-position: -36px -42px; }

.paging .next { background-position: 0 -74px; }

.paging .last { background-position: -36px -74px; }

.store_notice_view { border-top: 1px solid #eee; border-bottom: 1px solid #eee; }

.store_notice_view .notice_header { padding: 20px 0 16px; }

.store_notice_view .notice_header .title { display: block; color: #333; font-size: 16px; letter-spacing: -0.5px; }

.store_notice_view .notice_header .title .flag_text { margin-right: 7px; }

.store_notice_view .notice_header .title.item_em .flag_text { background-color: rgba(244, 49, 66, 0.06); color: #ff0038; }

.store_notice_view .notice_header .date { margin-top: 10px; padding-left: 56px; color: #999; }

.store_notice_view .notice_header .date > em { margin-right: 8px; }

.store_notice_view .notice_content { padding: 0 16px 30px 56px; color: #333; font-size: 14px; line-height: 2; letter-spacing: -0.5px; }

.store_btn_wrap { margin-top: 20px; text-align: right; }

.store_btn_wrap .btn { display: inline-block; width: 120px; height: 40px; border: 1px solid #ccc; color: #333; font-weight: bold; font-size: 14px; line-height: 38px; }

.c-starrate { zoom: 1; }

.c-starrate:after { display: block; content: ""; clear: both; }

.c-starrate dt { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c-starrate dd:first-of-type .c-starrate__review .sr-only { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; color: #666; }

.c-starrate .c-review-event + dd.c-starrate__review .sr-only, .c-starrate dd.c-starrate__review:first-of-type .sr-only { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; color: #666; }

.c-starrate.c-starrate--amazon dt { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 14px; height: 14px; background-position: -154px -243px; background-size: 395px 390px; overflow: hidden; float: left; margin: 2px 4px 0 0; color: transparent; }

.c-starrate.c-starrate--simply.c-starrate--amazon dt { position: static; width: auto; height: auto; margin: 0; clip: auto; white-space: normal; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 12px; height: 12px; background-position: -131.77722px -208.10769px; background-size: 338px 334px; overflow: hidden; float: left; margin: 3px 2px 0 0; color: transparent; }

.c-starrate.c-starrate--simply .c-starrate__sati { display: flex; gap: 2px; align-items: center; width: auto; height: 18px; margin: 0; background: none; }

.c-starrate.c-starrate--simply .c-starrate__review { margin: 0 0 0 2px; color: #949494; font-size: 13px; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; line-height: 18px; }

.c-starrate.c-starrate--simply .c-starrate__review .value { font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c-starrate.c-starrate--simply .c-starrate__review a { color: #949494; font-size: 13px; line-height: 18px; }

.c-starrate.c-starrate--simply .c-qty { display: flex; line-height: 17px; }

.c-starrate.c-starrate--simply .c-qty:not(:first-of-type)::before { display: block; width: 2px; height: 2px; border-radius: 50%; margin: 8px 4px; background-color: #ddd; content: ""; }

.c-starrate.c-starrate--simply .c-qty__value { color: #949494; font-size: 13px; }

.c-starrate__sati { position: relative; float: left; overflow: hidden; margin: 2px 4px 0 0; color: transparent; font-size: 10px; width: 64px; height: 12px; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 64px; height: 12px; background-position: -244px -182px; background-size: 395px 390px; }

.c-starrate__sati .c-starrate__gauge { position: absolute; top: 0; left: 0; display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 64px; height: 12px; background-position: -244px -202px; background-size: 395px 390px; }

.c-starrate__sati .c-starrate__title { display: inline-block; vertical-align: top; background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat; width: 12px; height: 12px; background-position: -106px -195px; background-size: 395px 390px; }

.c-starrate__sati .c-starrate__text { color: #111; font-family: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 13px; font-weight: 400; line-height: 18px; }

.c-starrate__review { float: left; color: #666; font-size: 12px; letter-spacing: 0; }

.c-starrate__review .value { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_list_card { position: relative; }

.c_list_card ul { zoom: 1; }

.c_list_card ul:after { display: block; content: ""; clear: both; }

.c_list_card li { position: relative; float: left; box-sizing: border-box; }

.c_list_card li:first-child { margin-left: 0; }

.c_list_card .c_adtext { position: absolute; left: 0; z-index: 10; }

.c_list_card .c-starrate__sati { margin: 2px 3px 0 0; }

.c_list_card .list_navigator button { overflow: hidden; position: absolute; top: 50%; line-height: 300px; background: #fff; z-index: 10; }

.c_list_card .list_navigator button::before { position: absolute; left: 50%; top: 50%; margin: -9px 0 0 -5px; content: ""; }

.c_list_card .list_navigator button.previous { left: -25px; }

.c_list_card .list_navigator button.next { right: -25px; }

.c_list_card .list_navigator button:disabled { display: none; }

.c_list_card.c_list_card_deal .list_navigator button, .c_list_card.c_list_card_collection .list_navigator button { width: 50px; height: 50px; margin-top: -25px; border-radius: 50%; box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45); }

.c_list_card.c_list_card_deal .list_navigator button.previous::before, .c_list_card.c_list_card_collection .list_navigator button.previous::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -87px -27px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_list_card.c_list_card_deal .list_navigator button.previous:hover::before, .c_list_card.c_list_card_collection .list_navigator button.previous:hover::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -102px -27px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_list_card.c_list_card_deal .list_navigator button.next::before, .c_list_card.c_list_card_collection .list_navigator button.next::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: 0px -83px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_list_card.c_list_card_deal .list_navigator button.next:hover::before, .c_list_card.c_list_card_collection .list_navigator button.next:hover::before { background-image: url("/img/eui/sprites/sp_card_item_202171_95024.png"); background-position: -15px -83px; width: 10px; height: 18px; background-size: 112px 101px; }

.c_list_card.c_list_card_deal .list_navigator button:hover, .c_list_card.c_list_card_collection .list_navigator button:hover { box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6); }

.c_list_card.c_list_card_deal { width: 1240px; }

.c_list_card.c_list_card_deal .swiper-container, .c_list_card.c_list_card_deal .c_slide_view { padding: 25px; margin: -25px; }

.c_list_card.c_list_card_deal .swiper-container.swiper_doing, .c_list_card.c_list_card_deal .swiper-container.c_slide_doing, .c_list_card.c_list_card_deal .c_slide_view.swiper_doing, .c_list_card.c_list_card_deal .c_slide_view.c_slide_doing { margin-right: -3px; margin-left: -3px; padding-right: 3px; padding-left: 3px; }

.c_list_card.c_list_card_deal .swiper-slide, .c_list_card.c_list_card_deal .c_slide { margin-right: 29px; }

.c_list_card.c_list_card_deal li:not(:first-of-type) { margin-left: 29px; }

.c_list_card.c_list_card_deal .c-card-item__soldout-text::before { width: 118px; height: 118px; background-size: 100% 100%; }

.c_list_card.c_list_card_collection { width: 1240px; }

.c_list_card.c_list_card_collection ul { display: flex; width: 100%; flex-wrap: wrap; }

.c_list_card.c_list_card_collection li { float: none; vertical-align: top; }

.c_list_card.c_list_card_collection .swiper-container, .c_list_card.c_list_card_collection .c_slide_view { padding: 25px; margin: -25px; }

.c_list_card.c_list_card_collection .swiper-container.swiper_doing, .c_list_card.c_list_card_collection .swiper-container.c_slide_doing, .c_list_card.c_list_card_collection .c_slide_view.swiper_doing, .c_list_card.c_list_card_collection .c_slide_view.c_slide_doing { margin-right: -3px; margin-left: -3px; padding-right: 3px; padding-left: 3px; }

.c_list_card.c_list_card_collection .swiper-slide, .c_list_card.c_list_card_collection .c_slide { margin-right: 30px; }

.c_list_card.c_list_card_collection .swiper-slide li:first-of-type, .c_list_card.c_list_card_collection .c_slide li:first-of-type { margin-left: 0px; }

.c_list_card.c_list_card_collection .c_adtext { top: 208px; }

.c_list_card.c_list_card_collection .c-nation { margin-bottom: 10px; }

.c_list_card.c_list_card_collection .c-card-item__soldout-text::before { width: 60%; height: 60%; background-size: 100% 100%; }

.c_list_card.c_list_card_collection.col_5 .swiper-slide, .c_list_card.c_list_card_collection.col_5 .c_slide { margin-right: 30px; }

.c_list_card.c_list_card_collection.col_5 li { flex: 0 1 224px; width: 224px; margin: 30px 0 0 30px; }

.c_list_card.c_list_card_collection.col_5 li:nth-child(1), .c_list_card.c_list_card_collection.col_5 li:nth-child(2), .c_list_card.c_list_card_collection.col_5 li:nth-child(3), .c_list_card.c_list_card_collection.col_5 li:nth-child(4), .c_list_card.c_list_card_collection.col_5 li:nth-child(5) { margin-top: 0; }

.c_list_card.c_list_card_collection.col_5 li:nth-child(5n - 4) { margin-left: 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item { width: 224px; border-radius: 4px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__thumb { padding: 10px 10px 0 10px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__thumb-img { width: 204px; height: 204px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__soldout-text { left: 10px; top: 10px; width: 204px; height: 204px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__info { zoom: 1; padding: 17px 0 17px 20px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__info:after { display: block; content: ""; clear: both; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-time-flag { margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-flag { margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__name { max-height: 3em; padding: 0 20px 0 0; margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__price-info { margin: 0 0 11px 0; padding: 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__price-info .c-card-item__lowest-value { clear: left; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__price-info .c-card-item__rate { margin-right: 8px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__price-info + .c-card-item__delivery { padding-left: 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__price-info + .c-card-item__delivery:before { display: none; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-starrate { float: left; line-height: 18px; margin: 0 0 7px 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-starrate dd { float: left; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-starrate .sr-only { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__delivery { position: relative; float: none; clear: both; padding: 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__delivery:before { display: none; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__delivery .allclub:before { float: left; margin: 1px 2px 0 0; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__delivery .sktpass .c-card-item__point { line-height: 18px; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__save { display: block; width: 100%; height: 52px; line-height: 52px; text-align: center; font-size: 17px; color: #ff0038; }

.c_list_card.c_list_card_collection.col_5 .c-card-item .c-card-item__save:before { display: inline-block; margin: 12px 6px 0 0; vertical-align: top; overflow: hidden; line-height: 200px; content: ""; }

.c_list_card.c_list_card_collection.col_5 .c_adtext { top: 208px; }

.c_list_card.c_list_card_collection.col_4 .swiper-slide, .c_list_card.c_list_card_collection.col_4 .c_slide { margin-right: 30px; }

.c_list_card.c_list_card_collection.col_4 li { flex: 0 1 287px; width: 287px; margin: 30px 0 0 30px; }

.c_list_card.c_list_card_collection.col_4 li:nth-child(1), .c_list_card.c_list_card_collection.col_4 li:nth-child(2), .c_list_card.c_list_card_collection.col_4 li:nth-child(3), .c_list_card.c_list_card_collection.col_4 li:nth-child(4) { margin-top: 0; }

.c_list_card.c_list_card_collection.col_4 li:nth-child(4n-3) { margin-left: 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item { width: 287px; margin: 0 auto; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__thumb-img { overflow: hidden; width: 268px; height: 268px; margin: 0 auto; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__thumb-img img { transition: transform 0.2s ease-in; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__soldout-text { left: 10px; top: 10px; width: 268px; height: 268px; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__info { zoom: 1; padding: 15px 20px 19px; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__info:after { display: block; content: ""; clear: both; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-time-flag { margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-flag { margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__name { max-height: 3em; line-height: 1.5; margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__name-addition { max-height: 1.5em; margin: 0 0 8px 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__price-info { margin: 0 0 10px 0; padding: 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__price-info .c-card-item__rate { margin-right: 8px; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__price-info + .c-card-item__delivery { padding-left: 0; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__price-info + .c-card-item__delivery:before { display: none; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-starrate dd { float: left; }

.c_list_card.c_list_card_collection.col_4 .c-card-item .c-card-item__delivery .sktpass .c-card-item__point { line-height: 18px; }

.c_list_card.c_list_card_gallery { width: 1240px; }

.c_list_card.c_list_card_gallery ul { display: flex; width: 100%; flex-wrap: wrap; }

.c_list_card.c_list_card_gallery li { float: none; vertical-align: top; }

.c_list_card.c_list_card_gallery li:nth-of-type(n + 100):nth-of-type(-n + 999) .c-card-item__rank { width: 50px; }

.c_list_card.c_list_card_gallery .c-card-item .c-nation { margin-bottom: 10px; }

.c_list_card.c_list_card_gallery .c-card-item .c-card-item__info .c-starrate { margin: 0 0 8px 0; }

.c_list_card.c_list_card_gallery .c-card-item .c-card-item__info .c-card-item__price-info { margin-bottom: 11px; }

.c_list_card.c_list_card_gallery .c-card-item .c-card-item__info .c-starrate + .c-card-item__price-info dt + .c-card-item__price { margin-top: 2px; }

.c_list_card.c_list_card_gallery .c-card-item .c-card-item__info .c-card-item__delivery .allclub { line-height: 18px; }

.c_list_card.c_list_card_gallery .c-card-item .c-card-item__soldout-text::before { width: 60%; height: 60%; background-size: 100% 100%; }

.c_list_card.c_list_card_gallery.col_5 li { flex: 0 1 224px; width: 224px; margin: 30px 0 0 30px; }

.c_list_card.c_list_card_gallery.col_5 li:nth-child(1), .c_list_card.c_list_card_gallery.col_5 li:nth-child(2), .c_list_card.c_list_card_gallery.col_5 li:nth-child(3), .c_list_card.c_list_card_gallery.col_5 li:nth-child(4), .c_list_card.c_list_card_gallery.col_5 li:nth-child(5) { margin-top: 0; }

.c_list_card.c_list_card_gallery.col_5 li:nth-child(5n - 4) { margin-left: 0; }

.c_list_card.c_list_card_gallery.col_5 .c-card-item { width: 224px; }

.c_list_card.c_list_card_gallery.col_5 .c-card-item .c-card-item__thumb-img { width: 204px; height: 204px; }

.c_list_card.c_list_card_gallery.col_5 .c-card-item .c-card-item__soldout-text { height: 204px; }

.c_list_card.c_list_card_gallery.col_5 .c-card-item .c-card-item__lowest-value { clear: left; }

.c_list_card.c_list_card_gallery.col_4 li { flex: 0 1 287px; width: 287px; margin: 30px 0 0 30px; }

.c_list_card.c_list_card_gallery.col_4 li:nth-child(1), .c_list_card.c_list_card_gallery.col_4 li:nth-child(2), .c_list_card.c_list_card_gallery.col_4 li:nth-child(3), .c_list_card.c_list_card_gallery.col_4 li:nth-child(4) { margin-top: 0; }

.c_list_card.c_list_card_gallery.col_4 li:nth-child(4n - 3) { margin-left: 0; }

.c_list_card.c_list_card_gallery.col_4 .c-card-item { width: 287px; }

.c_list_card.c_list_card_gallery.col_4 .c-card-item .c-card-item__thumb-img { width: 267px; height: 267px; }

.c_slide_view, .swiper-container { overflow: hidden; width: 100%; }

.c_slide_container, .swiper-wrapper { zoom: 1; }

.c_slide_container:after, .swiper-wrapper:after { display: block; content: ""; clear: both; }

.c_slide, .swiper-slide { float: left; }

.c_card_contents .contents_info a:hover { text-decoration: none; }

.c_card_contents .contents_thumb { overflow: hidden; position: relative; }

.c_card_contents .contents_thumb img { width: 100%; height: 100%; }

.c_card_contents .contents_thumb .video { position: absolute; overflow: hidden; left: 50%; top: 50%; line-height: 200px; z-index: 10; }

.c_card_contents .contents_name { overflow: hidden; }

.c_card_contents .contents_description { position: relative; overflow: hidden; }

.c_card_contents .contents_description .more { position: absolute; bottom: 1px; right: 0; }

.c_card_contents .contents_description .more::before { position: absolute; height: 100%; right: 0; top: 0; content: ""; }

.c_card_contents .contents_description .more:after { position: absolute; top: 50%; right: 1px; margin-top: -3px; }

.c_card_contents .contents_description .more span { position: relative; z-index: 5; }

.c_card_contents .contents_tag { overflow: hidden; }

.c_card_contents .contents_tag a { float: left; }

.c_card_contents .contents_tag a:first-child { margin-left: 0; }

.c_card_contents .contents_tag a:hover { text-decoration: underline; }

.c_card_contents .contents_meta { overflow: hidden; box-sizing: border-box; }

.c_card_contents .contents_meta dl { float: left; position: relative; }

.c_card_contents .contents_meta dt { float: left; }

.c_card_contents .contents_meta dd { float: left; }

.c_card_contents .contents_meta .writer { float: left; }

.c_card_contents .contents_meta .writer .thumb { float: left; }

.c_card_contents .contents_meta .writer .name { overflow: hidden; float: left; white-space: nowrap; text-overflow: ellipsis; }

.c_card_contents .contents_meta .date { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.c_card_contents .contents_meta .count { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

@media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) { .c_list_card.c_list_card_collection.col_4 li { float: left; margin-left: 26px !important; }
  .c_list_card.c_list_card_collection.col_4 li:first-of-type { margin-left: 0 !important; }
  .c_list_card.c_list_card_collection.col_4 li:nth-child(4n - 3) { margin-left: 0 !important; }
  .c_list_card.c_list_card_collection.col_5 li { float: left; margin-left: 26px !important; }
  .c_list_card.c_list_card_collection.col_5 li:first-of-type { margin-left: 0 !important; }
  .c_list_card.c_list_card_collection.col_5 li:nth-child(5n - 4) { margin-left: 0 !important; } }

.c-button { position: relative; display: inline-block; box-sizing: border-box; vertical-align: middle; text-align: center; border: 1px solid transparent; }

.c-button[type="button"], .c-button[type="submit"] { border: 1px solid transparent; }

.c-button--bold { font-weight: bold; }

.c-button--text-left { text-align: left; }

.c-button:disabled, .c-button--disabled { cursor: pointer; }

.c-button:hover { text-decoration: none; }

.c-button--primary { color: #fff; background-color: #ff0038; border-color: #ff0038; }

.c-button--primary[type="button"], .c-button--primary[type="submit"] { color: #fff; background-color: #ff0038; border-color: #ff0038; }

.c-button--primary::after { border-color: #fff; }

.c-button--primary::after[type="button"], .c-button--primary::after[type="submit"] { border-color: #fff; }

.c-button--primary:disabled, .c-button--primary.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--primary:disabled[type="button"], .c-button--primary:disabled[type="submit"], .c-button--primary.c-button--disabled[type="button"], .c-button--primary.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--alert { color: #ff0038; background-color: #fff; border-color: #ff0038; }

.c-button--alert[type="button"], .c-button--alert[type="submit"] { color: #ff0038; background-color: #fff; border-color: #ff0038; }

.c-button--alert::after { border-color: #ff0038; }

.c-button--alert::after[type="button"], .c-button--alert::after[type="submit"] { border-color: #ff0038; }

.c-button--alert:disabled, .c-button--alert.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--alert:disabled[type="button"], .c-button--alert:disabled[type="submit"], .c-button--alert.c-button--disabled[type="button"], .c-button--alert.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--normal { color: #111; background-color: #fff; border-color: #ccc; }

.c-button--normal[type="button"], .c-button--normal[type="submit"] { color: #111; background-color: #fff; border-color: #ccc; }

.c-button--normal::after { border-color: #ccc; }

.c-button--normal::after[type="button"], .c-button--normal::after[type="submit"] { border-color: #ccc; }

.c-button--normal:disabled, .c-button--normal.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--normal:disabled[type="button"], .c-button--normal:disabled[type="submit"], .c-button--normal.c-button--disabled[type="button"], .c-button--normal.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--secondary { color: #0b83e6; background-color: #fff; border-color: #0b83e6; }

.c-button--secondary[type="button"], .c-button--secondary[type="submit"] { color: #0b83e6; background-color: #fff; border-color: #0b83e6; }

.c-button--secondary::after { border-color: #0b83e6; }

.c-button--secondary::after[type="button"], .c-button--secondary::after[type="submit"] { border-color: #0b83e6; }

.c-button--secondary:disabled, .c-button--secondary.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--secondary:disabled[type="button"], .c-button--secondary:disabled[type="submit"], .c-button--secondary.c-button--disabled[type="button"], .c-button--secondary.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--black { color: #fff; background-color: #333; border-color: #333; }

.c-button--black[type="button"], .c-button--black[type="submit"] { color: #fff; background-color: #333; border-color: #333; }

.c-button--black::after { border-color: #fff; }

.c-button--black::after[type="button"], .c-button--black::after[type="submit"] { border-color: #fff; }

.c-button--black:disabled, .c-button--black.c-button--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button--black:disabled[type="button"], .c-button--black:disabled[type="submit"], .c-button--black.c-button--disabled[type="button"], .c-button--black.c-button--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button-luxy { position: relative; display: inline-block; box-sizing: border-box; vertical-align: middle; text-align: center; border: 1px solid transparent; }

.c-button-luxy[type="button"], .c-button-luxy[type="submit"] { border: 1px solid transparent; }

.c-button-luxy--bold { font-weight: bold; }

.c-button-luxy--text-left { text-align: left; }

.c-button-luxy:disabled, .c-button-luxy--disabled { cursor: pointer; }

.c-button-luxy:hover { text-decoration: none; }

.c-button-luxy--primary { color: #fff; background-color: #0f0fd9; border-color: #0f0fd9; }

.c-button-luxy--primary[type="button"], .c-button-luxy--primary[type="submit"] { color: #fff; background-color: #0f0fd9; border-color: #0f0fd9; }

.c-button-luxy--primary::after { border-color: #0f0fd9; }

.c-button-luxy--primary::after[type="button"], .c-button-luxy--primary::after[type="submit"] { border-color: #0f0fd9; }

.c-button-luxy--primary:disabled, .c-button-luxy--primary.c-button-luxy--disabled { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c-button-luxy--primary:disabled[type="button"], .c-button-luxy--primary:disabled[type="submit"], .c-button-luxy--primary.c-button-luxy--disabled[type="button"], .c-button-luxy--primary.c-button-luxy--disabled[type="submit"] { border-color: rgba(204, 204, 204, 0.2); background: #fafafa; color: #ccc; }

.c_modal { display: none; position: fixed; left: 0; top: 0; right: 0; bottom: 0; z-index: 2000; }

.c_modal.active { display: block; }

.c_modal.c_modal_dimmed { background: rgba(0, 0, 0, 0.2); }

.c_modal--w500 .modal_inner { width: 500px; }

.c_modal .modal_inner { position: relative; margin: 50vh auto; transform: translateY(-50%); background: #fff; box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12); }

.c_modal .modal_header { border-bottom: 1px solid #eee; }

.c_modal .modal_header .title { text-align: center; }

.c_modal .modal_content.scroll { overflow-y: auto; height: 100px; }

.c_modal .modal_footer { border-top: 1px solid #f4f4f4; text-align: center; }

.c_modal .modal_footer button { display: inline-block; margin: 0 5px; }

.c_modal .modal_close button { position: absolute; top: 17px; right: 21px; width: 36px; height: 36px; overflow: hidden; line-height: 200px; }

.c_modal .modal_close button:before, .c_modal .modal_close button:after { position: absolute; top: 50%; left: 50%; width: 48px; height: 3px; background-color: #111; content: ""; }

.c_modal .modal_close button:before { -webkit-transform: translate(-50%, -50%) scale(0.5) rotate(45deg); transform: translate(-50%, -50%) scale(0.5) rotate(45deg); }

.c_modal .modal_close button:after { -webkit-transform: translate(-50%, -50%) scale(0.5) rotate(135deg); transform: translate(-50%, -50%) scale(0.5) rotate(135deg); }

.c_layer_expand { display: inline-block; position: relative; }

.c_layer_expand.active { z-index: 100; }

.c_layer_expand.active .c_layer_item { display: block; }

.c_layer_item { display: none; position: absolute; left: 0; width: 240px; padding: 20px; box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.36); background: #fff; border-radius: 4px; box-sizing: border-box; z-index: 10; }

.c_layer_item.active { display: block; }

.c_layer_item .layer_info_subject dt { padding-bottom: 6px; font-size: 14px; font-weight: bold; color: #111; }

.c_layer_item .layer_info_subject dd { margin-top: 4px; }

.c_layer_item .layer_info_subject .dash::before { display: inline-block; width: 4px; height: 1px; margin-right: 4px; background-color: #666; vertical-align: 5px; content: ""; }

.c_layer_item .layer_info_subject .dash_align { position: relative; padding-left: 8px; }

.c_layer_item .layer_info_subject .dash_align::before { position: absolute; left: 0; top: 50%; width: 4px; height: 1px; background-color: #666; content: ""; }

.c_layer_item .layer_info li { margin-top: 4px; }

.c_layer_item .layer_info li:first-child { margin-top: 0; }

.c_layer_item .layer_info .dash::before { display: inline-block; width: 4px; height: 1px; margin-right: 4px; background-color: #666; vertical-align: 5px; content: ""; }

.c_layer_item .layer_info .dash_align { position: relative; padding-left: 8px; }

.c_layer_item .layer_info .dash_align::before { position: absolute; left: 0; top: 50%; width: 4px; height: 1px; background-color: #666; content: ""; }

.c_layer_item .layer_close { position: absolute; right: 15px; top: 15px; width: 23px; height: 23px; overflow: hidden; line-height: 200px; }

.c_layer_item .layer_close:before, .c_layer_item .layer_close:after { position: absolute; top: 50%; left: 50%; width: 17px; height: 1px; background-color: #111; content: ""; }

.c_layer_item .layer_close:before { -webkit-transform: translate(-50%, -50%) rotate(45deg); transform: translate(-50%, -50%) rotate(45deg); }

.c_layer_item .layer_close:after { -webkit-transform: translate(-50%, -50%) rotate(135deg); transform: translate(-50%, -50%) rotate(135deg); }

.c_search_item { position: relative; box-sizing: border-box; border: 1px solid #ddd; background: #fff; }

.c_search_item .search_text { display: block; width: 100%; background: none; box-sizing: border-box; border: none; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; letter-spacing: 0; color: #111; outline: none; padding: 0; }

.c_search_item .search_text::placeholder { color: #999; }

.c_search_item .search_button { position: absolute; right: -1px; top: -1px; overflow: hidden; line-height: 200px; height: 100%; }

.c_search_item .c_layer_item { width: 100%; }

.c_bi_amazon { display: inline-block; width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon.svg"); background-size: 80px 25px; background-size: 100% 100%; vertical-align: middle; overflow: hidden; line-height: 200px; }

.c_bi_amazon.c_bi_amazon_allwhite { width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon_allwhite.svg"); background-size: 80px 25px; background-size: 100% 100%; }

.c_bi_amazon.c_bi_amazon_white { width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon_white.svg"); background-size: 80px 25px; background-size: 100% 100%; }

.c_bi_amazon.c_bi_amazon_grey { width: 80px; height: 25px; background-image: url("/img/svg/logo/amazon_black.svg"); background-size: 80px 25px; background-size: 100% 100%; }

.c_icon_amazon { display: inline-block; width: 14px; height: 14px; background-image: url("/img/svg/logo/amazon_icon.svg"); background-size: 14px 14px; background-size: 100% 100%; vertical-align: middle; overflow: hidden; line-height: 200px; }

.l_amazon_header { position: relative; z-index: 500; width: 100%; box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28); background: linear-gradient(to left, #a5e6cc, #a5e6cc 44%, #84d7e2 48%, #84d7e1); }

.l_amazon_header.l_amazon_header_fixed { position: fixed; top: 69px; left: 0; right: 0; z-index: 1100; }

.b_amazon_header { zoom: 1; letter-spacing: 0; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; position: relative; width: 1240px; height: 70px; margin: 0 auto; background: linear-gradient(to left, #a5e6cc, #a5e6cc 44%, #84d7e1); }

.b_amazon_header:after { display: block; content: ""; clear: both; }

.c_amazon_header_category { float: left; position: relative; }

.c_amazon_header_category .category_button { position: relative; width: 224px; height: 70px; padding: 0 40px 0 0; font-size: 17px; font-weight: bold; color: #111; text-align: left; z-index: 10; }

.c_amazon_header_category .category_button .c_bi_amazon { margin: 8px 10px 0 0; }

.c_amazon_header_category .category_button:after { content: ""; position: absolute; right: 17px; top: 33px; width: 0; border-style: solid; border-width: 6px 5px 0; border-color: #111 transparent; }

.c_amazon_header_category .category_button::before { position: absolute; right: 0; top: 0; width: 1px; height: 100%; background: #41a1ab; opacity: 0.29; content: ""; }

.c_amazon_header_category.category_visible .category_button:after { transform: rotate(180deg); }

.c_amazon_header_category.category_visible:before { position: absolute; right: 0; top: 0; width: 3000px; height: 100%; background: #41a1ab; opacity: 0.2; content: ""; }

.c_amazon_header_category.category_visible .c_amazon_header_category_layer { display: block; }

.c_amazon_header_category_layer { display: none; position: absolute; left: 0; top: 70px; overflow: hidden; width: 672px; background: #fff; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.36); }

.c_amazon_header_category_layer.subcategory_none { width: 224px; }

.c_amazon_header_category_layer > ul { position: relative; width: 224px; height: 100%; border-right: 1px solid #eee; box-sizing: border-box; }

.c_amazon_header_category_layer > ul > li a { position: relative; display: block; height: 44px; padding: 0 10px 0 20px; line-height: 42px; box-sizing: border-box; font-size: 15px; }

.c_amazon_header_category_layer > ul > li a:hover { text-decoration: none; }

.c_amazon_header_category_layer > ul > li > a { border-top: 1px solid #fafafa; border-bottom: 1px solid #fafafa; background: #fafafa; }

.c_amazon_header_category_layer > ul > li:first-child.active > a { border-top: 1px solid #fafafa; }

.c_amazon_header_category_layer > ul > li.active > a { color: #ff0038; font-weight: bold; background: #fff; border-top: 1px solid #eee; border-bottom: 1px solid #eee; }

.c_amazon_header_category_layer > ul > li.active > a::before { width: 5px; height: 5px; border: 1px solid #ff0038; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(45deg); position: absolute; right: 20px; top: 18px; content: ""; }

.c_amazon_header_category_layer > ul > li.active > a::after { position: absolute; right: -1px; top: 0; width: 1px; height: 100%; background: #fff; content: ""; }

.c_amazon_header_category_layer > ul > li.active .box_2depth { display: block; }

.c_amazon_header_category_layer > ul > li.depth_none > a::before { display: none; }

.c_amazon_header_category_layer .box_2depth { zoom: 1; display: none; position: absolute; left: 223px; top: 0; width: 448px; }

.c_amazon_header_category_layer .box_2depth:after { display: block; content: ""; clear: both; }

.c_amazon_header_category_layer .box_2depth ul { position: relative; float: left; width: 224px; }

.c_amazon_header_category_layer .box_2depth ul:nth-child(2):before { display: none; }

.c_amazon_header_category_layer .box_2depth ul:before { position: absolute; right: 0; top: 0; width: 1px; height: 800px; background: #eee; content: ""; }

.c_amazon_header_category_layer .box_2depth a:hover { color: #ff0038; font-weight: bold; }

.c_amazon_header_servicelink { float: left; margin: 0 0 0 25px; }

.c_amazon_header_servicelink ul { float: left; }

.c_amazon_header_servicelink li { float: left; margin: 0 0 0 20px; }

.c_amazon_header_servicelink li:first-child { margin-left: 0; }

.c_amazon_header_servicelink li a { display: block; height: 70px; line-height: 70px; font-weight: bold; font-size: 16px; color: #111; }

.c_amazon_universepass_landing { position: absolute; right: 0; top: 0; padding: 15px 0 0 0; }

.c_amazon_universepass_landing .text { float: left; margin: 9px 10px 0 0; color: #000; font-size: 15px; }

.c_amazon_universepass_landing .link { position: relative; float: left; height: 40px; padding: 7px 25px 0 15px; box-sizing: border-box; background: #3617ce; border-radius: 21px; color: #fff; font-size: 16px; }

.c_amazon_universepass_landing .link:hover { text-decoration: none; }

.c_amazon_universepass_landing .link::after { width: 5px; height: 5px; border: 1px solid #fff; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: rotate(45deg); position: absolute; right: 17px; top: 17px; content: ""; }

.c_amazon_universepass_landing .link .universepass { background-image: url("/img/amazon/sprites/sp_amazon_2x_2023215_151557.png"); background-position: -802px -25px; width: 56px; height: 16px; background-size: 1191px 201px; display: inline-block; margin: 5px 4px 0 0; overflow: hidden; line-height: 200px; vertical-align: top; }

#cts2, #cts > .store_recommand_product_wrap:first-child { margin-top: 30px; }

.store_wrap .c-flag-box { max-height: 22px; }

.store_wrap .subject-flag { color: #0b83e6; margin-right: 3px; }

.store_bundle_discount { overflow: visible; position: relative; width: 1200px; margin: 30px auto; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.store_bundle_discount .title { padding-bottom: 20px; font-size: 18px; font-weight: normal; line-height: 28px; color: #111; }

.store_bundle_discount .my_bundle_discount { padding: 24px; margin-bottom: 40px; background-color: #fff; zoom: 1; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.store_bundle_discount .my_bundle_discount:after { display: block; content: ""; clear: both; }

.store_bundle_discount .my_bundle_discount .c-card-item { float: left; width: 590px; min-height: 225px; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__thumb { float: left; width: 225px; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__thumb a { display: block; text-align: center; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__thumb .c-card-item__thumb-img { display: inline-block; width: 225px; height: 225px; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__thumb .c-card-item__thumb-img img { vertical-align: top; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info { float: left; padding-left: 15px; width: 320px; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info dl { zoom: 1; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info dl:after { display: block; content: ""; clear: both; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-card-item__name { margin-bottom: 8px; font-size: 15px; line-height: 22px; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-card-item__price-info { margin-bottom: 2px; zoom: 1; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-card-item__price-info:after { display: block; content: ""; clear: both; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-card-item__price-info .c-card-item__rate { float: left; margin-right: 8px; font-size: 14px; line-height: 18px; color: #ff0038; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-card-item__price-info .c-card-item__rate .value { font-size: 20px; line-height: 20px; vertical-align: bottom; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-card-item__price-info .c-card-item__price { float: left; font-size: 14px; line-height: 18px; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-card-item__price-info .c-card-item__price .value { display: inline-block; font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-card-item__price-info .c-card-item__price .unit { display: inline-block; margin-left: -2px; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-starrate { zoom: 1; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-starrate:after { display: block; content: ""; clear: both; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-starrate .c-starrate__sati { margin: 4px 5px 0 0; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-starrate .c-starrate__review .sr-only { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-starrate .c-starrate__review a { color: #999; }

.store_bundle_discount .my_bundle_discount .c-card-item .c-card-item__info .c-starrate .c-starrate__review a:hover { text-decoration: underline; }

.store_bundle_discount .my_bundle_discount .discount_info { float: left; padding: 0 11px 0 35px; min-height: 225px; border-left: 1px solid #eee; }

.store_bundle_discount .my_bundle_discount .discount_info > ul { width: 515px; }

.store_bundle_discount .my_bundle_discount .discount_info > ul > li { position: relative; margin-top: 8px; border: 1px solid #ddd; border-radius: 6px; padding: 23px 20px 19px 54px; }

.store_bundle_discount .my_bundle_discount .discount_info > ul > li:first-child { margin-top: 0; }

.store_bundle_discount .my_bundle_discount .discount_info > ul > li.active { padding: 23px 20px 24px 54px; }

.store_bundle_discount .my_bundle_discount .discount_info > ul > li.active .radiobox .info .detail2 { display: block; }

.store_bundle_discount .my_bundle_discount .discount_info > ul > li.active .layer_discount_info { display: block; }

.store_bundle_discount .my_bundle_discount .discount_info > ul > li.active .button_expand:before { transform: rotate(-135deg); }

.store_bundle_discount .my_bundle_discount .discount_info > ul > li .radiobox .info .detail2 { display: none; }

.store_bundle_discount .my_bundle_discount .discount_info > ul > li .layer_discount_info { display: none; }

.store_bundle_discount .my_bundle_discount .discount_info > ul > li .button_expand { position: absolute; overflow: hidden; top: 22px; right: 22px; width: 24px; height: 24px; text-indent: -9999px; }

.store_bundle_discount .my_bundle_discount .discount_info > ul > li .button_expand:before { content: ""; display: block; position: absolute; top: 50%; left: 50%; width: 7px; height: 7px; margin: -6px 0 0 -4px; border: 1px solid #252525; border-top: none; border-left: none; transform: rotate(45deg); }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox { display: block; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox > span { display: inline-block; font-size: 17px; line-height: 22px; color: #333; letter-spacing: 0; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox > span:before { content: ""; position: absolute; top: 0; left: 0; width: 22px; height: 22px; border: 1px solid #ddd; border-radius: 100%; background-color: #fff; vertical-align: middle; box-sizing: border-box; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox > span:after { content: ""; position: absolute; top: 6px; left: 6px; width: 10px; height: 10px; border-radius: 100%; background-color: #ddd; vertical-align: middle; box-sizing: border-box; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox input[type="radio"] { position: absolute; top: 23px; left: 17px; width: 22px; height: 22px; margin: 0; opacity: 0; vertical-align: middle; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox input[type="radio"]:checked + span:before { font-weight: bold; color: #ff3142; border-color: #ff0038; background-color: #ff0038; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox input[type="radio"]:checked + span:after { background-color: #fff; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox .info { display: block; overflow: hidden; background: #fff; font-size: 14px; color: #333; line-height: 21px; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox .info::before { top: 23px; left: 17px; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox .info::after { top: 29px; left: 23px; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox .info span { display: block; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox .info span em { color: #0b83e6; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox .info .detail1 { padding-bottom: 5px; font-size: 15px; font-weight: bold; line-height: 22px; color: #333; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox .info .detail2 { font-size: 14px; line-height: 20px; color: #666; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox .info .detail2 span { margin-top: 2px; }

.store_bundle_discount .my_bundle_discount .discount_info .radiobox .info .detail2 span:first-child { margin-top: 0; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info { position: relative; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .layer { display: none; position: absolute; top: 35px; left: 0; width: 408px; padding: 20px; box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.36); background: #fff; border-radius: 4px; box-sizing: border-box; z-index: 10; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info.active .layer { display: block; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .layer_cont .title { padding-bottom: 0; font-size: 15px; font-weight: bold; line-height: 22px; color: #111; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .layer_cont .cont { margin-top: 10px; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .layer_cont .cont .bundle_info li { font-family: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 15px; color: #111; line-height: 24px; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .layer_cont .cont .bundle_info li em { color: #0b83e6; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .btn_click { position: relative; margin-top: 7px; padding-right: 12px; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; font-size: 15px; line-height: 22px; color: #111; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .btn_click::after { position: absolute; top: 50%; right: 2px; width: 8px; height: 8px; margin-top: -4px; border: solid #111; border-width: 1px 1px 0 0; transform: rotate(45deg); content: ""; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .btn_close { position: absolute; right: 15px; top: 15px; width: 23px; height: 23px; overflow: hidden; line-height: 200px; width: 23px; height: 23px; overflow: hidden; line-height: 200px; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .btn_close:before, .store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .btn_close:after { position: absolute; top: 50%; left: 50%; width: 17px; height: 1px; background-color: #111; content: ""; }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .btn_close:before { -webkit-transform: translate(-50%, -50%) rotate(45deg); transform: translate(-50%, -50%) rotate(45deg); }

.store_bundle_discount .my_bundle_discount .discount_info .layer_discount_info .btn_close:after { -webkit-transform: translate(-50%, -50%) rotate(135deg); transform: translate(-50%, -50%) rotate(135deg); }

.store_bundle_discount .recommand_discount .c_list_store_recommand { position: relative; width: 1200px; }

.store_bundle_discount .recommand_discount .c_list_store_recommand .c_slide_view { padding: 25px 15px; margin: -25px -15px; }

.store_bundle_discount .recommand_discount .c_slide_container .c_slide { margin-right: 20px; }

.store_bundle_discount .recommand_discount .c_slide_container .c_slide ul li { margin: 0 0 0 20px; border-radius: 4px; background-color: #fff; box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28); }

.store_bundle_discount .recommand_discount .c_slide_container .c_slide ul li:hover { box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.7); }

.store_bundle_discount .recommand_discount .c_slide_container .c_slide ul li:hover .c-card-item__thumb-img img { transform: scale(1.08); }

.store_bundle_discount .recommand_discount .c_slide_container .c_slide ul li:first-child { margin-left: 0; }

.store_bundle_discount .recommand_discount .c_slide_container .c-card-item { width: 224px; height: 345px; border-radius: 4px; }

.store_bundle_discount .recommand_discount .c_slide_container .c-card-item__thumb .c-card-item__thumb-img { width: 224px; height: 224px; border-top-left-radius: 4px; border-top-right-radius: 4px; }

.store_bundle_discount .recommand_discount .c_slide_container .c-card-item__thumb .c-card-item__thumb-img img { vertical-align: top; transition: transform 0.2s ease-in; }

.store_bundle_discount .recommand_discount .c_slide_container .c-card-item__info { padding: 20px; }

.store_bundle_discount .recommand_discount .c_slide_container .c-card-item__info .c-card-item__name { height: 3em; margin: 0 0 15px 0; line-height: 22px; }

.store_bundle_discount .recommand_discount .c_slide_container .c-card-item__info .c-card-item__price-info .c-card-item__rate { display: inline-block; margin: 0 8px 2px 0; font-size: 16px; }

.store_bundle_discount .recommand_discount .c_slide_container .c-card-item__info .c-card-item__price-info .c-card-item__rate .value { font-size: 22px; line-height: 22px; }

.store_bundle_discount .recommand_discount .c_slide_container .c-card-item__info .c-card-item__price-info .c-card-item__price { float: left; font-size: 15px; line-height: 22px; }

.store_bundle_discount .recommand_discount .c_slide_container .c-card-item__info .c-card-item__price-info .c-card-item__price .value { display: inline-block; margin: 0 0 1px 0; font-size: 22px; line-height: 22px; }

.store_bundle_discount .recommand_discount .c_slide_container .c_adtext { top: 208px; }

.store_bundle_discount .list_navigator button { overflow: hidden; position: absolute; top: 50%; width: 50px; height: 50px; margin-top: -25px; border-radius: 50%; box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45); line-height: 200px; background: #fff; z-index: 10; }

.store_bundle_discount .list_navigator button.previous::before { background-image: url("/img/mini/sprites/sp_ico_2x_2021128_164308.png"); background-position: 0px 0px; width: 10px; height: 18px; background-size: 55px 18px; }

.store_bundle_discount .list_navigator button.previous:hover::before { background-image: url("/img/mini/sprites/sp_ico_2x_2021128_164308.png"); background-position: -15px 0px; width: 10px; height: 18px; background-size: 55px 18px; }

.store_bundle_discount .list_navigator button.next::before { background-image: url("/img/mini/sprites/sp_ico_2x_2021128_164308.png"); background-position: -30px 0px; width: 10px; height: 18px; background-size: 55px 18px; }

.store_bundle_discount .list_navigator button.next:hover::before { background-image: url("/img/mini/sprites/sp_ico_2x_2021128_164308.png"); background-position: -45px 0px; width: 10px; height: 18px; background-size: 55px 18px; }

.store_bundle_discount .store_filter_title { padding-top: 10px; }

.store_bundle_discount.store_bundle_discount_amazon .discount_info .radiobox input[type="radio"]:checked + span:before { color: #54cabe; border-color: #54cabe; background-color: #54cabe; }
