@charset "UTF-8";

/* MPSR-51108 삭제 (_product.scss 이동) */
.store_product_heading {
    padding: 9px 0 14px;
    border-bottom: 2px solid #111;
    font-weight: normal;
    font-size: 15px;

    a {
        display: inline-block;
        width: 100px;
        margin: -5px 0;
        padding: 5px 9px 5px;
        border-radius: 0;
        border: 1px solid #dadada;
        background: #fff url('/img/mini/home/icon_select_arrow.png') no-repeat 100% 0;
        font-size: 12px;
        line-height: 18px;
        vertical-align: top;

        &.on {
            background-position: 100% 100%;
        }

        &:focus,
        &:active,
        &:hover {
            text-decoration: none;
        }
    }
}

.category_list_layer {
    display: none;
    position: absolute;
    left: 0;
    top: 34px;
    border: 1px solid #676767;
    background-color: #fff;
    z-index: 1;
    overflow: hidden;

    .category_list {
        float: left;
        width: 118px;
        margin-right: -1px;
        padding: 6px 0;
        border-right: 1px solid #dadada;

        +.category_list {
            margin-left: 1px;
        }
    }

    .category_item {
        font-size: 12px;
        line-height: 22px;
    }

    .category_link {
        display: block;
        height: 22px;
        padding: 1px 10px 1px 8px;
        overflow: hidden;

        &:hover {
            background-color: #f4f4f4;
            text-decoration: none;
        }
    }
}

.store_search_title {
    margin-top: 30px;
    margin-bottom: -30px;
    padding: 9px 0;
    font-weight: normal;
    font-size: 22px;
    line-height: 32px;

    strong {
        font-weight: normal;
        color: $color-11st-red;
    }
}

.store_header_wrap {
    overflow: hidden;
    margin: 30px 0 -23px;

    .store_content_title {
        float: left;
        margin-right: 20px;
        padding: 4px 0;
        font-weight: normal;
        font-size: 22px;
        line-height: 32px;
    }

    .store_content_explain {
        float: left;
        padding: 13px 0 7px;
        font-size: 14px;
        color: #666;
    }
}

.store_product_top {
    padding: 9px 0 14px;
    border-bottom: 2px solid #111;
    zoom: 1;

    &:after {
        display: block;
        clear: both;
        content: '';
    }

    .store_product_heading {
        float: left;
        padding: 0;
        border: 0;
    }

    .store_chk_wrap {
        float: left;
        margin-left: 10px;
    }
}
/* // MPSR-51108 삭제 (_product.scss 이동) */
