@charset "UTF-8";

/* body{overflow-x: hidden;} */ // 가로스크롤 오류로 삭제
#wrap {
    min-width: 1240px;
    background-color: #e9edf0;
}

#layBodyWrap {
    width: 100%;
    padding-top: 0;
}

.store_container {
    overflow: hidden;
    position: relative;
    width: 1200px;
    margin: 20px auto 0;
}

.store_content_wrap {
    overflow: hidden;
    background-color: #fff;
    zoom: 1;

    .store_content {
        overflow: hidden;
        position: relative;
        min-height: 768px;
        margin-top: -30px;
        margin-left: 200px;
        padding-left: 20px;
        background-color: #e9edf0;
    }
}

.store_content_wrap.w1200 .store_content {
    min-height: auto;
    margin-left: 0;
    padding-left: 0;
}

.store_module_wrap {
    position: relative;
    margin-top: 40px;
}

.store_title {
    padding: 0 60px 18px 0;
    color: #333;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -1px;

    >i {
        margin-left: 6px;
        color: #666;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: -0.5px;
        font-style: normal;
    }
}

.btn_more {
    position: absolute;
    right: 0;
    top: 9px;
    padding-right: 12px;
    color: #666;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.88px;

    &:before {
        position: absolute;
        top: 50%;
        right: 0;
        width: 7px;
        height: 7px;
        margin: -1px 5px 0 0;
        border: 1px solid #666;
        border-width: 1px 1px 0 0;
        vertical-align: middle;
        transform: rotate(45deg) translateY(-50%);
        content: '';
    }
}

.c_sticky {
    position: relative;
}

.c_sticky_fixed_top {
    position: fixed;
    top: 0;
}

.c_sticky_fixed_bottom {
    position: fixed;
    bottom: 0;
}

.c_sticky_fixed_top, .c_sticky_fixed_bottom {
    bottom: 0;
    &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -20px;
        width: 20px;
        background-color: #e9edf0;
        content: '';
    }
}

.c_sticky_absolute {
    position: absolute;
    bottom: 0;

    +.c_sticky_dummy {
        display: block;
    }
}

.c_sticky_dummy {
    display: none;
}

.c_sticky_fixed_top+.c_sticky_dummy,
.c_sticky_fixed_bottom+.c_sticky_dummy {
    display: block;
}

.wing_banner {
    top: 141px;
    margin-left: 610px;
    padding-top: 10px;
}

#layBodyWrap .wing_banner.wing_fixed {
    top: 0;
}

/* my history 1200px */
div {

    &.footer_search2,
    &.my_history2_wrap,
    &.ftr_banner {
        width: 1200px;
    }
}

.my_history2_wrap {
    .recom_prd h5 {
        padding-left: 51px;
    }

    .btnctr_pn {
        padding-right: 51px;
    }
}
