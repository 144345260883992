@charset "UTF-8";

.store_lnb_f {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    background-color: #fff;
    z-index: 10;


    .store_search {
        padding: 10px 10px 0;

        .input_search[type='text'] {
            display: block;
            position: relative;
            *top: -1px;
            width: 142px;
            height: 18px;
            padding: 9px 0;
            border: 0;
            background-color: #f4f4f4;
            text-indent: 10px;
        }

        .button_search {
            overflow: hidden;
            position: absolute;
            right: 10px;
            top: 10px;
            width: 38px;
            height: 36px;
            background-color: #333;
            text-align: center;
            border: 0;

            &:after {
                @include sprite-retina($sp_mini_2x_icon_search);
                display: inline-block;
                vertical-align: middle;
                content: '';
            }
        }
    }

    .store_navigation {
        @extend .fs14;
        margin: 20px 0;
        overflow-y: auto;
        overflow-x: hidden;
        height: 691px;
    }

    .store_tag {
        padding: 20px 0;
        border-top: 1px solid #ebebeb;
    }

    .navigation_item_em {
        .navigation_link {
            position: relative;
            color: #333;
            font-size: 13px;

            &:after {
                position: absolute;
                right: 21px;
                top: 50%;
                width: 7px;
                height: 7px;
                margin: -3px -3px 0 0;
                border: 1px solid #111;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                content: '';
            }
        }
    }

    /* MPSR-51108 delete */
    .f_heading {
        @extend .fs14;
        position: relative;
        padding: 5px 20px;

        a {
            position: absolute;
            right: 8px;
            top: 0;
            width: 30px;
            height: 30px;
            background: url('/img/mini/home/icon_category_all.png') no-repeat 0 0;
        }
    }

    /* // MPSR-51108 delete */
    .navigation_item {
        position: relative;
    }

    .navigation_item:hover .navigation_link {
        font-weight: bold;
        color: #333;
        text-decoration: none;
        outline: none;
    }

    .navigation_item.on .navigation_link {
        font-weight: bold;
        color: #111;
    }

    .navigation_link {
        display: block;
        padding: 5px 20px;
        color: #666;
        transition: 0.15s ease-out;

        &:focus,
        &:active,
        &:hover {
            font-weight: bold;
            color: #333;
            text-decoration: none;
            outline: none;
        }
    }

    .promotion {
        position: relative;
        margin-top: 13px;
        padding-top: 17px;

        &:before {
            position: absolute;
            left: 20px;
            top: 0;
            width: 16px;
            height: 1px;
            background-color: #ccc;
            content: '';
        }

        .navigation_link {
            color: #fd7985;

            &:focus,
            &:active,
            &:hover {
                color: $color-11st-red;
            }
        }

        .navigation_item:hover .navigation_link {
            color: $color-11st-red;
        }
    }

    .etc {
        position: relative;
        margin-top: 13px;
        padding-top: 13px;

        &:before {
            position: absolute;
            left: 20px;
            top: 0;
            width: 16px;
            height: 1px;
            background-color: #ccc;
            content: '';
        }

        .navigation_link {
            color: #111;
        }

        .item_notice {
            >a {
                position: relative;

                &:before {
                    @include sprite-retina($sp_mini_2x_icon_notice);
                    display: inline-block;
                    margin: -2px 8px 0 0;
                    vertical-align: middle;
                    content: '';
                }
            }
        }

        .item_instagram {
            margin-top: 19px;
            padding: 0 10px;

            >a {
                position: relative;
                padding: 10px 12px 10px 50px;
                border: 1px solid #ddd;
                font-size: 12px;
                line-height: 16px;

                &:before {
                    @include sprite-retina($sp_mini_2x_icon_instagram);
                    position: absolute;
                    top: 11px;
                    left: 10px;
                    content: '';
                }

                &:hover {
                    font-weight: normal;
                }

                >em {
                    display: block;
                    margin-top: 2px;
                    font-weight: bold;
                    line-height: 1;
                    word-break:break-word;
                    word-break: break-all;
                }
            }
        }
    }
}

.store_lnb {
    width: 200px;
    min-height: 768px;
    background-color: #fff;

    .tag_list {
        padding: 0 20px 10px;
    }

    .tag_item {
        display: inline-block;
        margin: 2px 0;
        padding: 5px 10px;
        border-radius: 15px;
        background-color: #f4f4f4;
        color: #666;
    }
}

.store_category_all {
    display: none;
    position: absolute;
    left: 0;
    top: 56px;
    min-width: 198px;
    padding: 9px 0 11px;
    border: 1px solid #333;
    background-color: #fff;
    white-space: nowrap;
    z-index: 1;

    .category_heading {
        position: relative;
        padding: 5px 19px;
    }

    .category_list {
        display: inline-block;
        *display: inline;
        zoom: 1;
        width: 153px;
        vertical-align: top;
    }

    .category_item {
        position: relative;
        zoom: 1;
    }

    .category_link {
        display: block;
        padding: 5px 19px;
        color: #666;
        zoom: 1;

        &:focus,
        &:active,
        &:hover {
            color: #333;
            text-decoration: underline;
        }
    }
}

.store_category_all_layer_close {
    position: absolute;
    right: 11px;
    top: 12px;
    width: 26px;
    height: 26px;
    background: url('/img/mini/home/icon_store_info_layer_x.png') no-repeat 50% 50%;
}
