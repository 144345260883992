@charset "UTF-8";

.store_wrap,
.store_wrap input[type="text"],
.store_wrap button {
    font-size: 12px;
    font-family: $font-family-default;
    line-height: 18px;
}

.store_wrap button {
    box-sizing: border-box;
}

.store_wrap,
.store_wrap a {
    color: #333;
}

.store_wrap a,
.store_wrap a:focus,
.store_wrap a:active,
.store_wrap a:hover {
    text-decoration: none;
}

.fs12 {
    font-size: 12px;
    line-height: 16px;
}

.fs14 {
    font-size: 14px;
    line-height: 20px;
}

.fs16 {
    font-size: 16px;
    line-height: 22px;
}
