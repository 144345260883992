@charset "UTF-8";

.store_filter_title {
    margin-top: 20px;
    padding: 15px 0 18px;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

.store_content .store_filter {
    @extend .fs14;
    overflow: hidden;
    position: relative;
    margin-top: 0;
    margin-bottom: -12px;
    padding: 15px 20px;
    border: 1px solid #cdd1d4;
    background-color: #d6dadd;
    zoom: 1;

    .filter_area1,
    .filter_area2,
    .filter_area3 {
        float: left;
        position: relative;
        margin-right: 15px;
        padding-right: 21px;
    }

    .filter_area1:after {
        position: absolute;
        right: 0;
        top: 6px;
        width: 1px;
        height: 18px;
        background-color: #999;
        content: '';
    }

    input[type="checkbox"] {
        margin: -1px 0 1px;
        vertical-align: middle;
    }

    input[type="text"] {
        width: 138px;
        height: 18px;
        padding: 5px 0 7px;
        border: 0;
        text-indent: 10px;
        vertical-align: middle;
    }

    .filter_area1 input[type="text"] {
        width: 382px;
    }

    button {
        position: absolute;
        right: 20px;
        top: 15px;
        width: 100px;
        height: 30px;
        padding: 5px 0 7px;
        border: 0;
        background-color: #666;
        color: #fff;
        vertical-align: middle;
    }

    span {
        display: inline-block;
        padding: 4px 10px 6px;
        color: #666;
        vertical-align: middle;
    }

    label {
        display: inline-block;
        padding: 4px 15px 6px 3px;
        color: #111;
        vertical-align: middle;
    }
}
