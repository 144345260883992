@charset "UTF-8";

.store_instagram {
    position: relative;
    margin-top: 30px;
    padding: 120px 0 40px;
    background: #b4d0eb url('/img/mini/home/store_instagram.png') no-repeat 41px 29px;
    @include box-border();

    .store_common_prev,
    .store_common_next {
        top: 170px;
        margin-top: 0;
    }

    .store_instagram_author {
        @extend .fs14;
        position: absolute;
        left: 122px;
        top: 65px;
        font-family: tahoma, sans-serif;
        color: #fff;
    }
}

.store_instagram_popularity {
    position: absolute;
    right: 24px;
    top: 40px;
    overflow: hidden;

    dl {
        @extend .fs14;
        float: left;
        width: 83px;
        color: #333;
        text-align: center;
    }

    dd {
        font-family: tahoma, sans-serif;
    }
}

.store_instagram_list_area {
    overflow: hidden;
    height: 160px;

    .store_instagram_list {
        height: 180px;
        padding-left: 40px;
        font-size: 0;
        white-space: nowrap;
        @include scroll-x();
    }

    .store_instagram_item {
        display: inline-block;
        *display: inline;
        padding-right: 41px;
        zoom: 1;

        +.store_instagram_item {
            margin-left: -40px;
        }
    }

    .store_instagram_link,
    .store_instagram_thumb {
        display: block;
    }
}
